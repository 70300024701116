import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import useDictionary from '@values/Dictionary.ts';

import Section from '@components/Section';
import Footer from '@components/Footer';
import Navbar from '@components/Navbar';
import Image from '@components/Image';

import { Helmet, HelmetProvider } from 'react-helmet-async';

/**
 * NotFound page for web app
 *
 * By Frankil A.
 * © Kaady Sport S.A. de C.V. 2024
 */
export default function NotFound() {
	const location = useLocation();
	const navigate = useNavigate();
	const { NOTFOUND } = useDictionary();

	const toHome = () => {
		navigate('/');
	};

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>{NOTFOUND.hero_header}</title>
				</Helmet>
			</HelmetProvider>
			<Navbar showOptions={false} />
			<Section>
				<Stack
					direction={'column'}
					spacing={3}
					flexGrow={'1'}
					width={'100%'}>
					<Stack component={'header'}>
						<Typography variant="h3">{NOTFOUND.hero_header}</Typography>
						<Typography
							sx={{
								wordBreak: 'break-all',
							}}
							variant="h6"
							color={(t) => t.palette.text.secondary}>
							www.kaadysport.com{location.pathname}
						</Typography>
					</Stack>

					<Typography variant="body1">{NOTFOUND.hero_text}</Typography>

					<Stack direction={'row'}>
						<Button variant="contained" size="large" onClick={toHome}>
							{NOTFOUND.hero_go_start}
						</Button>
					</Stack>
				</Stack>
				<Image src="/images/404.png" alt={NOTFOUND.hero_header} />
			</Section>
			<Footer small />
		</>
	);
}
