/**
 * Checks if a given text is in a valid email format.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} True if the email is in a valid format, false otherwise.
 */
export function isValidEmail(email) {
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
	return emailRegex.test(email);
}

/**
 * Validates a date format in 'yyyy-mm-dd' between given limits
 * @param {string} date - The date to validate
 * @param {string} dateStart - The first date
 * @param {string} dateEnd - The last date
 * @returns {boolean} True if the date is valid and between the range
 */
export function isValidDate(date, dateStart, dateEnd) {
	const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
	if (
		!dateRegex.test(date) ||
		!dateRegex.test(dateStart) ||
		!dateRegex.test(dateEnd)
	) {
		return false;
	}
	const dateObj = new Date(date);
	const dateObjS = new Date(dateStart);
	const dateObjE = new Date(dateEnd);
	// Range validation and values
	if (
		dateObj.getTime() >= dateObjS.getTime() &&
		dateObj.getTime() <= dateObjE.getTime()
	) {
		return true;
	}
	return false;
}
