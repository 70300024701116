import { PanelCard, PanelContent, PanelHeader } from '@components/Panel';
import {
	CheckCircleOutline,
	EditOutlined,
	LockOpenOutlined,
	LockOutlined,
	MoreVertOutlined,
} from '@mui/icons-material';
import {
	Card,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { getPriceFormat } from '@utils/Format';
import useCards from '@values/Cards';
import { useState } from 'react';

/**
 *
 * @param {Object} param
 * @param {import('@values/Types').Membership} [param.item={}]
 * @param {() => void} [param.onChange=() => {}]
 * @returns
 */
function MembershipCard({ item = {}, onChange = () => {} }) {
	const [anchor, setAnchor] = useState(null);
	const handleAnchor = (e) => setAnchor(e.currentTarget);
	const handleClose = () => setAnchor(null);

	return (
		<Card
			variant="outlined"
			sx={{
				borderRadius: '12px',
			}}>
			<Stack
				direction={'row'}
				padding={'0px 8px 0px 16px'}
				height={'48px'}
				alignItems={'center'}>
				<Stack
					direction={'row'}
					alignItems={'center'}
					gap={'16px'}
					flexGrow={1}>
					<Typography variant="h6">{item.title}</Typography>
					{item.locked ? <LockOutlined /> : null}
				</Stack>
				<IconButton
					onClick={handleAnchor}
					aria-haspopup="true"
					aria-label="Opciones">
					<MoreVertOutlined />
				</IconButton>
				<Menu
					anchorEl={anchor}
					open={Boolean(anchor)}
					onClose={handleClose}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<MenuItem
						onClick={() => {
							onChange(0, item);
							handleClose();
						}}>
						<ListItemIcon>
							<EditOutlined />
						</ListItemIcon>
						<ListItemText>Editar</ListItemText>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<ListItemIcon>
							{item.locked ? <LockOpenOutlined /> : <LockOutlined />}
						</ListItemIcon>
						<ListItemText>
							{item.locked ? 'Desbloquear' : 'Bloquear'}
						</ListItemText>
					</MenuItem>
				</Menu>
			</Stack>
			<Stack direction={'row'} padding={'16px'} gap={'16px'} flexWrap={'wrap'}>
				<Stack gap={'8px'} flex={'1 0 300px'}>
					{item.description.map((value, i) => (
						<Stack
							key={i}
							alignItems={'center'}
							direction={'row'}
							gap={'8px'}>
							<CheckCircleOutline />
							<Typography variant="body1" color={'text.secondary'}>
								{value}
							</Typography>
						</Stack>
					))}
				</Stack>
				<Stack gap={'8px'} flex={'1 0 300px'}>
					<Typography variant="subtitle1">Precios (MXN)</Typography>
					<Stack
						direction={'row'}
						gap={'8px'}
						width={'100%'}
						flexWrap={'wrap'}>
						<Stack
							flexGrow={1}
							alignItems={'center'}
							padding={'4px 8px'}>
							<Typography variant="body1">
								{getPriceFormat(item.price_1)}
							</Typography>
							<Typography variant="caption" color={'text.secondary'}>
								mensual
							</Typography>
						</Stack>
						<Stack
							flexGrow={1}
							alignItems={'center'}
							padding={'4px 8px'}>
							<Typography variant="body1">
								{getPriceFormat(item.price_3)}
							</Typography>
							<Typography variant="caption" color={'text.secondary'}>
								trimestral
							</Typography>
						</Stack>
						<Stack
							flexGrow={1}
							alignItems={'center'}
							padding={'4px 8px'}>
							<Typography variant="body1">
								{getPriceFormat(item.price_6)}
							</Typography>
							<Typography variant="caption" color={'text.secondary'}>
								semestral
							</Typography>
						</Stack>
						<Stack
							flexGrow={1}
							alignItems={'center'}
							padding={'4px 8px'}>
							<Typography variant="body1">
								{getPriceFormat(item.price_12)}
							</Typography>
							<Typography variant="caption" color={'text.secondary'}>
								anual
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Card>
	);
}

export default function MembershipMain({ visible = true, onChange = () => {} }) {
	const { cards } = useCards();

	const list = cards.map((item, index) => (
		<MembershipCard key={index} item={item} onChange={onChange} />
	));

	return (
		<PanelCard
			sx={{
				display: !visible ? 'none' : '',
			}}>
			<PanelContent>
				<PanelHeader component="header">
					<Typography variant="h6" component={'h1'}>
						Membresías
					</Typography>
				</PanelHeader>
				<Stack padding={'16px'} gap={'16px'}>
					{list}
				</Stack>
			</PanelContent>
		</PanelCard>
	);
}
