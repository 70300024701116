// React Router
import { Routes, Route } from 'react-router-dom';

// Theming and tokens
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Pages
import {
	Home,
	AdminHome,
	BaseHome,
	CenterHome,
	// UserHome,
	About,
	Policies,
	Signup,
	Login,
	Recover,
	Account,
	NotFound,
} from './pages';

// Sections
import {
	HomeSection,
	PartnersSection,
	MembershipsSection,
	ActivitiesSection,
	CentersSection,
} from './sections';

import useCustomTheme from './tokens';

import { RecoverSteps } from './modules/general/RecoverSteps';
import CentersPanel from './modules/admin/CentersPanel';
import ResumePanel from './modules/admin/ResumePanel';
import MembershipPanel from './modules/admin/MembershipPanel';

import ScrollToTop from './utils/ScrollToTop';

import Offline from './components/Offline';
import { PanelCard } from './components/Panel';
import AuthPage from './components/AuthPage';
import Developing from './components/Developing';
import { APIProvider } from '@vis.gl/react-google-maps';
import { GOOGLE_API_KEY } from '@values/Default';
import Logout from '@pages/Logout';

export default function Root() {
	const theme = useCustomTheme();

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<ScrollToTop />
			<Offline>
				<APIProvider apiKey={GOOGLE_API_KEY}>
					<Routes>
						<Route path="/" element={<Home />}>
							<Route index element={<HomeSection />} />
							<Route path="partners" element={<PartnersSection />} />
							<Route
								path="memberships"
								element={<MembershipsSection />}
							/>
							<Route path="centers" element={<CentersSection />} />
							<Route
								path="activities"
								element={<ActivitiesSection />}
							/>
						</Route>
						{/* <Route path="/home" element={<AuthPage />}>
							<Route path="" element={<UserHome />}></Route>
						</Route> */}
						<Route path="/panel" element={<AuthPage />}>
							<Route path="" element={<CenterHome />}>
								<Route path="account" element={<Developing />} />
								<Route path="settings" element={<Developing />} />
								<Route path="security" element={<Developing />} />
								<Route path="logout" element={<Logout />} />
							</Route>
						</Route>
						<Route path="/base-centers" element={<AuthPage />}>
							<Route path="" element={<BaseHome />}>
								<Route index element={<CentersPanel />} />
								<Route path="centers" element={<CentersPanel />} />
								<Route path="account" element={<Developing />} />
								<Route path="settings" element={<Developing />} />
								<Route path="security" element={<Developing />} />
								<Route path="logout" element={<Logout />} />
							</Route>
						</Route>
						<Route path="/admin" element={<AuthPage />}>
							<Route path="" element={<AdminHome />}>
								<Route index element={<ResumePanel />} />
								<Route path="centers" element={<CentersPanel />} />
								<Route
									path="memberships"
									element={<MembershipPanel />}
								/>
								<Route
									path="stores"
									element={<PanelCard>Tiendas</PanelCard>}
								/>
								<Route
									path="activities"
									element={<PanelCard>Actividades</PanelCard>}
								/>
								<Route
									path="users"
									element={<PanelCard>Usuarios</PanelCard>}
								/>
								<Route
									path="server"
									element={<PanelCard>Servidor</PanelCard>}
								/>
								<Route
									path="account"
									element={<PanelCard>Cuenta</PanelCard>}
								/>
								<Route
									path="settings"
									element={<PanelCard>Ajustes</PanelCard>}
								/>
								<Route
									path="security"
									element={<PanelCard>Seguridad</PanelCard>}
								/>
								<Route path="logout" element={<Logout />} />
							</Route>
						</Route>

						<Route path="/signup" element={<Signup />} />
						<Route path="/login" element={<Login />} />

						<Route path="/about" element={<About />} />
						<Route path="/policies" element={<Policies />} />

						<Route path="/recover-account" element={<Recover />}>
							{/* Send email and get the code or only get the code (NOT SEND, only get)
								get info token, if status == 3, sen to code verification, only views
							 */}
							<Route index element={<RecoverSteps />} />
						</Route>

						<Route path="/account-options" element={<Account />} />

						<Route path="*" element={<NotFound />} />
					</Routes>
				</APIProvider>
			</Offline>
		</ThemeProvider>
	);
}
