// React Router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Root view for all routes *
import Root from 'Root';

// Router
const router = createBrowserRouter([{ path: '*', Component: Root }]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
