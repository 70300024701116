// Definiciones
export type PoliciesRes = {
	title: string;
	header: string;
	published: string;
	language: string;
	download: string;
	print: string;
	copy: string;
};

export type PoliciesLang = {
	es?: PoliciesRes;
	en?: PoliciesRes;
};

// Traducciones
export const PoliciesDictionary: PoliciesLang = {
	es: {
		title: 'Términos y Condiciones de Kaady Sport',
		header: 'Términos y Condiciones',
		published: 'Publicado el 14 de agosto de 2023',
		language: 'Idioma original español',
		download: 'Descargar',
		print: 'Imprimir',
		copy: 'Copiar enlace',
	},
};
