// Components
import Footer from '@components/Footer';
import Navbar from '@components/Navbar';
import Logo from '@components/Logo';

// Icons
import GoalIcon from '@mui/icons-material/SportsScoreOutlined';
import IdeaIcon from '@mui/icons-material/EmojiObjectsOutlined';

import { Card, Grid, Stack, Typography } from '@mui/material';
import Section from '@components/Section';
import { useLargeScreen } from '@utils/Media';
import useDictionary from '@values/Dictionary.ts';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Image from '@components/Image';
import Content from '@components/Content';

export default function About() {
	const isLarge = useLargeScreen();
	const { ABOUT } = useDictionary();
	const values_es = [
		'Transparencia',
		'Honestidad',
		'Respeto',
		'Adaptabilidad',
		'Diligencia',
		'Constancia',
		'Integridad',
		'Pasión',
		'Trabajo en equipo',
		'Liderazgo',
	];

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>{ABOUT.title}</title>
				</Helmet>
			</HelmetProvider>
			<Navbar />
			<Section component="section">
				<Stack
					direction={'column'}
					gap={'16px'}
					textAlign={!isLarge ? 'center' : ''}>
					<Typography variant={isLarge ? 'h3' : 'h4'} component={'h1'}>
						{ABOUT.hero_header}
					</Typography>
					<Typography variant="h6" color={'text.secondary'}>
						{ABOUT.hero_text}
					</Typography>
				</Stack>
				<Image src="/images/training_1.png" alt={ABOUT.hero_alt} />
			</Section>
			<Content>
				<Stack direction={'column'} gap={'16px'}>
					<Typography variant={'h4'} component={'h1'}>
						{ABOUT.about_header}
					</Typography>
					<Typography variant={'h6'} color={'text.secondary'}>
						{ABOUT.about_text_1}
					</Typography>
					<Typography variant={'h6'} color={'text.secondary'}>
						{ABOUT.about_text_2}
					</Typography>
				</Stack>
				<Stack
					direction={isLarge ? 'row' : 'column-reverse'}
					gap={'24px'}
					alignItems={isLarge ? 'start' : 'center'}>
					<Stack gap={'24px'}>
						<Card
							variant="outlined"
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Stack
								padding={'16px'}
								direction={'row'}
								alignItems={'center'}
								gap={'8px'}>
								<GoalIcon fontSize="large" />
								<Typography variant="h5">{ABOUT.mission}</Typography>
							</Stack>
							<Stack padding={'0px 16px 24px 16px'}>
								<Typography variant="body1" color={'text.secondary'}>
									{ABOUT.mission_content}
								</Typography>
							</Stack>
						</Card>
						<Card
							variant="outlined"
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Stack
								padding={'16px'}
								direction={'row'}
								alignItems={'center'}
								gap={'8px'}>
								<IdeaIcon fontSize="large" />
								<Typography variant="h5">{ABOUT.vision}</Typography>
							</Stack>
							<Stack padding={'0px 16px 24px 16px'}>
								<Typography variant="body1" color={'text.secondary'}>
									{ABOUT.vision_content}
								</Typography>
							</Stack>
						</Card>
					</Stack>
					<Logo type="square" height="250px" />
				</Stack>
			</Content>
			<Content>
				<Stack direction={'column'} gap={'16px'}>
					<Typography variant={'h4'} component={'h1'}>
						{ABOUT.values_header}
					</Typography>
				</Stack>
				<Grid
					container
					spacing={2}
					alignItems={'center'}
					justifyContent={'start'}>
					{values_es.map((item, index) => (
						<Grid key={index} item xs={'auto'}>
							<Stack
								direction={'row'}
								padding={'6px 12px'}
								sx={(t) => ({
									backgroundColor: t.palette.secondary.main,
									color: t.palette.secondary.contrastText,
									borderRadius: '8px',
								})}>
								<Typography variant="body1">{item}</Typography>
							</Stack>
						</Grid>
					))}
				</Grid>
			</Content>
			<Content>
				<Stack direction={'column'} gap={'16px'}>
					<Typography variant={'h4'} component={'h1'}>
						{ABOUT.goal_header}
					</Typography>
					<Typography variant={'h6'} color={'text.secondary'}>
						{ABOUT.goal_text}
					</Typography>
				</Stack>
			</Content>
			<Footer />
		</>
	);
}
