// Definiciones
export type AboutRes = {
	title: string;
	hero_header: string;
	hero_text: string;
	hero_alt: string;
	about_header: string;
	about_text_1: string;
	about_text_2: string;
	mission: string;
	mission_content: string;
	vision: string;
	vision_content: string;
	values_header: string;
	goal_header: string;
	goal_text: string;
};

export type AboutLang = {
	es?: AboutRes;
	en?: AboutRes;
};

// Traducciones
export const AboutDictionary: AboutLang = {
	es: {
		title: 'Conoce acerca de Kaady Sport',
		hero_header: 'Conoce acerca de nosotros',
		hero_text: 'Haz ejercicio',
		hero_alt: 'Entrenamiento con pesas',
		about_header: '¿Quiénes somos?',
		about_text_1:
			'Kaady Sport es una empresa dedicada a la venta de membresías deportivas para que los clientes puedan asistir a distintos lugares donde quiera que se encuentren y llevar a cabo entrenamientos o actividades físicas diversas de su gusto.',
		about_text_2:
			'Nos distinguimos por reconocer a toda la ciudadanía fitness y al público en general para apoyarlos en conseguir sus objetivos personales por medio de la activación física que además puede ayudar en áreas psicológicas y emocionales.',
		mission: 'Misión',
		mission_content:
			'Consolidarnos como la empresa tecnológica nacional número uno en venta de membresías en apoyo a gente que quiere mejorar su estado físico, psicológico y mental, así como impulsar un programa de inclusión gratuito para personas con capacidades diferentes para que puedan reforzar su sistema físico y emocional.',
		vision: 'Visión',
		vision_content:
			'Ser una empresa reconocida en todo el país agregando valor humano y generando calidad de vida, haciendo que el mayor número de ciudadanos se conviertan en fitness y realicen distintas actividades físicas para mejorar la salud mundial.',
		values_header: 'Valores de la empresa',
		goal_header: 'Objetivo principal',
		goal_text:
			'Nuestro objetivo es lograr que cada vez más gente realice alguna actividad física para acabar con la obesidad mundial e inculcar buenos hábitos y costumbres rutinarias.',
	},
};
