/**
 * Loads the sdk from facebook
 * @param {string} appId
 */
const loadFacebookSDK = (appId) => {
	// Verificar si el script ya existe
	const existingScript = document.querySelector(
		'[src^="https://connect.facebook.net/es_ES/sdk.js"]'
	);

	if (!existingScript) {
		const script = document.createElement('script');
		script.async = true;
		script.defer = true;
		script.crossOrigin = 'anonymous';
		script.src = `https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v19.0&appId=${appId}`;
		script.nonce = 'DT6HXnKJ';

		document.head.appendChild(script);
	}
};

export default loadFacebookSDK;
