import { PanelCard, PanelContent, PanelHeader } from '@components/Panel';
import { Stack, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { getPriceFormat } from '@utils/Format';
import { useLargeScreen } from '@utils/Media';
import { getLabelMonth } from '@values/General';

export default function ResumePanel() {
	const isLarge = useLargeScreen();
	const sales = [
		{
			month: 0,
			sale: 0,
		},
		{
			month: 1,
			sale: 0,
		},
		{
			month: 2,
			sale: 0,
		},
		{
			month: 3,
			sale: 0,
		},
		{
			month: 4,
			sale: 0,
		},
		{
			month: 5,
			sale: 500,
		},
		{
			month: 6,
			sale: 0,
		},
		{
			month: 7,
			sale: 0,
		},
		{
			month: 8,
			sale: 0,
		},
		{
			month: 9,
			sale: 0,
		},
		{
			month: 10,
			sale: 0,
		},
		{
			month: 11,
			sale: 0,
		},
	];
	const resume = [
		{
			section: 'Centros',
			count: 10,
		},
		{
			section: 'Tiendas',
			count: 5,
		},
		{
			section: 'Usuarios',
			count: 15,
		},
		{
			section: 'Membresías',
			count: 2,
		},
	];
	return (
		<PanelCard>
			<PanelContent>
				<PanelHeader component="header">
					<Typography variant="h6" component={'h1'}>
						Resumen de 2024
					</Typography>
				</PanelHeader>
				<Stack
					direction={'row'}
					padding={'16px'}
					gap={'16px'}
					flexWrap={'wrap'}>
					{resume.map((item, index) => (
						<Stack
							key={index}
							flexGrow={1}
							alignItems={'center'}
							padding={'8px 16px'}>
							<Typography variant="h6">{item.count}</Typography>
							<Typography variant="body2" color={'text.secondary'}>
								{item.section}
							</Typography>
						</Stack>
					))}
				</Stack>
				<Stack
					width={'100%'}
					flexGrow={1}
					justifyContent={'center'}
					padding={'0px 16px'}>
					<BarChart
						height={350}
						skipAnimation
						dataset={sales}
						tooltip={{ trigger: 'none' }}
						margin={{
							right: 16,
						}}
						axisHighlight={{
							y: 'line',
						}}
						xAxis={[
							{
								scaleType: 'band',
								dataKey: 'month',
								valueFormatter: (value) =>
									getLabelMonth(value, 'es', isLarge ? -1 : 1),
								tickMinStep: 1,
							},
						]}
						series={[
							{
								label: 'Ventas por mes',
								dataKey: 'sale',
								valueFormatter: (value) => getPriceFormat(value),
							},
						]}
					/>
				</Stack>
			</PanelContent>
		</PanelCard>
	);
}
