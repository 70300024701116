import { Button, Grid, Stack, Typography } from '@mui/material';

// Icons
import StatsIcon from '@mui/icons-material/QueryStats';
import MonetizationIcon from '@mui/icons-material/MonetizationOnOutlined';
import PublicIcon from '@mui/icons-material/PublicOutlined';
import FitnessIcon from '@mui/icons-material/FitnessCenterOutlined';
import WidgetsIcon from '@mui/icons-material/WidgetsOutlined';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import useDictionary from '@values/Dictionary.ts';

import Section from '@components/Section';
import Image from '@components/Image';
import Content from '@components/Content';
import { useLargeScreen } from '@utils/Media';

export default function PartnersSection() {
	const isLarge = useLargeScreen();
	const { PARTNERS } = useDictionary();
	const benefits = [
		{
			icon: <StatsIcon color="primary" />,
			content: PARTNERS.benefit_1,
		},
		{
			icon: <MonetizationIcon color="primary" />,
			content: PARTNERS.benefit_2,
		},
		{
			icon: <FitnessIcon color="primary" />,
			content: PARTNERS.benefit_3,
		},
		{
			icon: <PublicIcon color="primary" />,
			content: PARTNERS.benefit_4,
		},
		{
			icon: <WidgetsIcon color="primary" />,
			content: PARTNERS.benefit_5,
		},
	];
	const activities = [
		'Boxeo',
		'Danza',
		'Taekwondo',
		'Halterofilia',
		'Cardio',
		'Natación',
		'Crossfit',
		'Gimnasia',
		'Pesas',
		'Spinning',
		'Running',
	];

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>{PARTNERS.title}</title>
				</Helmet>
			</HelmetProvider>
			<Section component={'section'}>
				<Stack
					direction={'column'}
					gap={'16px'}
					textAlign={isLarge ? '' : 'center'}>
					<Typography variant={isLarge ? 'h3' : 'h4'} component={'h1'}>
						{PARTNERS.hero_header}
					</Typography>
					<Typography variant="h6" color={'text.secondary'}>
						{PARTNERS.hero_text}
					</Typography>
				</Stack>
				<Image src="/images/training_2.png" alt={PARTNERS.hero_alt} />
			</Section>
			<Content>
				<Stack direction={'column'} gap={'16px'} textAlign={'center'}>
					<Typography variant={'h4'} component={'h1'}>
						{PARTNERS.benefit_header}
					</Typography>
				</Stack>
				<Grid container spacing={2} alignItems={'center'}>
					{benefits.map((item, index) => (
						<Grid key={index} item xs={12} sm={6}>
							<Stack
								direction={'row'}
								gap={'8px'}
								alignItems={'center'}
								padding={'6px 12px'}>
								{item.icon}
								<Typography variant="subtitle1">
									{item.content}
								</Typography>
							</Stack>
						</Grid>
					))}
				</Grid>
			</Content>
			<Content>
				<Stack direction={'column'} gap={'16px'} textAlign={'center'}>
					<Typography variant={'h4'} component={'h1'}>
						{PARTNERS.activity_header}
					</Typography>
					<Typography variant={'h6'} color={'text.secondary'}>
						{PARTNERS.activity_text}
					</Typography>
				</Stack>
				<Grid
					container
					spacing={2}
					alignItems={'center'}
					justifyContent={'center'}>
					{activities.map((item, index) => (
						<Grid key={index} item xs={'auto'}>
							<Stack
								direction={'row'}
								padding={'6px 12px'}
								sx={(t) => ({
									backgroundColor: t.palette.secondary.main,
									color: t.palette.secondary.contrastText,
									borderRadius: '8px',
								})}>
								<Typography variant="body1">{item}</Typography>
							</Stack>
						</Grid>
					))}
				</Grid>
			</Content>
			<Section
				sx={{
					flexDirection: 'column',
					padding: isLarge ? '90px 56px' : '56px 16px',
				}}>
				<Image src="/images/undraw_form.png" alt={PARTNERS.joining_alt} />
				<Stack
					component={'header'}
					direction={'column'}
					gap={'36px'}
					flexGrow={1}
					width={'100%'}>
					<Stack direction={'column'} gap={'16px'} textAlign={'center'}>
						<Typography variant={'h4'} component={'h1'}>
							{PARTNERS.joining_header}
						</Typography>
						<Typography variant="h6" color={'text.secondary'}>
							{PARTNERS.joining_text}
						</Typography>
					</Stack>
					<Stack
						width={'100%'}
						direction={'row'}
						justifyContent={'center'}>
						<Button variant="contained">
							{PARTNERS.joining_action}
						</Button>
					</Stack>
				</Stack>
			</Section>
		</>
	);
}
