import { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Section from './Section';
import Image from './Image';
import { Button, Stack, Typography } from '@mui/material';
import { useLargeScreen } from '@utils/Media';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '@utils/Auth';
import Loading from 'pages/Loading';

export default function AuthPage() {
	const navigate = useNavigate();
	const [isAuth, setIsAuth] = useState(false);
	const [completed, setCompleted] = useState(false);
	const isLarge = useLargeScreen();

	useEffect(() => {
		async function checkAuthentication() {
			const res_auth = await isAuthenticated();
			setIsAuth(res_auth);
			setCompleted(true);
		}

		checkAuthentication();

		function handleFocus() {
			checkAuthentication();
		}

		function handleClick(event) {
			if (event.target.tagName === 'BUTTON' || event.target.tagName === 'A') {
				checkAuthentication();
			}
		}

		document.addEventListener('click', handleClick);
		window.addEventListener('focus', handleFocus);

		return () => {
			window.removeEventListener('focus', handleFocus);
			document.removeEventListener('click', handleClick);
		};
	}, []);

	if (!completed) {
		return <Loading open={!completed} message="Verificando authorización.." />;
	}

	if (completed && !isAuth) {
		return (
			<>
				<HelmetProvider>
					<Helmet>
						<title>Se requiere permisos de acceso</title>
					</Helmet>
				</HelmetProvider>
				<Navbar isFixed={false} showOptions={false} />
				<Section component={'section'}>
					<Stack gap={'36px'}>
						<Stack
							direction={'column'}
							gap={'16px'}
							textAlign={!isLarge ? 'center' : ''}>
							<Typography
								variant={isLarge ? 'h3' : 'h4'}
								component={'h1'}>
								Acceso denegado
							</Typography>
							<Typography variant="h6" color={'text.secondary'}>
								Se requiere más permisos para ver la página actual,
								inicie sesión de nuevo o solicite acceso con su
								administrador.
							</Typography>
						</Stack>
						<Stack
							direction={'row'}
							gap={'16px'}
							justifyContent={isLarge ? 'start' : 'center'}
							width={'100%'}>
							<Button
								variant="contained"
								onClick={() => navigate('/login')}>
								Iniciar sesión
							</Button>
							<Button
								variant="text"
								href="mailto:contacto@kaadysport.com?subject=Ayuda%20Kaady%20Sport&body=Hola,%20tengo%20un%20problema%20con...">
								Ayuda
							</Button>
						</Stack>
					</Stack>
					<Image src="/images/undraw_auth.png" alt="Requiere permisos" />
				</Section>
			</>
		);
	}

	return <Outlet />;
}
