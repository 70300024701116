import {
	PanelAction,
	PanelCard,
	PanelContent,
	PanelHeader,
} from '@components/Panel';
import { CloseOutlined, SaveOutlined } from '@mui/icons-material';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

/**
 *
 * @param {Object} param
 * @param {import('@values/Types').Membership} [param.item={}]
 * @param {() => void} [param.onClose=() => {}]
 * @returns
 */
export default function MembershipEdit({ item = {}, onClose = () => {} }) {
	const [values, setValues] = useState({ ...item });
	const handleChange = (e) => {
		const { name, value } = e.target;
		setValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleDescriptionChange = (index) => (e) => {
		const value = [...values.description];
		value[index] = e.target.value;
		setValues((prev) => ({
			...prev,
			description: value,
		}));
	};

	return (
		<PanelCard>
			<PanelContent>
				<PanelHeader>
					<Stack flexGrow={'1'}>
						<Typography variant="h6" component={'h1'}>
							{values.title}
						</Typography>
						<Typography variant="caption" color={'text.secondary'}>
							Editar membresía
						</Typography>
					</Stack>
					<IconButton title="Close" onClick={onClose}>
						<CloseOutlined />
					</IconButton>
				</PanelHeader>
				<Stack flexGrow={1} component={'form'}>
					<Stack padding={'16px'} gap={'8px'}>
						<Typography variant="subtitle1">Precios</Typography>
						<Stack direction={'row'} gap={'8px'}>
							<TextField
								fullWidth
								variant="filled"
								label="Mensual"
								name="price_1"
								value={values.price_1}
								onChange={handleChange}
							/>
							<TextField
								fullWidth
								variant="filled"
								label="Trimestral"
								name="price_3"
								value={values.price_3}
								onChange={handleChange}
							/>
						</Stack>
						<Stack direction={'row'} gap={'8px'}>
							<TextField
								fullWidth
								variant="filled"
								label="Semestral"
								name="price_6"
								value={values.price_6}
								onChange={handleChange}
							/>
							<TextField
								fullWidth
								variant="filled"
								label="Anual"
								name="price_12"
								value={values.price_12}
								onChange={handleChange}
							/>
						</Stack>
					</Stack>
					<Stack padding={'16px'} gap={'8px'}>
						<Typography variant="subtitle1">Beneficios</Typography>
						{values.description.map((value, index) => (
							<TextField
								key={index}
								fullWidth
								variant="filled"
								label={`Beneficio ${index + 1}`}
								value={value}
								onChange={handleDescriptionChange(index)}
							/>
						))}
						<Stack direction={'row'}>
							<Button variant="contained" color="tonal">
								Agregar beneficio
							</Button>
						</Stack>
					</Stack>
				</Stack>
				<PanelAction>
					<Button
						variant="contained"
						sx={{
							padding: '8px 16px',
							lineHeight: 'normal',
						}}
						startIcon={<SaveOutlined />}>
						Guardar
					</Button>
				</PanelAction>
			</PanelContent>
		</PanelCard>
	);
}
