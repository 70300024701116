// Modules
import useDictionary from '@values/Dictionary.ts';
import Content from '@components/Content';
import StyledTextField from '@components/StyledTextField';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
	Autocomplete,
	Avatar,
	Card,
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Typography,
	alpha,
} from '@mui/material';
import Chip from '@components/Chip';
import CentersMap from '@modules/shared/CentersMap';
import { useLargeScreen } from '@utils/Media';
import { useEffect, useState } from 'react';
import { requestAllCenters } from '@utils/Centers';
import { capitalizeFirstLetter } from '@utils/Format';
//import useCenters from '@values/Centers';

/**
 *
 * @param {Object} param0
 * @param {import('@values/Types').CenterType} param0.item
 * @returns
 */
function CenterItem({ item }) {
	return (
		<ListItem disablePadding>
			<ListItemButton
				sx={{
					display: 'flex',
					flexDirection: 'row',
					padding: '8px 12px',
					gap: '16px',
					alignItems: 'center',
				}}>
				<Avatar
					alt={item.name}
					src={item.picture.url}
					sx={(t) => ({
						bgcolor: alpha(t.palette.primary.main, 0.72),
					})}>
					{item.name.charAt(0)}
				</Avatar>
				<Stack direction={'column'} gap={'4px'}>
					<Typography variant="subtitle1" component={'h6'}>
						{item.name}
					</Typography>
					<Typography variant="body2" color={'text.secondary'}>
						{item.locations.length > 0
							? capitalizeFirstLetter(item.locations[0].zone.name) +
							  ', ' +
							  item.locations[0].zone.region
							: null}
					</Typography>
					<Stack
						padding={'4px 8px'}
						sx={(t) => ({
							width: 'fit-content',
							backgroundColor: t.palette.secondary.main,
							color: t.palette.secondary.contrastText,
							borderRadius: '6px',
						})}>
						<Typography variant="caption">{item.status.name}</Typography>
					</Stack>
				</Stack>
			</ListItemButton>
		</ListItem>
	);
}

export default function CentersSection() {
	const isLarge = useLargeScreen();
	const { CENTERS } = useDictionary();
	const [loading, setLoading] = useState(true);
	const [centers, setCenters] = useState([]);

	useEffect(() => {
		const loadCenters = async () => {
			const res = await requestAllCenters();
			setCenters(res);
			setLoading(false);
		};
		loadCenters();
	}, []);

	return (
		<Content component={'section'}>
			<HelmetProvider>
				<Helmet>
					<title>{CENTERS.title}</title>
				</Helmet>
			</HelmetProvider>
			<Stack direction={'column'} gap={'16px'}>
				<Typography variant="h3" component={'h1'}>
					{CENTERS.hero_header}
				</Typography>
				<Typography variant="h6" color={'text.secondary'}>
					{CENTERS.hero_text}
				</Typography>
			</Stack>
			<Stack direction={isLarge ? 'row' : 'column-reverse'} gap={'16px'}>
				<Stack
					direction={'row'}
					flexWrap={'nowrap'}
					overflow={'auto'}
					gap={'8px'}
					padding={'6px 0px'}
					width={'100%'}
					flexGrow={1}>
					<Chip
						label={CENTERS.near_me}
						onChange={(check) => console.log(check)}
					/>
					<Chip label={CENTERS.gyms} />
					<Chip label={CENTERS.stores} />
				</Stack>
				<Autocomplete
					freeSolo
					options={centers.map((value) => value.name)}
					renderInput={(params) => (
						<StyledTextField
							{...params}
							placeholder={CENTERS.search_center}
						/>
					)}
				/>
			</Stack>
			<Stack
				direction={isLarge ? 'row' : 'column-reverse'}
				gap={'24px'}
				height={isLarge ? 'calc(100vh - 210px)' : 'auto'}>
				<Card
					variant="outlined"
					sx={{
						width: isLarge ? '270px' : '100%',
						height: isLarge ? '100%' : 'auto',
						display: 'flex',
						flexDirection: 'column',
					}}>
					<Stack
						component={'header'}
						direction={'column'}
						padding={'12px 16px'}
						sx={(t) => ({
							backgroundColor: t.palette.background.variant,
						})}>
						<Typography variant="h6">
							{centers.length} {CENTERS.places.toLocaleLowerCase()}
						</Typography>
					</Stack>
					<Divider />
					<List
						sx={{
							flexGrow: '1',
							overflow: 'auto',
						}}>
						{loading && (
							<ListItem>
								<CircularProgress
									sx={{
										margin: '28px auto',
									}}
								/>
							</ListItem>
						)}
						{centers.map((item, index) => (
							<CenterItem key={index} item={item} />
						))}
					</List>
				</Card>
				<Card
					variant="outlined"
					sx={{
						flexGrow: '1',
						height: isLarge ? '100%' : 'calc(100vh - 250px)',
					}}>
					<CentersMap centers={centers} />
				</Card>
			</Stack>
		</Content>
	);
}
