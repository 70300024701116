import { ContactType, StatusType } from '@values/Types';
import { Status } from './Status.ts';

export class Contact implements ContactType {
	id?: number;
	type: string;
	value: string;
	prefix?: string;
	is_valid: boolean;
	permission_promo?: boolean;
	permission_recover?: boolean;
	permission_two_steps?: boolean;
	permission_billing?: boolean;
	status_code?: number;
	status: StatusType;

	constructor(data: any) {
		this.id = data?.id;
		this.type = data?.type || '';
		this.value = data?.value || '';
		this.prefix = data?.prefix;
		this.is_valid = data?.is_valid || false;
		this.permission_promo = data?.permission_promo;
		this.permission_recover = data?.permission_recover;
		this.permission_two_steps = data?.permission_two_steps;
		this.permission_billing = data?.permission_billing;
		this.status_code = data?.status_code;
		this.status = new Status(data?.status);
	}
}
