import { PointType, StatusType, ZoneType } from '@values/Types';
import { Status } from './Status.ts';
import { Point } from './Point.ts';

export class Zone implements ZoneType {
	id: number;
	region: string;
	name: string;
	description: string;
	points?: PointType[];
	status_code?: number;
	status: StatusType;

	constructor(data: any) {
		this.id = data?.id || 5;
		this.region = data?.region || 'EDOMEX';
		this.name = data?.name || 'NEZAHUALCOYOTL';
		this.description = data?.description || '';
		this.points = Array.isArray(data?.points)
			? data?.points.map((item: any) => new Point(item))
			: [];
		this.status_code = data?.status_code;
		this.status = new Status(data?.status);
	}
}
