/**
 * Get the string for a price format in MXN
 * @param {number} price The price to format
 * @returns {string}
 */
export function getPriceFormat(price) {
	const formatter = new Intl.NumberFormat('es-MX', {
		style: 'currency',
		currency: 'MXN',
	});

	return formatter.format(price);
}

/**
 * Convert a string with capital first letter
 * @param {string} value
 * @returns {string|null}
 */
export function capitalizeFirstLetter(value) {
	if (value === null || value === undefined) return null;
	return value
		.toLowerCase()
		.split(' ')
		.map((word) => {
			return word.charAt(0).toUpperCase() + word.slice(1);
		})
		.join(' ');
}
