import { useState } from 'react';
import MembershipEdit from './sections/MembershipEdit';
import { useExtraScreen } from '@utils/Media';
import MembershipMain from './sections/MembershipMain';

export default function MembershipPanel() {
	const isExtra = useExtraScreen();
	const [openSecondary, setOpenSecondary] = useState(false);
	const [item, setItem] = useState(null);
	const [index, setIndex] = useState(0);
	const handleChange = (i, item) => {
		setIndex(i);
		setItem(item);
		setOpenSecondary(true);
	};
	const handleClose = () => setOpenSecondary(false);
	const secondaries = [<MembershipEdit item={item} onClose={handleClose} />];

	return (
		<>
			<MembershipMain
				onChange={handleChange}
				visible={isExtra || !openSecondary}
			/>
			{openSecondary && secondaries[index]}
		</>
	);
}
