import { styled } from '@mui/material';

const SocialLink = styled('a')(({ theme }) => {
	return {
		display: 'flex',
		padding: '4px',
		cursor: 'pointer',
		color: theme.palette.text.primary,
		fill: theme.palette.text.primary,
		borderRadius: '50px',
		':hover': {
			color: theme.palette.primary.contrastText,
			fill: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.main,
		},
	};
});

export default SocialLink;
