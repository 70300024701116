import { SocialType } from '@values/Types';

export class Social implements SocialType {
	facebook: string;
	instagram: string;
	tiktok: string;
	twitter: string;

	constructor(data: any) {
		this.facebook = data?.facebook || '';
		this.instagram = data?.instagram || '';
		this.tiktok = data?.tiktok || '';
		this.twitter = data?.twitter || '';
	}
}
