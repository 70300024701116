import { IconButton, Stack, Typography, styled } from '@mui/material';

// Icons
import PrintIcon from '@mui/icons-material/PrintOutlined';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';

import Footer from '@components/Footer';
import Navbar from '@components/Navbar';
import Content from '@components/Content';
import useDictionary from '@values/Dictionary.ts';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLargeScreen } from '@utils/Media';

const ItemRoot = styled(Stack)({
	display: 'flex',
	flexDirection: 'column',
	margin: '0 auto',
	gap: '8px',
	maxWidth: '880px',
});

const SECTIONS_ES = [
	{
		title: 'Propietario de Kaady Sport, oferta y políticas de privacidad',
		content: (
			<Stack gap={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					La página web <strong>www.kaadysport.com</strong> es propiedad y
					está operada por Kaady Sport S.A. de C.V. El uso de los sitios,
					servicios y productos ofrecidos por Kaady Sport está sujeto a los
					siguientes términos y políticas. A continuación, se establecen
					los términos y condiciones bajo los cuales un usuario puede
					utilizar nuestra página web y servicios. En caso de surgir algún
					imprevisto, se resolverá directamente con el departamento legal
					de Kaady Sport mediante el correo{' '}
					<strong>legal@kaadysport.com</strong> o a través de un
					representante verificado, público y autorizado perteneciente a la
					empresa Kaady Sport.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					Nuestra aplicación y página web ofrecen a los visitantes los
					beneficios que las membresías de Kaady Sport brindan
					individualmente en sus cuatro presentaciones: básica, silver, oro
					y black. Los beneficios correspondientes a cada membresía
					adquirida por el usuario serán proporcionados y descritos al
					momento de la compra. Al adquirir tu membresía Kaady Sport, se
					otorga el acceso a nuestra aplicación disponible para Android e
					iOS. Para otorgar el servicio se requerirá de datos personales
					del usuario. Es responsabilidad del usuario que los datos sean
					reales, verídicos, y completos. Todos los datos proporcionados
					serán debidamente protegidos conforme a las legislaciones
					vigentes. Los datos se resguardan, almacenan y se usan en los
					sitios de Kaady Sport de forma segura, encriptada y privada, y
					solo se usan para brindar un mejor servicio. Solamente en casos
					de aclaraciones y/o procesos legales, los datos del usuario serán
					compartidos con las autoridades correspondientes, y es
					responsabilidad del departamento legal de Kaady Sport compartir
					estos datos de forma parcial y/o completa. Esto garantiza que
					nuestra aplicación brinde funciones útiles complementarias a los
					entrenamientos y/o actividades físicas para los usuarios que
					adquieran una membresía Kaady Sport.
				</Typography>
				<Stack gap={'8px'}>
					<Typography variant="subtitle1">
						¿Quién puede usar la página web? Requisitos para crear una
						cuenta
					</Typography>
					<Typography variant="body1" color={'text.secondary'}>
						Para hacer uso de nuestra página web y/o recibir nuestros
						servicios, el usuario debe contar con la capacidad y
						condición física necesaria. En caso de ser menor de edad,
						deberá contar con previa autorización de un padre o tutor, o
						cumplir con la mayoría de edad legal establecida en la
						jurisdicción correspondiente. Además, deberá poseer la
						capacidad jurídica, el derecho y la libertad para participar
						en estos términos como un acuerdo vinculante. No se permite
						utilizar esta página web y/o recibir servicios en caso de
						prohibición o de acuerdo con cualquier ley o regulación
						aplicable al caso en el país, ciudad o lugar de residencia
						del usuario.
					</Typography>
				</Stack>
			</Stack>
		),
	},
	{
		title: 'Requisitos y políticas de uso para clientes',
		content: (
			<Stack gap={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					Al comprar tu membresía Kaady Sport, aceptas que:
				</Typography>
				<Stack gap={'16px'} paddingLeft={'24px'}>
					<Typography variant="body1" color={'text.secondary'}>
						Eres responsable de leer todas y cada una de las condiciones,
						términos e información relevante sobre el uso antes de
						comprometerte a adquirirla.
					</Typography>
					<Typography variant="body1" color={'text.secondary'}>
						Celebras un contrato legalmente vinculante para adquirir uno
						o más servicios (según sea el caso de compra) mediante
						membresías deportivas y te comprometes a completar el proceso
						de check-out, ya sea mediante pago con tarjetas de crédito,
						débito o en efectivo en los establecimientos permitidos.
					</Typography>
				</Stack>
				<Typography variant="body1" color={'text.secondary'}>
					Los precios que se cobran por los servicios que ofrece Kaady
					Sport se encuentran enumerados en la página web. Kaady Sport se
					reserva el derecho de cambiar los precios de sus productos y/o
					servicios mostrados en cualquier momento y de corregir los
					errores de precios que puedan ocurrir de forma inadvertida. La
					información adicional sobre precios e impuestos sobre las ventas
					está disponible en la página de pagos.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					La tarifa por los servicios y cualquier otro cargo relacionado
					con el uso del servicio del cliente, como impuestos y posibles
					tarifas de transacción, se cobrará de acuerdo con el método de
					pago seleccionado por el mismo, en moneda nacional que esté en
					circulación de acuerdo con la zona, región y/o lugar donde se
					contrata el servicio por parte del cliente.
				</Typography>
			</Stack>
		),
	},
	{
		title: 'Política de devoluciones y reembolsos',
		content: (
			<Stack className="paragraphs body-large on-surface-variant-text">
				<Typography variant="body1" color={'text.secondary'}>
					No se aceptan devoluciones de membresías físicas ni virtuales, ya
					que cada una está personalizada de acuerdo con los datos y
					registro del usuario. En ningún caso se brindarán reembolsos,
					exceptuando situaciones justificadas, donde se podrá modificar el
					contrato a conveniencia de las partes.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					En casos de inconformidad directamente con el servicio de Kaady
					Sport, el usuario deberá comunicarse con el departamento
					responsable, con la finalidad de brindar una solución y mejorar
					el servicio.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					Las cuotas pagadas por el periodo de cualquiera de las membresías
					o servicios adicionales ofrecidos por Kaady Sport no son
					reembolsables ni compensables en los períodos no utilizados.
				</Typography>
			</Stack>
		),
	},
	{
		title: 'Retención del derecho a cambiar la oferta',
		content: (
			<Typography variant="body1" color={'text.secondary'}>
				Por cualquier motivo o sin alguno, Kaady Sport puede establecer
				límites y/o realizar cambios en los servicios, sin previo aviso ni
				responsabilidad, suspendiendo de manera temporal o permanente el
				acceso a los mismos en caso que el usuario se haga acreedor a una
				sanción o actúe de manera irresponsable de acuerdo a las normas y
				políticas de Kaady Sport y de su comunidad de establecimientos.
			</Typography>
		),
	},
	{
		title: 'Garantías y responsabilidad por servicios y productos',
		content: (
			<Typography variant="body1" color={'text.secondary'}>
				En caso de presentarse errores en la personalización de las
				membresías o la contratación de servicios, Kaady Sport se compromete
				a dar pronta solución al defecto relevante o a proceder con el
				reemplazo del producto, según corresponda. En el evento de no ser
				posible continuar realizar la reparación o el reemplazo en un plazo
				de tiempo razonable, Kaady Sport asumirá el costo por envío de los
				productos reparados o reemplazados al cliente, quien a su vez será
				responsable de la devolución del producto físico erróneo.
			</Typography>
		),
	},
	{
		title: 'Posesión de propiedad intelectual, derechos de autor y logos',
		content: (
			<Typography variant="body1" color={'text.secondary'}>
				El servicio y todos los materiales incluidos o transferidos,
				incluyendo, sin limitación, software, imágenes, textos, gráficos,
				logotipos, patentes, marcas registradas, marcas de servicio, derechos
				de autor, fotografías, audio, videos, música y todos los derechos de
				propiedad intelectual asociados, son propiedad exclusiva de Kaady
				Sport. A menos que se indique explícitamente en este documento, no se
				otorgará ninguna licencia en o bajo ninguno de dichos derechos de
				propiedad intelectual mediante estos términos. El usuario se
				compromete a no vender, alquilar, modificar, distribuir, copiar,
				reproducir, transmitir, exhibir públicamente, publicar, adaptar,
				editar o crear trabajos derivados basados en los mismos.
			</Typography>
		),
	},
	{
		title: 'Derecho a suspender o cancelar la cuenta de usuario. Renovaciones y cancelación del servicio',
		content: (
			<Stack gap={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					En caso de infringir los presentes términos y condiciones, o
					cualquier ley o regulación aplicable, en Kaady Sport nos
					reservamos el derecho de suspender de manera temporal o
					permanente el acceso de la cuenta perteneciente al usuario
					infractor, sin previo aviso y sin asumir responsabilidad alguna.
					Las cuentas que se encuentren suspendidas no pueden ser
					reactivadas creando una cuenta nueva en nuestros sitios
					registrando los mismos datos del usuario, ni por ningún otro
					medio. En caso de haber algún error de cancelación de cuenta, es
					responsabilidad del usuario entregar evidencia y/o solicitarla
					por medio de nuestros medios de contacto. Asimismo, el usuario
					tiene la posibilidad de solicitar la cancelación de su cuenta y/o
					cualquier servicio en cualquier momento, sin perjuicio ni
					penalización por ello.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					En cuanto a las suscripciones renovadas automáticamente a los
					servicios pagados, dichas suscripciones se suspenderán únicamente
					al finalizar el período correspondiente por el cual ya se ha
					efectuado el pago. Por lo que, si se cancela un servicio antes
					del periodo de renovación, el servicio seguirá activo hasta que
					finalice el periodo correspondiente, en ningún caso habrá
					reembolsos por cancelar una membresía antes de la renovación del
					servicio. Los pagos de renovación que no se efectúen después de
					tres intentos, o después de un día hábil de la fecha de
					renovación, se consideran como suspensión inmediata del servicio
					y/o beneficios de la membresía correspondiente.
				</Typography>
			</Stack>
		),
	},
	{
		title: 'Indemnización',
		content: (
			<Typography variant="body1" color={'text.secondary'}>
				El usuario acuerda indemnizar y eximir de toda responsabilidad a
				Kaady Sport de cualquier acto jurídico (incluyendo honorarios de
				abogados), responsabilidad, reclamo o gasto que se realice en su
				contra como consecuencia, derivado o en relación con el uso que dé a
				las plataformas de Kaady Sport o cualquiera de los servicios
				ofrecidos por la misma. Kaady Sport se deslinda de responsabilidades
				económicas si el usuario presenta problemas con los establecimientos
				donde realiza cierta actividad o toma cierta clase, ya sean de
				cualquier índole, moral o físico.
			</Typography>
		),
	},
	{
		title: 'Limitación de responsabilidad',
		content: (
			<Stack gap={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					En la máxima medida permitida por las leyes vigentes aplicables,
					Kaady Sport no asume responsabilidad alguna por errores o
					inexactitudes de contenido, lesiones personales o daños a la
					propiedad, de cualquier naturaleza que sean, como resultado del
					acceso o uso indebido de nuestros servicios, cualquier acceso no
					autorizado o uso indebido de nuestros servidores seguros y/o un
					mal uso de toda la información personal almacenada en los mismos.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					Kaady Sport no será responsable de los problemas de salud que los
					usuarios puedan sufrir como consecuencia del uso de alguna de las
					instalaciones o de nuestras rutinas virtuales de ejercicios. Por
					lo tanto, se recomienda que los usuarios consulten con un médico
					su estado de salud antes de contratar nuestros servicios,
					especialmente en padecimientos como: tensión alta, angina de
					pecho, cardiopatía, diabetes, enfermedad crónica, desmayos o
					cualquier otra circunstancia que afecte su salud y forma física.
					Al aceptar estos términos, el usuario declara estar en buenas
					condiciones para realizar ejercicio físico.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					El socio y/o los tutores legales (en el caso de menores de edad)
					asumen plenamente la responsabilidad y declaran tener la
					capacidad física o la de su representado para la práctica del
					deporte en las instalaciones de los establecimientos registrados
					en Kaady Sport, asumiendo bajo su responsabilidad cualquier
					eventualidad, contratiempo, dificultad, problema, lesión o
					accidente que pueda surgir debido a tal circunstancia, exonerando
					plena y expresamente a Kaady Sport de cualquier responsabilidad
					por estos conceptos.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					Todo entrenamiento en línea, al no existir contacto directo, hace
					que Kaady Sport no pueda ser responsable de la salud del cliente.
					Por tanto, el cliente exime expresamente a Kaady Sport de
					cualquier responsabilidad por las consecuencias que el ejercicio
					físico pudiera ocasionar en su estado de salud.
				</Typography>
			</Stack>
		),
	},
	{
		title: 'Derecho a cambiar y modificar los términos',
		content: (
			<Stack gap={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					Kaady Sport se reserva el derecho de modificar los presentes
					términos sin plazo establecido, a entera discreción. Por lo
					tanto, se recomienda a los usuarios que revisen periódicamente el
					presente documento con el fin de estar informados sobre posibles
					actualizaciones. En caso de realizar cambios materiales en los
					términos, notificaremos a cada usuario los cambios importantes
					realizados.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					El uso continuado de la página web o nuestro servicio después de
					dichos cambios constituirá la aceptación de los nuevos términos.
					Si algún usuario no acepta alguno de estos términos o cualquier
					versión futura de los mismos, se exhorta a abstenerse de utilizar
					o acceder (o continuar accediendo) a la página web o al servicio.
				</Typography>
			</Stack>
		),
	},
	{
		title: 'Emails de promociones y contenido',
		content: (
			<Typography variant="body1" color={'text.secondary'}>
				El usuario acepta recibir esporádicamente mensajes y materiales de
				promoción por parte de Kaady Sport, los cuales podrán ser enviados
				por correo postal, correo electrónico o cualquier otro medio de
				contacto que el usuario proporcione. Sin embargo, respetamos la
				privacidad y preferencias de mismo, por lo que, si en algún momento
				no se desea recibir dichos materiales o avisos de promociones, se
				pide hacerlo saber en atención al cliente en horarios laborales
				establecidos, para ser atendidos de acuerdo con disponibilidad de los
				operadores de Kaady Sport. Revise la forma de contacto descrita en
				estos términos y condiciones.
			</Typography>
		),
	},
	{
		title: 'Preferencia de ley y resolución de disputas',
		content: (
			<Typography variant="body1" color={'text.secondary'}>
				Los presentes términos, derechos y recursos estipulados en el
				presente documento, así como los reclamos y disputas relacionados con
				este u otros servicios ofrecidos por Kaady Sport, se regirán,
				interpretarán y aplicarán de conformidad con la legislación vigente
				aplicable dentro de los Estados Unidos Mexicanos y, según
				corresponda, bajo la jurisdicción de la ciudad en la que estos
				apliquen. Con la aceptación de estos términos y condiciones, el
				usuario acepta que cualquier reclamo o disputa será decidido
				exclusivamente por el tribunal competente de dicha jurisdicción.
			</Typography>
		),
	},
	{
		title: 'Atención al cliente e información de contacto',
		content: (
			<Stack gap={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					Publicación de números de atención en espera. Consulta nuestras
					redes sociales para contactarnos, o consulta la sección{' '}
					<strong>Contacto</strong> en nuestro sitio web. También puede
					contactarnos directamente en el correo{' '}
					<strong>contacto@kaadysport.com</strong>.
				</Typography>
				<Typography variant="body1" color={'text.secondary'}>
					Kaady Sport no se hace responsable por objetos perdidos o
					extraviados en instalaciones que formen parte de nuestra
					comunidad de establecimiento afiliados y dados de alta en nuestra
					aplicación que prestan servicio a nuestros clientes.
				</Typography>
			</Stack>
		),
	},
	{
		title: 'Vigencia de términos y condiciones',
		content: (
			<Typography variant="body1" color={'text.secondary'}>
				Los presentes términos, condiciones y políticas de Kaady Sport tienen
				vigencia a partir del 14 de agosto de 2023 hasta la publicación
				futura de cambios importantes. Podrá consultar actualizaciones en
				esta página.
			</Typography>
		),
	},
];

export default function Policies() {
	const isLarge = useLargeScreen();
	const { POLICIES } = useDictionary();
	const handleCopy = async () => {
		const url = 'https://www.kaadysport.com/policies';
		await navigator.clipboard.writeText(url);
	};

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>{POLICIES.title}</title>
				</Helmet>
			</HelmetProvider>
			<Navbar isFixed={false} />
			<Content component="section">
				<Stack direction={'column'} gap={'8px'}>
					<Typography variant="h3" component={'h1'}>
						{POLICIES.header}
					</Typography>
					<Typography variant="h6" color={'text.secondary'}>
						{POLICIES.published}
					</Typography>
					<Typography variant="h6" color={'text.secondary'}>
						{POLICIES.language}
					</Typography>
				</Stack>
				<Stack direction={'row'} gap={'24px'} flexWrap={'wrap'}>
					<Stack alignItems={'center'}>
						<IconButton
							href="/documents/Kaady%20Sport%20Policies.pdf"
							download={'KAADY SPORT POLICIES.pdf'}>
							<DownloadIcon />
						</IconButton>
						<Typography variant="caption" color={'text.secondary'}>
							{POLICIES.download}
						</Typography>
					</Stack>
					<Stack alignItems={'center'}>
						<IconButton
							href="/documents/Kaady%20Sport%20Policies.pdf"
							target="_blank">
							<PrintIcon />
						</IconButton>
						<Typography variant="caption" color={'text.secondary'}>
							{POLICIES.print}
						</Typography>
					</Stack>
					<Stack alignItems={'center'}>
						<IconButton onClick={handleCopy}>
							<CopyIcon />
						</IconButton>
						<Typography variant="caption" color={'text.secondary'}>
							{POLICIES.copy}
						</Typography>
					</Stack>
				</Stack>
			</Content>
			<Stack gap={'48px'} paddingBottom={'70px'}>
				{SECTIONS_ES.map((item, index) => (
					<ItemRoot
						key={index}
						padding={isLarge ? '0px 56px' : '0px 16px'}>
						<Stack
							padding={'8px 0px'}
							position={'sticky'}
							top={0}
							sx={(t) => ({
								backgroundColor: t.palette.background.default,
								borderBottom: `1px solid ${t.palette.divider}`,
							})}>
							<Typography variant="h4" component={'h1'}>
								{index + 1}. {item.title}
							</Typography>
						</Stack>
						<Stack>{item.content}</Stack>
					</ItemRoot>
				))}
			</Stack>
			<Footer small />
		</>
	);
}
