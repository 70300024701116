/**
 * Errors for inputs
 */
export const INPUT_ERRORS = {
	EMPTY: 'Rellena este campo',
	EMAIL: 'Ingresa un correo válido',
	PASS: 'Ingresa al menos 8 caracteres',
	DATE: 'Ingresa una fecha válida',
	MATCHPASS: 'Las contraseñas no coinciden',
};

/**
 * Errors for responses
 */
export const RESPONSE_ERRORS = {
	FIELD: {
		title: 'Faltan datos',
		message:
			'Por favor revise los campos de registro, alguno falta o está vacío',
	},
	EMAIL: {
		title: 'Correo inválido',
		message: 'Por favor revise el correo electrónico, tiene un formato inválido',
	},
	PASS: {
		title: 'Revisa la contraseña',
		message:
			'La contraseña debe tener al menos 8 carácteres, por favor revísala.',
	},
	AGREE: {
		title: 'Revisa nuestras políticas',
		message:
			'Para crear una cuenta en Kaady Sport, debes leer y estar de acuerdo con nuestras Políticas.',
	},
	EXIST: {
		title: 'La cuenta ya existe',
		message:
			'Tu correo electrónico ya fue registrado con una cuenta, ingresa a la sección de iniciar sesión.',
	},
	UNEXP: {
		title: 'Error inesperado',
		message:
			'Ha ocurrido un error, por favor intenta más tarde o reinicia la aplicación.',
	},
};
