import { DataImageType } from '@values/Types';

export class DataImage implements DataImageType {
	url: string;
	size?: number;
	width?: number;
	height?: number;
	file?: Blob;

	constructor(data: any) {
		this.url = data?.url || '';
		this.size = data?.size;
		this.width = data?.width;
		this.height = data?.height;
	}
}
