import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
import { isValidDate, isValidEmail } from '@utils/Validations';
import { INPUT_ERRORS } from '@values/Errors';
import { useEffect, useState } from 'react';

// Range for birthdays
const [minDate, maxDate] = ['1950-01-01', '2020-12-31'];

/**
 *
 * @param {{
 * enabled: boolean,
 * onChange: (value: string) => void
 * }}
 */
export function InputEmail({ enabled, onChange }) {
	const [error, setError] = useState('');
	const [value, setValue] = useState('');
	/**
	 * Check the changes
	 * @param {import('react').ChangeEvent<HTMLInputElement>} e
	 */
	const handleChange = (e) => {
		onChange(e.target.value);
		setValue(e.target.value);
	};
	/**
	 * Validate on blur
	 */
	const handleBlur = () => {
		if (value.trim() === '') {
			setError(INPUT_ERRORS.EMPTY);
			return;
		} else setError('');
		if (!isValidEmail(value)) setError(INPUT_ERRORS.EMAIL);
		else setError('');
	};

	return (
		<TextField
			autoFocus
			fullWidth
			name="email"
			label="Correo electrónico"
			variant="filled"
			autoComplete="email"
			type="email"
			disabled={!enabled}
			error={Boolean(error)}
			helperText={error}
			value={value}
			onBlur={handleBlur}
			onChange={handleChange}
		/>
	);
}

/**
 *
 * @param {{
 * enabled: boolean,
 * label: string,
 * name: string,
 * matchError: boolean,
 * onChange: (value: string) => void
 * }}
 */
export function InputPassword({
	enabled = true,
	onChange = () => {},
	label = 'Password',
	name = '',
	matchError = false,
}) {
	const [error, setError] = useState('');
	const [value, setValue] = useState('');
	const [visible, setVisible] = useState(false);
	useEffect(() => {
		if (matchError && value.trim() !== '' && value.length >= 8)
			setError(INPUT_ERRORS.MATCHPASS);
		if (!matchError) setError('');
	}, [matchError, value]);
	/**
	 * Handle show / hide password
	 */
	const handleShowHide = () => setVisible(!visible);

	/**
	 * @param {import('react').MouseEvent<HTMLButtonElement, MouseEvent>} e
	 */
	const handleMouseDown = (e) => e.preventDefault();

	/**
	 * Check the changes
	 * @param {import('react').ChangeEvent<HTMLInputElement>} e
	 */
	const handleChange = (e) => {
		onChange(e.target.value);
		setValue(e.target.value);
	};

	/**
	 * Validate on blur
	 */
	const handleBlur = () => {
		if (value.trim() === '') {
			setError(INPUT_ERRORS.EMPTY);
			return;
		} else setError('');
		if (value.length < 8) {
			setError(INPUT_ERRORS.PASS);
			return;
		} else setError('');
		if (matchError) setError(INPUT_ERRORS.MATCHPASS);
		else setError('');
	};

	return (
		<TextField
			fullWidth
			name={name}
			label={label}
			variant="filled"
			autoComplete="new-password"
			type={visible ? 'text' : 'password'}
			disabled={!enabled}
			error={Boolean(error)}
			helperText={error}
			value={value}
			onBlur={handleBlur}
			onChange={handleChange}
			sx={{
				'& input::-ms-reveal': {
					display: 'none',
				},
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							disabled={!enabled}
							onClick={handleShowHide}
							onMouseDown={handleMouseDown}>
							{visible ? (
								<VisibilityOffOutlined />
							) : (
								<VisibilityOutlined />
							)}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
}

/**
 *
 * @param {{
 * enabled: boolean,
 * onChange: (value: string) => void
 * }}
 */
export function InputName({ enabled, onChange }) {
	const [error, setError] = useState('');
	const [value, setValue] = useState('');
	/**
	 * Check the changes
	 * @param {import('react').ChangeEvent<HTMLInputElement>} e
	 */
	const handleChange = (e) => {
		onChange(e.target.value);
		setValue(e.target.value);
	};
	/**
	 * Validate on blur
	 */
	const handleBlur = () => {
		if (value.trim() === '') setError(INPUT_ERRORS.EMPTY);
		else setError('');
	};

	return (
		<TextField
			autoFocus
			fullWidth
			name="name"
			label="Nombre"
			variant="filled"
			autoComplete="given-name"
			disabled={!enabled}
			error={Boolean(error)}
			helperText={error}
			value={value}
			onBlur={handleBlur}
			onChange={handleChange}
		/>
	);
}

/**
 *
 * @param {{
 * enabled: boolean,
 * onChange: (value: string) => void
 * }}
 */
export function InputLastName({ enabled, onChange }) {
	const [error, setError] = useState('');
	const [value, setValue] = useState('');
	/**
	 * Check the changes
	 * @param {import('react').ChangeEvent<HTMLInputElement>} e
	 */
	const handleChange = (e) => {
		onChange(e.target.value);
		setValue(e.target.value);
	};
	/**
	 * Validate on blur
	 */
	const handleBlur = () => {
		if (value.trim() === '') setError(INPUT_ERRORS.EMPTY);
		else setError('');
	};

	return (
		<TextField
			fullWidth
			name="lastname"
			label="Apellido"
			variant="filled"
			autoComplete="family-name"
			disabled={!enabled}
			error={Boolean(error)}
			helperText={error}
			value={value}
			onBlur={handleBlur}
			onChange={handleChange}
		/>
	);
}

/**
 *
 * @param {{
 * enabled: boolean,
 * onChange: (value: string) => void
 * }}
 */
export function InputBirthday({ enabled, onChange }) {
	const [error, setError] = useState('');
	const [value, setValue] = useState('');
	/**
	 * Check the changes
	 * @param {import('react').ChangeEvent<HTMLInputElement>} e
	 */
	const handleChange = (e) => {
		onChange(e.target.value);
		setValue(e.target.value);
	};
	/**
	 * Validate on blur
	 */
	const handleBlur = () => {
		if (value.trim() === '') {
			setError(INPUT_ERRORS.EMPTY);
			return;
		} else setError('');
		if (!isValidDate(value, minDate, maxDate)) setError(INPUT_ERRORS.DATE);
		else setError('');
	};

	return (
		<TextField
			fullWidth
			name="birthday"
			label="Fecha de nacimiento"
			variant="filled"
			type="date"
			disabled={!enabled}
			error={Boolean(error)}
			helperText={error}
			value={value}
			onBlur={handleBlur}
			onChange={handleChange}
			inputProps={{
				min: minDate,
				max: maxDate,
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);
}

/**
 *
 * @param {{
 * enabled: boolean,
 * onChange: (value: number) => void
 * }}
 * @returns
 */
export function InputGender({ enabled, onChange }) {
	const label = 'Selecciona tu sexo';
	/**
	 * Check changes
	 * @param {import('react').ChangeEvent<HTMLInputElement>} e
	 */
	const handleChange = (e) => {
		onChange(e.target.value);
	};

	return (
		<FormControl
			disabled={!enabled}
			sx={{
				marginTop: '16px',
			}}>
			<FormLabel>{label}</FormLabel>
			<RadioGroup
				name="gender"
				aria-label={label}
				defaultValue={0}
				onChange={handleChange}>
				<FormControlLabel value={0} control={<Radio />} label="Femenino" />
				<FormControlLabel value={1} control={<Radio />} label="Masculino" />
				<FormControlLabel value={2} control={<Radio />} label="Otro" />
			</RadioGroup>
		</FormControl>
	);
}
