import axios from 'axios';
import { Center } from '@models/Center.ts';
import { authConfig } from './Auth';

export async function requestAllCenters() {
	try {
		const res = await axios.get('/api/centers/all');
		return Array.isArray(res.data)
			? res.data.map((item) => new Center(item))
			: [];
	} catch (error) {
		console.error(error.message);
	}
}

/**
 * Request the centers by pages. Check result object.
 *
 * @param {string} page_url Request data by page
 * @returns {Promise<{
 * current_page: number,
 * last_page: number,
 * prev_url: string,
 * next_url: string,
 * total: number,
 * centers: import('@values/Types').CenterType[],
 * }>}
 */
export async function requestCenters(page_url = null) {
	try {
		const res = await axios.get(page_url || '/api/centers', authConfig());
		return {
			current_page: res.data.current_page,
			last_page: res.data.last_page,
			prev_url: res.data.prev_page_url,
			next_url: res.data.next_page_url,
			total: res.data.total,
			centers: Array.isArray(res.data.data)
				? res.data.data.map((item) => new Center(item))
				: [],
		};
	} catch (error) {
		console.error('Centers error:', error.message);
	}
}

export async function updateOrCreateCenter(data) {
	try {
		let res;
		if (data.id) {
			res = await axios.put('/api/centers', data, authConfig());
		} else {
			res = await axios.post('/api/centers', data, authConfig());
		}
		return res;
	} catch (error) {
		console.error('Centers error:', error.message);
		return error.response;
	}
}

export async function updateCenterLocation(data) {
	try {
		await axios.put('/api/centers/location', data, authConfig());
	} catch (error) {
		console.error('Centers error:', error.message);
	}
}

export async function updateCenterImages(data) {
	try {
		const res = await axios.post('/api/centers/images', data, {
			headers: {
				...authConfig().headers,
				'Content-Type': 'multipart/form-data',
			},
		});
		return res;
	} catch (error) {
		console.error('Centers error:', error.message);
		return error.response;
	}
}

export async function deleteOneImage(data) {
	try {
		await axios.delete('/api/centers/images', {
			...authConfig(),
			data: data,
		});
	} catch (error) {
		console.error('Centers error:', error.message);
	}
}

export async function updateCenterSocial(data) {
	try {
		await axios.put('/api/centers/social', data, authConfig());
	} catch (error) {
		console.error('Centers error:', error.message);
	}
}
