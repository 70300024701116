import axios from 'axios';
const { authConfig } = require('./Auth');

export async function getUserRole() {
	try {
		const res = await axios.get('/api/roles/get', authConfig());
		if (res.data.roles) {
			return res.data.roles;
		} else {
			return [];
		}
	} catch (error) {
		console.error('Role error: ', error.message);
		return [];
	}
}
