import { PanelCard, PanelContent, PanelHeader } from '@components/Panel';
import { Center } from '@models/Center.ts';
import {
	AddOutlined,
	EditOutlined,
	MoreVertOutlined,
	PhotoOutlined,
	PlaceOutlined,
	PublicOutlined,
} from '@mui/icons-material';
import {
	Avatar,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
	alpha,
} from '@mui/material';

import { requestCenters } from '@utils/Centers';
import { capitalizeFirstLetter } from '@utils/Format';

import { useEffect, useState } from 'react';

/**
 *
 * @param {Object} param0
 * @param {import('@values/Types').CenterType} [param0.item]
 * @param {() => void} [param0.onOpen]
 */
function CenterCard({ item = {}, onOpen = () => {} }) {
	const [anchor, setAnchor] = useState(null);
	const handleAnchor = (e) => setAnchor(e.currentTarget);
	const handleClose = () => setAnchor(null);

	return (
		<Stack
			direction={'row'}
			alignItems={'center'}
			gap={'16px'}
			overflow={'hidden'}>
			<Avatar
				alt={item.name}
				src={item.picture.url}
				sx={(t) => ({
					bgcolor: alpha(t.palette.primary.main, 0.72),
				})}>
				{item.name.charAt(0)}
			</Avatar>

			<Stack
				sx={{
					flexWrap: 'wrap',
					whiteSpace: 'break-spaces',
					overflowWrap: 'anywhere',
				}}
				alignItems={'center'}
				direction={'row'}
				flexGrow={1}
				columnGap={'8px'}>
				<Typography variant="subtitle1">{item.name}</Typography>
				<Typography variant="body2" color={'text.secondary'}>
					{item.locations.length > 0
						? capitalizeFirstLetter(item.locations[0].zone.name) +
						  ', ' +
						  item.locations[0].zone.region
						: null}
				</Typography>
			</Stack>
			{item.status?.code !== -1 && (
				<Stack
					direction={'row'}
					padding={'4px 8px'}
					sx={(t) => ({
						backgroundColor: t.palette.secondary.main,
						color: t.palette.secondary.contrastText,
						borderRadius: '6px',
					})}>
					<Typography noWrap variant="caption">
						{item.status?.name}
					</Typography>
				</Stack>
			)}
			<IconButton
				title="Ver opciones"
				onClick={handleAnchor}
				aria-haspopup="true"
				aria-label="Ver opciones">
				<MoreVertOutlined />
			</IconButton>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}>
				<MenuItem
					onClick={() => {
						onOpen(0, item);
						handleClose();
					}}>
					<ListItemIcon>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText>Editar perfil</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={() => {
						onOpen(1, item);
						handleClose();
					}}>
					<ListItemIcon>
						<PlaceOutlined />
					</ListItemIcon>
					<ListItemText>Ubicación</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={() => {
						onOpen(2, item);
						handleClose();
					}}>
					<ListItemIcon>
						<PhotoOutlined />
					</ListItemIcon>
					<ListItemText>Imágenes</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={() => {
						onOpen(3, item);
						handleClose();
					}}>
					<ListItemIcon>
						<PublicOutlined />
					</ListItemIcon>
					<ListItemText>Redes sociales</ListItemText>
				</MenuItem>
			</Menu>
		</Stack>
	);
}

export default function CentersMain({
	load = false,
	visible = true,
	onOpen = () => {},
	onLoaded = () => {},
}) {
	const [centers, setCenters] = useState([]);
	const [total, setTotal] = useState(0);
	useEffect(() => {
		const loadCenters = async () => {
			if (load) {
				const res = await requestCenters();
				setCenters(res?.centers || []);
				setTotal(res?.total || 0);
				onLoaded();
			}
		};
		loadCenters();
	}, [load, onLoaded]);

	const list = centers.map((item, index) => (
		<CenterCard item={item} key={index} onOpen={onOpen} />
	));

	return (
		<PanelCard
			sx={{
				display: !visible ? 'none' : '',
			}}>
			<PanelContent>
				<PanelHeader component="header">
					<Typography
						variant="h6"
						component={'h1'}
						flexGrow={1}
						sx={{
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}>
						Centros deportivos ({total})
					</Typography>
					<IconButton
						title="Crear nuevo"
						onClick={() => {
							onOpen(0, new Center());
						}}>
						<AddOutlined />
					</IconButton>
				</PanelHeader>
				<Stack padding={'16px'} gap={'16px'}>
					{list}
				</Stack>
			</PanelContent>
		</PanelCard>
	);
}
