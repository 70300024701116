export const months = {
	es: [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	],
};

/**
 * Get the label from number month
 * @param {number} month
 * @param {import("./Dictionary").Lang} lang
 * @param {number} length
 * @returns {string}
 */
export function getLabelMonth(month, lang = 'es', length) {
	if (month < 0 || month >= 12) return '';
	if (length <= 0) return months[lang][month];
	return months[lang][month].slice(0, length);
}
