// Definiciones
export type NotFoundRes = {
	hero_header: string;
	hero_text: string;
	hero_go_start: string;
};

export type NotFoundLang = {
	es?: NotFoundRes;
	en?: NotFoundRes;
};

// Traducciones
export const NotFoundDictionary: NotFoundLang = {
	es: {
		hero_header: 'Ruta no encontrada',
		hero_text:
			'La ruta de la página no existe o no se encuentra disponible, por favor revisa la ruta e intenta de nuevo.',
		hero_go_start: 'Ir a inicio',
	},
};
