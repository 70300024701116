import { styled, Stack } from '@mui/material';
import { useLargeScreen } from '@utils/Media';

const Content = styled(Stack)(() => {
	const isLarge = useLargeScreen();
	return {
		boxSizing: 'border-box',
		flexDirection: 'column',
		maxWidth: '880px',
		gap: '36px',
		padding: isLarge ? '90px 56px' : '56px 16px',
		margin: '0 auto',
	};
});

export default Content;
