import { ContactType, DataImageType, ManagerType, StatusType } from '@values/Types';
import { DataImage } from './DataImage.ts';
import { Contact } from './Contacts.ts';
import { Status } from './Status.ts';
import { capitalizeFirstLetter } from '@utils/Format.js';

export class Manager implements ManagerType {
	id?: string;
	user: string;
	name: string;
	last_name: string;
	contacts: ContactType[];
	picture: DataImageType;
	verified?: boolean;
	status_code?: number;
	status: StatusType;

	constructor(data: any) {
		this.id = data?.id;
		this.user = data?.user || '';
		this.name = capitalizeFirstLetter(data?.name) || '';
		this.last_name = capitalizeFirstLetter(data?.last_name) || '';
		this.contacts = Array.isArray(data?.contacts)
			? data?.contacts.map((item: any) => new Contact(item))
			: [];
		this.picture = new DataImage(data?.picture);
		this.verified = data?.verified;
		this.status_code = data?.status_code;
		this.status = new Status(data?.status);
	}
}
