import Footer from '@components/Footer';
import Navbar from '@components/Navbar';
import FormRoot from '@components/FormRoot';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

export default function Recover() {
	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>Recuperación de cuentas Kaady Sport</title>
				</Helmet>
				<Navbar showOptions={false} isFixed={false} />
				<FormRoot>
					<Outlet />
				</FormRoot>
				<Footer small />
			</HelmetProvider>
		</>
	);
}
