import { SvgIcon } from '@mui/material';

/**
 *
 * @param {import('@mui/material').SvgIconProps} props
 * @returns {SvgIcon}
 */
export function InstagramIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
				<path d="M24,17.25A6.75,6.75,0,1,0,30.75,24,6.72,6.72,0,0,0,24,17.25Zm0,0A6.75,6.75,0,1,0,30.75,24,6.72,6.72,0,0,0,24,17.25ZM32.88,4H15.12A11.15,11.15,0,0,0,4,15.12V32.87A11.15,11.15,0,0,0,15.12,44H32.87A11.15,11.15,0,0,0,44,32.88V15.12A11.15,11.15,0,0,0,32.88,4ZM24,35.13A11.13,11.13,0,1,1,35.13,24,11.15,11.15,0,0,1,24,35.13Zm11.13-20a2.25,2.25,0,1,1,2.25-2.25A2.42,2.42,0,0,1,35.13,15.12ZM24,17.25A6.75,6.75,0,1,0,30.75,24,6.72,6.72,0,0,0,24,17.25Zm0,0A6.75,6.75,0,1,0,30.75,24,6.72,6.72,0,0,0,24,17.25Zm0,0A6.75,6.75,0,1,0,30.75,24,6.72,6.72,0,0,0,24,17.25Z" />
			</svg>
		</SvgIcon>
	);
}

/**
 *
 * @param {import('@mui/material').SvgIconProps} props
 * @returns {SvgIcon}
 */
export function YoutubeIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
				<path d="M43,14.25a5.57,5.57,0,0,0-4.25-4C35.62,9.5,30,9.13,23.87,9.13A86.11,86.11,0,0,0,9,10.25a5.56,5.56,0,0,0-4.25,4A61.08,61.08,0,0,0,4,24a47.16,47.16,0,0,0,.88,9.75,5.55,5.55,0,0,0,4.25,4A86.11,86.11,0,0,0,24,38.87a88.38,88.38,0,0,0,14.87-1.12,5.4,5.4,0,0,0,4.26-4A82.22,82.22,0,0,0,44,24,70.77,70.77,0,0,0,43,14.25ZM18.87,30.5v-13L30.25,24Z" />
			</svg>
		</SvgIcon>
	);
}

/**
 *
 * @param {import('@mui/material').SvgIconProps} props
 * @returns {SvgIcon}
 */
export function TiktokIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
				<path d="M40,20c-3,0-5.5-1.25-8-3.13V32A12,12,0,1,1,20,20v8a4,4,0,1,0,4,4V4h8c0,1.75,1.12,3.75,3.13,5.38A17.09,17.09,0,0,0,40,12Z" />
			</svg>
		</SvgIcon>
	);
}

/**
 *
 * @param {import('@mui/material').SvgIconProps} props
 * @returns {SvgIcon}
 */
export function TwitterIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
				<path d="M27.72,20.94,42.29,4H38.84L26.19,18.71,16.08,4H4.43L19.71,26.24,4.43,44H7.88L21.24,28.47,31.92,44H43.57L27.72,20.94ZM23,26.43l-1.54-2.21L9.13,6.6h5.3l9.94,14.22L25.92,23,38.84,41.52h-5.3L23,26.44Z" />
			</svg>
		</SvgIcon>
	);
}

/**
 *
 * @returns {SvgIcon}
 */
export function GoogleIcon() {
	return (
		<SvgIcon>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 48 48"
				width="24px"
				height="24px">
				<path
					fill="#FFC107"
					d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
				/>
				<path
					fill="#FF3D00"
					d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
				/>
				<path
					fill="#4CAF50"
					d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
				/>
				<path
					fill="#1976D2"
					d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
				/>
			</svg>
		</SvgIcon>
	);
}
