import Navbar from '@components/Navbar';
import { Panel } from '@components/Panel';
import { Sidebar } from '@components/Sidebar';
import { FitnessCenterOutlined } from '@mui/icons-material';
import { Drawer, Stack, alpha } from '@mui/material';
import { useLargeScreen } from '@utils/Media';
import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function BaseHome() {
	const base = '/base-centers';
	const options = [
		{
			label: 'Centros',
			to: `${base}/centers`,
			icon: <FitnessCenterOutlined />,
		},
	];

	const location = useLocation();
	const startIndex = options.findIndex((value) => value.to === location.pathname);
	const [index, setIndex] = useState(startIndex === -1 ? 0 : startIndex);
	const [open, setOpen] = useState(false);
	const isLarge = useLargeScreen();
	const navigate = useNavigate();
	const onChange = (i) => {
		setIndex(i);
		setOpen(false);
		navigate(options[i].to);
	};

	return (
		<Stack
			component={'main'}
			height={'100vh'}
			sx={(t) => ({
				overflow: 'hidden',
				backgroundColor: alpha(t.palette.primary.main, 0.05),
			})}>
			<>
				<HelmetProvider>
					<Helmet>
						<title>Panel de administración</title>
					</Helmet>
				</HelmetProvider>
				<Navbar base={base} isAuth onDrawer={() => setOpen(true)} />
				<Panel component={'section'}>
					{isLarge && (
						<Sidebar
							index={index}
							onChange={onChange}
							header="Base de centros"
							options={options}
						/>
					)}
					<Outlet />
				</Panel>
				<Drawer open={open} onClose={() => setOpen(false)}>
					<Sidebar
						index={index}
						onChange={onChange}
						header="Base de centros"
						options={options}
					/>
				</Drawer>
			</>
		</Stack>
	);
}
