// Definiciones
export type HomeRes = {
	title: string;
	hero_header: string;
	hero_text: string;
	hero_start: string;
	hero_create: string;
	users_header_1: string;
	users_header_2: string;
	users_text: string;
	users_action: string;
	centers_header: string;
	centers_text: string;
	centers_action: string;
};

export type HomeLang = {
	es?: HomeRes;
	en?: HomeRes;
};

// Traducciones
export const HomeDictionary: HomeLang = {
	es: {
		title: 'Kaady Sport: Una membresía deportiva a tu medida',
		hero_header: 'Haz ejercicio en cualquier lugar, a cualquier hora',
		hero_text: 'Entrena tus actividades favoritas en los centros afiliados',
		hero_start: 'Empezar ahora',
		hero_create: 'Crear cuenta',
		users_header_1: '¿Piensas mejorar tu condición física?',
		users_header_2: '¿Estás de viaje y quieres entrenar?',
		users_text:
			'Busca ahora los diferentes centros deportivos asociados y entrena una de las actividades disponibles con una de nuestras membresías',
		users_action: 'Ver membresías',
		centers_header: 'Puedes unirte a los socios de Kaady Sport',
		centers_text:
			'Revisa los diferentes beneficios que ofrecemos para los centros deportivos. Con nuestra aplicación puedes manejar tu centro de forma digital',
		centers_action: 'Más información',
	},
};
