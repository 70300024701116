import { GOOGLE_MAP_ID, MAP_PROPS } from '@values/Default';
import { AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';

export default function MarkerMap({
	disabled = false,
	reload = false,
	center = MAP_PROPS.center,
	onChange = () => {},
}) {
	const [marker, setMarker] = useState(center);
	const map = useMap('location-map');

	useEffect(() => {
		if (!map || !reload || disabled) return;
		if (typeof center.lat === 'number' && typeof center.lng === 'number') {
			map.panTo(center);
			map.setZoom(15);
		}
	}, [map, reload, center, disabled]);

	return (
		<Map
			id="location-map"
			reuseMaps={true}
			style={{ width: '100%', height: '100%' }}
			mapId={GOOGLE_MAP_ID}
			defaultCenter={center}
			defaultZoom={15}
			disableDefaultUI={true}
			zoomControl={true}
			clickableIcons={false}
			onCameraChanged={(e) => {
				if (!disabled) {
					setMarker(e.detail.center);
					onChange(e.detail.center);
				}
			}}>
			<AdvancedMarker position={marker} />
		</Map>
	);
}
