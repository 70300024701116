import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { useEffect } from 'react';
import { requestLogout } from '@utils/Auth';

export default function Logout() {
	const navigate = useNavigate();
	useEffect(() => {
		const logout = async () => {
			await requestLogout();
			navigate('/login');
		};
		logout();
	}, [navigate]);
	return <Loading open message="Cerrando sesión..." />;
}
