export const MAP_PROPS = {
	center: {
		lat: 19.395858,
		lng: -99.020027,
	},
	zoom: 12,
};

export const GOOGLE_API_KEY = 'AIzaSyAGjYZCAlud4xRcyfgBS5Mz5Ie3rxkvmfM';
export const GOOGLE_CLIENT_ID =
	'477524391114-ir6qp8i75kim6g6vanh0484o9bejj54u.apps.googleusercontent.com';
export const GOOGLE_MAP_ID = '413f9ec4fea25f17';
export const FACEBOOK_APP_ID = '791748218160630';
