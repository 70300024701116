// Material design
import {
	Button,
	Card,
	Divider,
	IconButton,
	InputAdornment,
	Link,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

// Icons
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';

// From react
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// External btns
import FBLogin from '@components/FBLogin';
import GLogin from '@components/GLogin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useLargeScreen } from '@utils/Media';
import { EmailOutlined } from '@mui/icons-material';
import BasicModal from '@components/BasicModal';
import {
	isAuthenticated,
	requestBasicInfo,
	requestLogin,
	requestLoginExternal,
} from '@utils/Auth';
import Loading from 'pages/Loading';
import { GOOGLE_CLIENT_ID } from '@values/Default';
import { VerifyDialog } from './UserDialogs';

/**
 *
 * @param {{
 * name: string,
 * value: string,
 * enabled: boolean,
 * onChange: (value: string) => void
 * }}
 */
function InputUser({ enabled = true, value = '', onChange = () => {}, name = '' }) {
	/**
	 * Check the changes
	 * @param {import('react').ChangeEvent<HTMLInputElement>} e
	 */
	const handleChange = (e) => {
		onChange(e);
	};

	return (
		<TextField
			autoFocus
			fullWidth
			name={name}
			value={value}
			label="Correo o usuario"
			variant="filled"
			autoComplete="username"
			type="text"
			disabled={!enabled}
			onChange={handleChange}
		/>
	);
}

/**
 *
 * @param {{
 * enabled: boolean,
 * name: string,
 * value: string,
 * onChange: (value: string) => void
 * }}
 */
function InputPassword({
	enabled = true,
	value = '',
	name = '',
	onChange = () => {},
}) {
	const [visible, setVisible] = useState(false);
	/**
	 * Handle show / hide password
	 */
	const handleShowHide = () => setVisible(!visible);

	/**
	 * @param {import('react').MouseEvent<HTMLButtonElement, MouseEvent>} e
	 */
	const handleMouseDown = (e) => e.preventDefault();

	/**
	 * Check the changes
	 * @param {import('react').ChangeEvent<HTMLInputElement>} e
	 */
	const handleChange = (e) => {
		onChange(e);
	};

	return (
		<TextField
			fullWidth
			name={name}
			value={value}
			label="Contraseña o código"
			variant="filled"
			autoComplete="current-password"
			type={visible ? 'text' : 'password'}
			disabled={!enabled}
			onChange={handleChange}
			sx={{
				'& input::-ms-reveal': {
					display: 'none',
				},
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							disabled={!enabled}
							onClick={handleShowHide}
							onMouseDown={handleMouseDown}>
							{visible ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
}

/**
 * Creates the module for login users : Module 1
 *
 * By Frankil A.
 * @returns The JSX element for login users
 */
export default function UserLogin() {
	const navigate = useNavigate();
	const isLarge = useLargeScreen();

	// Data states
	const [enabled, setEnabled] = useState(true);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		email: '',
		password: '',
	});
	const [dialog, setDialog] = useState({
		title: '',
		message: '',
	});
	const [verify, setVerify] = useState({
		open: false,
		name: '',
		email: '',
		onClose: (res) => {},
	});

	// Handlers
	const handleChange = (e) => {
		const { name, value } = e.target;
		const data = { ...form };
		data[name] = value;
		setForm(data);
	};

	// Disabled form
	const handleLogin = async () => {
		setEnabled(false);
		const success = await requestLogin(form);
		if (success) {
			navigate('/account-options');
		} else {
			setDialog({
				title: 'Ha ocurrido un error',
				message:
					'Su usuario y/o contraseña son incorrectos. Revise e intente de nuevo.',
			});
			setOpen(true);
		}
		setEnabled(true);
	};

	// Login external
	const handleSuccessExternal = async (type, token) => {
		setLoading(true);

		const success = await requestLoginExternal({
			type: type,
			token: token,
		});

		setLoading(false);

		if (success) {
			navigate('/account-options');
		} else {
			setDialog({
				title: 'Ha ocurrido un error',
				message:
					'No fue posible conectar con su cuenta, intente de nuevo o use otra opción.',
			});
			setOpen(true);
		}
	};

	// Validates the form
	const dataIsValid = () => {
		return (
			form.email.trim() !== '' &&
			form.password.trim() !== '' &&
			form.password.length >= 8
		);
	};

	// Ask verifications
	const showVerifyDialog = useCallback((name, email) => {
		return new Promise((resolve) => {
			setVerify({
				open: true,
				name: name,
				email: email,
				onClose: (res) => {
					setVerify((v) => ({ ...v, open: false }));
					resolve(res);
				},
			});
		});
	}, []);

	useEffect(() => {
		async function checkLogin() {
			const logged = await isAuthenticated();
			if (!logged) {
				return;
			}
			const user = await requestBasicInfo();
			if (user.status_code !== 21) {
				navigate('/account-options');
				return;
			}
			const res = await showVerifyDialog(user.name, user.contacts[0].value);
			if (res) {
				navigate('/recover-account');
				return;
			}

			navigate('/account-options');
			return;
		}
		checkLogin();
	}, [navigate, showVerifyDialog]);

	// Handle close modal
	const closeModal = () => {
		setOpen(false);
	};

	return (
		<Card
			variant="outlined"
			sx={(t) => ({
				width: '100%',
				maxWidth: isLarge ? '380px' : '400px',
				border: isLarge ? '' : 'none',
			})}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				padding={'16px 16px 8px 16px'}>
				<Typography variant="h5" component={'h1'}>
					Continuar con
				</Typography>
			</Stack>
			<Stack padding={'16px'} gap={'12px'} component={'form'}>
				<InputUser
					enabled={enabled}
					name="email"
					value={form.email}
					onChange={handleChange}
				/>
				<InputPassword
					enabled={enabled}
					name="password"
					value={form.password}
					onChange={handleChange}
				/>
			</Stack>
			<Stack padding={'16px'}>
				<Typography variant="body2" color={'text.secondary'}>
					Al continuar, aceptas leer y estar de acuerdo con todos nuestros{' '}
					<Link
						href="/policies"
						target="_blank"
						variant="body2"
						color={'inherit'}>
						Términos y Políticas
					</Link>
					.
				</Typography>
			</Stack>
			<Stack
				direction="row"
				gap={'16px'}
				padding={'8px'}
				justifyContent="center">
				<Button
					variant="text"
					size="large"
					onClick={() => navigate('/recover-account')}>
					Olvidé mi contraseña
				</Button>
				<Button
					variant="contained"
					onClick={handleLogin}
					size="large"
					disabled={!dataIsValid() || !enabled}>
					Iniciar sesión
				</Button>
				<BasicModal
					title={dialog.title}
					message={dialog.message}
					open={open}
					onClose={closeModal}
				/>
				<VerifyDialog {...verify} />
			</Stack>
			<Divider
				sx={{
					padding: '16px',
				}}>
				<Typography variant="caption" color={'text.secondary'}>
					O
				</Typography>
			</Divider>
			<Stack
				direction="column"
				spacing={1}
				padding={'0px 16px 16px 16px'}
				justifyContent="center">
				<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
					<GLogin
						onSuccess={(token) => handleSuccessExternal('GMAIL', token)}
					/>
				</GoogleOAuthProvider>
				<FBLogin
					onSucces={(token) => handleSuccessExternal('FACEBOOK', token)}
				/>
				<Button
					onClick={() => navigate('/signup')}
					disableElevation
					color="tonal"
					sx={{
						lineHeight: 'normal',
						padding: '8px 16px',
					}}
					startIcon={<EmailOutlined />}
					variant="contained">
					Crear una cuenta
				</Button>
			</Stack>
			<Loading open={loading} />
		</Card>
	);
}
