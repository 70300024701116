import { Stack, styled } from '@mui/material';
import { useLargeScreen } from '@utils/Media';

const Section = styled(Stack)(() => {
	const media = useLargeScreen();
	return {
		boxSizing: 'border-box',
		flexDirection: media ? 'row' : 'column-reverse',
		minHeight: media ? 'calc(100vh - 100px)' : 'calc(100vh - 70px)',
		maxWidth: '880px',
		gap: media ? '16px' : '32px',
		alignItems: 'center',
		justifyContent: 'center',
		padding: media ? '24px 56px' : '56px 16px',
		margin: '0 auto',
	};
});

export default Section;
