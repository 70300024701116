import {
	Autocomplete,
	Card,
	CardActionArea,
	Grid,
	Stack,
	Typography,
} from '@mui/material';

import PinIcon from '@mui/icons-material/PushPinOutlined';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import Content from '@components/Content';
import useDictionary from '@values/Dictionary.ts';
import Chip from '@components/Chip';
import StyledTextField from '@components/StyledTextField';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLargeScreen } from '@utils/Media';

export default function ActivitiesSection() {
	const isLarge = useLargeScreen();
	const { ACTIVITIES } = useDictionary();
	const activities = [
		{
			name: 'Boxeo',
			description:
				'Deporte de contacto que involucra golpear al oponente con los puños.',
		},
		{
			name: 'Danza',
			description:
				'Expresión artística que combina movimientos corporales con ritmo y música.',
		},
		{
			name: 'Taekwondo',
			description:
				'Arte marcial que se centra en patadas y técnicas de piernas.',
		},
		{
			name: 'Halterofilia',
			description:
				'Competición de levantamiento de pesas enfocada en levantamientos máximos.',
		},
		{
			name: 'Cardio',
			description:
				'Actividad de acondicionamiento físico para mejorar la salud cardiovascular.',
		},
		{
			name: 'Natación',
			description: 'Deporte acuático que implica nadar en diferentes estilos.',
		},
		{
			name: 'Crossfit',
			description:
				'Programa de entrenamiento variado que combina diferentes disciplinas.',
		},
		{
			name: 'Gimnasia',
			description:
				'Actividad que involucra movimientos y ejercicios para desarrollar fuerza y flexibilidad.',
		},
		{
			name: 'Pesas',
			description:
				'Entrenamiento con pesas para fortalecer y tonificar los músculos.',
		},
		{
			name: 'Spinning',
			description:
				'Clase de ciclismo en grupo realizada en bicicletas estáticas.',
		},
		{
			name: 'Running',
			description:
				'Actividad de correr a diferentes velocidades y distancias.',
		},
	];

	return (
		<Content component={'section'}>
			<HelmetProvider>
				<Helmet>
					<title>{ACTIVITIES.title}</title>
				</Helmet>
			</HelmetProvider>
			<Stack direction={'column'} gap={'16px'}>
				<Typography variant="h3" component={'h1'}>
					{ACTIVITIES.hero_header}
				</Typography>
				<Typography variant="h6" color={'text.secondary'}>
					{ACTIVITIES.hero_text}
				</Typography>
			</Stack>
			<Stack direction={isLarge ? 'row' : 'column-reverse'} gap={'16px'}>
				<Stack
					direction={'row'}
					flexWrap={'nowrap'}
					overflow={'auto'}
					gap={'8px'}
					padding={'6px 0px'}
					width={'100%'}
					flexGrow={1}>
					<Chip
						label={ACTIVITIES.classes}
						onChange={(check) => console.log(check)}
					/>
					<Chip label={ACTIVITIES.routines} />
					<Chip label={ACTIVITIES.in_centers} />
				</Stack>
				<Autocomplete
					freeSolo
					options={activities.map((value) => value.name)}
					renderInput={(params) => (
						<StyledTextField
							{...params}
							placeholder={ACTIVITIES.search_activity}
						/>
					)}
				/>
			</Stack>
			<Grid container spacing={2}>
				{activities.map((item, index) => (
					<Grid key={index} item xs={12} sm={6} md={4}>
						<Card
							variant="outlined"
							sx={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
							}}>
							<Stack
								direction={'row'}
								component={'header'}
								padding={'16px 16px 6px 16px'}>
								<Typography variant="h5">{item.name}</Typography>
							</Stack>
							<Stack
								direction={'row'}
								padding={'12px 16px'}
								flexGrow={1}
								height={'100%'}>
								<Typography variant="body1" color={'text.secondary'}>
									{item.description}
								</Typography>
							</Stack>
							<CardActionArea
								sx={{
									borderRadius: '0px',
								}}>
								<Stack
									direction={'row'}
									alignItems={'center'}
									gap={'8px'}
									borderRadius={0}
									padding={'16px'}>
									<CalendarIcon />
									<Typography variant="subtitle1" flexGrow={1}>
										{ACTIVITIES.classes}
									</Typography>
									<Typography
										variant="body2"
										color={'text.secondary'}>
										{ACTIVITIES.no_available}
									</Typography>
								</Stack>
							</CardActionArea>
							<CardActionArea
								sx={{
									borderRadius: '0px',
								}}>
								<Stack
									direction={'row'}
									alignItems={'center'}
									gap={'8px'}
									padding={'16px'}>
									<PinIcon />
									<Typography variant="subtitle1" flexGrow={1}>
										{ACTIVITIES.routines}
									</Typography>
									<Typography
										variant="body2"
										color={'text.secondary'}>
										{ACTIVITIES.no_available}
									</Typography>
								</Stack>
							</CardActionArea>
						</Card>
					</Grid>
				))}
			</Grid>
		</Content>
	);
}
