import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * Link element from Material Design. Include navigation dom for href link.
 * Use this Link for navigate only inside the app. Default click events was disabled.
 * Also is possible add onClick for other purpose.
 *
 * By Frankil A.
 * @param {import("@mui/material").LinkProps} props
 */
export default function LinkNavigate({ onClick, ...props }) {
	const navigate = useNavigate();

	const handleClick = (e) => {
		e.preventDefault();
		if (onClick) onClick(e);
		navigate(props.href);
	};

	return <Link onClick={handleClick} {...props} />;
}
