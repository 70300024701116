import { useCallback, useEffect, useMemo, useState } from 'react';
import { Fade, Stack, styled } from '@mui/material';
import IconPagination from './IconPagination';
import { TransitionGroup } from 'react-transition-group';

const Image = styled('img')({
	width: '100%',
	height: '250px',
	maxHeight: '250px',
	position: 'absolute',
	objectFit: 'contain',
});

export default function Banners() {
	const [current, setCurrent] = useState(1);
	const images = useMemo(
		() => [
			{
				title: 'Haz ejercicio',
				image: '/banners/undraw_fitness.png',
			},
			{
				title: 'Entrena frecuentemente',
				image: '/banners/undraw_training.png',
			},
			{
				title: 'Come saludable',
				image: '/banners/undraw_healthy.png',
			},
			{
				title: 'Feliz 2024',
				image: '/banners/kaady_banner_1.png',
			},
		],
		[]
	);

	const handleChange = useCallback(
		(_e, value) => {
			if (value > images.length) value = 1;
			setCurrent(value);
		},
		[images]
	);

	useEffect(() => {
		const intervalId = setInterval(() => {
			handleChange(null, current + 1);
		}, 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [handleChange, current]);

	const image = images.map(
		(image, index) =>
			current - 1 === index && (
				<Fade key={index}>
					<Image alt={image.title} src={image.image} />
				</Fade>
			)
	);

	return (
		<Stack
			gap={'16px'}
			direction={'column'}
			alignItems={'center'}
			flexGrow={1}
			width={'100%'}>
			<Stack
				direction={'row'}
				width={'100%'}
				height={'250px'}
				overflow={'hidden'}
				position={'relative'}>
				<TransitionGroup>{image}</TransitionGroup>
			</Stack>
			<Stack gap={'16px'}>
				<IconPagination
					count={images.length}
					page={current}
					onChange={handleChange}
				/>
			</Stack>
		</Stack>
	);
}
