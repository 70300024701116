import { AboutDictionary } from './dictionaries/About.ts';
import { ActivitiesDictionary } from './dictionaries/Activities.ts';
import { CentersDictionary } from './dictionaries/Centers.ts';
import { FooterDictionary } from './dictionaries/Footer.ts';
import { HomeDictionary } from './dictionaries/Home.ts';
import { MembershipsDictionary } from './dictionaries/Memberships.ts';
import { NavbarDictionary } from './dictionaries/Navbar.ts';
import { NotFoundDictionary } from './dictionaries/NotFound.ts';
import { PartnersDictionary } from './dictionaries/Partners.ts';
import { PoliciesDictionary } from './dictionaries/Policies.ts';

export type Lang = 'es' | 'en';

/**
 * Manage the languages in the strings resources values by sections
 *
 * By Frankil A.
 */
export default function useDictionary(lang?: Lang) {
	// Default
	let NOTFOUND = NotFoundDictionary.es;
	let NAVBAR = NavbarDictionary.es;
	let FOOTER = FooterDictionary.es;
	let HOME = HomeDictionary.es;
	let PARTNERS = PartnersDictionary.es;
	let MEMBERSHIPS = MembershipsDictionary.es;
	let ACTIVITIES = ActivitiesDictionary.es;
	let CENTERS = CentersDictionary.es;
	let ABOUT = AboutDictionary.es;
	let POLICIES = PoliciesDictionary.es;

	return {
		lang,
		NOTFOUND,
		NAVBAR,
		FOOTER,
		HOME,
		PARTNERS,
		MEMBERSHIPS,
		ACTIVITIES,
		CENTERS,
		ABOUT,
		POLICIES,
	};
}
