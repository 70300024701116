import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

/**
 * Basic response modal
 * @param {Object} param
 * @param {string} param.title
 * @param {string} param.message
 * @param {boolean} param.open
 * @param {()=>void} param.onClose
 */
export default function BasicModal({
	title = 'Basic modal',
	message = '',
	open = false,
	onClose = () => {},
}) {
	const handleClose = () => onClose();
	return (
		<Dialog keepMounted fullWidth maxWidth="xs" open={open}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText
					sx={{
						wordBreak: 'break-word',
					}}>
					{message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>OK</Button>
			</DialogActions>
		</Dialog>
	);
}
