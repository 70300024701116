import { Button, Card, Divider, Grid, Stack, Typography } from '@mui/material';

import CheckIcon from '@mui/icons-material/CheckCircleOutlined';

import Section from '@components/Section';
import Content from '@components/Content';

import { useLargeScreen } from '@utils/Media';
import useDictionary from '@values/Dictionary.ts';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getPriceFormat } from '@utils/Format';
import useCards from '@values/Cards';

export default function MembershipsSection() {
	const isLarge = useLargeScreen();
	const { MEMBERSHIPS } = useDictionary();
	const { cards } = useCards();
	const options = [
		{
			title: MEMBERSHIPS.activity_header_1,
			content: MEMBERSHIPS.activity_text_1,
		},
		{
			title: MEMBERSHIPS.activity_header_2,
			content: MEMBERSHIPS.activity_text_2,
		},
		{
			title: MEMBERSHIPS.activity_header_3,
			content: MEMBERSHIPS.activity_text_3,
		},
		{
			title: MEMBERSHIPS.activity_header_4,
			content: MEMBERSHIPS.activity_text_4,
		},
	];

	const cards_content = cards.map((item, index) => (
		<Grid key={index} item xs={12} sm={6}>
			<Card
				variant="outlined"
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<Stack component={'header'} padding={'16px 16px 12px 16px'}>
					<Typography variant="h5" component={'h1'}>
						{item.title}
					</Typography>
				</Stack>
				<Divider />
				<Stack direction={'column'} gap={'8px'} padding={'16px'}>
					{item.description.map((value, i) => (
						<Stack
							key={i}
							alignItems={'center'}
							direction={'row'}
							gap={'8px'}>
							<CheckIcon color="success" />
							<Typography variant="body1">{value}</Typography>
						</Stack>
					))}
				</Stack>
				<Stack direction={'column'} padding={'8px 16px'}>
					<Stack direction={'row'}>
						<Typography variant="body1">
							1 {MEMBERSHIPS.month}
						</Typography>
						<Typography variant="body1" flexGrow={1} textAlign={'end'}>
							{getPriceFormat(item.price_1)}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={'column'} padding={'8px 16px'}>
					<Stack direction={'row'}>
						<Typography variant="body1">
							3 {MEMBERSHIPS.months}
						</Typography>
						<Typography variant="body1" flexGrow={1} textAlign={'end'}>
							{getPriceFormat(item.price_3)}
						</Typography>
					</Stack>
					<Stack direction={'row'} color={'text.secondary'}>
						<Typography variant="caption">
							{MEMBERSHIPS.save}{' '}
							{Math.round(
								(100 * (item.price_1 - item.price_3 / 3)) /
									item.price_1
							)}{' '}
							%
						</Typography>
						<Typography
							variant="caption"
							flexGrow={1}
							textAlign={'end'}
							component={'strike'}>
							{getPriceFormat(item.price_1 * 3)}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={'column'} padding={'8px 16px'}>
					<Stack direction={'row'}>
						<Typography variant="body1">
							6 {MEMBERSHIPS.months}
						</Typography>
						<Typography variant="body1" flexGrow={1} textAlign={'end'}>
							{getPriceFormat(item.price_6)}
						</Typography>
					</Stack>
					<Stack direction={'row'} color={'text.secondary'}>
						<Typography variant="caption">
							{MEMBERSHIPS.save}{' '}
							{Math.round(
								(100 * (item.price_1 - item.price_6 / 6)) /
									item.price_1
							)}{' '}
							%
						</Typography>
						<Typography
							variant="caption"
							flexGrow={1}
							textAlign={'end'}
							component={'strike'}>
							{getPriceFormat(item.price_1 * 6)}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={'column'} padding={'8px 16px'}>
					<Stack direction={'row'}>
						<Typography variant="body1">1 {MEMBERSHIPS.year}</Typography>
						<Typography variant="body1" flexGrow={1} textAlign={'end'}>
							{getPriceFormat(item.price_12)}
						</Typography>
					</Stack>
					<Stack direction={'row'} color={'text.secondary'}>
						<Typography variant="caption">
							{MEMBERSHIPS.save}{' '}
							{Math.round(
								(100 * (item.price_1 - item.price_12 / 12)) /
									item.price_1
							)}{' '}
							%
						</Typography>
						<Typography
							variant="caption"
							flexGrow={1}
							textAlign={'end'}
							component={'strike'}>
							{getPriceFormat(item.price_1 * 12)}
						</Typography>
					</Stack>
				</Stack>
				<Stack
					direction={'row'}
					justifyContent={'center'}
					alignItems={'end'}
					padding={'16px'}
					flexGrow={1}>
					<Button>{MEMBERSHIPS.card_subscribe}</Button>
				</Stack>
			</Card>
		</Grid>
	));

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>{MEMBERSHIPS.title}</title>
				</Helmet>
			</HelmetProvider>
			<Section
				component={'section'}
				sx={{
					flexDirection: 'column',
					gap: '36px',
					padding: isLarge ? '90px 56px' : '56px 16px',
				}}>
				<Stack
					component={'header'}
					direction={'column'}
					gap={'16px'}
					textAlign={'center'}>
					<Typography variant={isLarge ? 'h3' : 'h4'} component={'h1'}>
						{MEMBERSHIPS.hero_header}
					</Typography>
					<Typography variant={'h6'} color={'text.secondary'}>
						{MEMBERSHIPS.hero_text}
					</Typography>
				</Stack>
				<Grid container spacing={3}>
					{options.map((item, index) => (
						<Grid key={index} item xs={12} sm={6}>
							<Stack
								direction={'column'}
								gap={'8px'}
								padding={'8px 16px'}>
								<Typography variant="h6">{item.title}</Typography>
								<Typography variant="body2" color={'text.secondary'}>
									{item.content}
								</Typography>
							</Stack>
						</Grid>
					))}
				</Grid>
			</Section>
			<Content>
				<Stack direction={'column'} gap={'16px'} textAlign={'center'}>
					<Typography variant={'h4'} component={'h1'}>
						{MEMBERSHIPS.card_header}
					</Typography>
					<Typography variant={'h6'} color={'text.secondary'}>
						{MEMBERSHIPS.card_text}
					</Typography>
				</Stack>
				<Grid container spacing={3} justifyContent={'center'}>
					{cards_content}
				</Grid>
				<Typography variant="body1" color={'text.secondary'}>
					{MEMBERSHIPS.card_policy}
				</Typography>
			</Content>
		</>
	);
}
