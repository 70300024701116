import { Zone } from '@models/Zone.ts';
import { authConfig } from './Auth';
import axios from 'axios';

/**
 * Get all available zones
 *
 */
export async function requestZones() {
	try {
		const res = await axios.get('/api/zones', authConfig());
		return Array.isArray(res.data) ? res.data.map((item) => new Zone(item)) : [];
	} catch (error) {
		console.error(error.message);
	}
}

export async function loadZonesToStorage() {
	try {
		const zones = await requestZones();
		localStorage.setItem('zones', JSON.stringify(zones));
	} catch (error) {
		console.error(error.message);
	}
}

export async function getZonesFromStorage() {
	try {
		const local = localStorage.getItem('zones');
		if (local) {
			const data = JSON.parse(local);
			return data;
		} else {
			const data = await requestZones();
			return data;
		}
	} catch (error) {
		console.error(error.message);
	}
}
