import { styled, TextField } from '@mui/material';

const StyledTextField = styled(TextField)(() => {
	return {
		minWidth: '250px',
		'& fieldset': {
			borderRadius: '100px',
		},
		'& .MuiInputBase-root': {
			padding: '6px 16px',
		},
	};
});

export default StyledTextField;
