import BasicModal from '@components/BasicModal';
import { InputPassword } from '@modules/shared/Forms';
import {
	ArrowBack,
	CheckOutlined,
	EmailOutlined,
	NumbersOutlined,
} from '@mui/icons-material';
import {
	Avatar,
	Button,
	Card,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	alpha,
} from '@mui/material';
import Loading from '@pages/Loading';
import {
	isAuthenticated,
	requestBasicInfo,
	requestVerificationStart,
	requestVerificationUpdate,
	requestVerificationValidate,
} from '@utils/Auth';
import { useLargeScreen } from '@utils/Media';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function RecoverSteps() {
	const [loading, setLoading] = useState(true);
	const [step, setStep] = useState(0);
	const [email, setEmail] = useState('');
	const [modal, setModal] = useState({
		open: false,
		title: '',
		message: '',
		onClose: () => setModal({ ...modal, open: false }),
	});

	useEffect(() => {
		const isLogged = async () => {
			const logged = await isAuthenticated();
			if (logged) {
				const user = await requestBasicInfo();
				setEmail(user?.contacts[0]?.value);
				setStep(1);
			}
			setLoading(false);
		};
		isLogged();
	}, []);

	const handleEmail = (value) => {
		setEmail(value);
	};

	const handleNext = () => {
		setLoading(false);
		setStep(step + 1);
	};

	const handleRestart = () => {
		setLoading(false);
		setStep(0);
	};

	const handleError = (title, message) => {
		setLoading(false);
		setModal({
			...modal,
			open: true,
			title: title,
			message: message,
		});
	};

	const handleAction = () => {
		setLoading(true);
	};

	const steps = [
		<EmailStep
			onAction={handleAction}
			onError={handleError}
			onCompleted={handleEmail}
			onNext={handleNext}
		/>,
		<VerifyStep
			email={email}
			onAction={handleAction}
			onError={handleError}
			onNext={handleNext}
			onRestart={handleRestart}
		/>,
		<UpdateStep
			email={email}
			onAction={handleAction}
			onError={handleError}
			onNext={handleNext}
		/>,
		<ResponseStep />,
	];

	if (loading) {
		return <Loading open message="Verificando información..." />;
	}

	return (
		<>
			{steps[step]}
			<BasicModal {...modal} />
		</>
	);
}

export function EmailStep({
	onAction = () => {},
	onError = () => {},
	onCompleted = () => {},
	onNext = () => {},
}) {
	const isLarge = useLargeScreen();
	const [enabled, setEnabled] = useState(true);
	const [data, setData] = useState({ email: '' });

	const handleStep = async () => {
		setEnabled(false);
		onAction();
		// send verification
		const res = await requestVerificationStart(data);
		if (res.status !== 200) {
			const errors = res.data?.errors ? Object.values(res.data?.errors) : [];
			onError(
				res.data?.message || 'Ha ocurrido un error',
				errors.length > 0
					? errors[0]
					: 'Por el momento no es posible verificar tu cuenta. Intenta de nuevo más tarde.'
			);
		} else {
			onCompleted(data.email);
			onNext();
		}
		setEnabled(true);
	};

	return (
		<Card
			variant="outlined"
			sx={(t) => ({
				width: '100%',
				maxWidth: isLarge ? '380px' : '400px',
				border: isLarge ? '' : 'none',
			})}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				padding={'16px 16px 8px 16px'}>
				<Typography variant="h5" component={'h1'}>
					Recupera tu cuenta
				</Typography>
			</Stack>
			<Stack padding={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					Para recuperar tu cuenta es necesario que tengas a la mano tu
					correo electrónico. A continuación, confirma y verifica tu
					correo.
				</Typography>
			</Stack>
			<Stack component={'form'} padding={'16px'}>
				<TextField
					fullWidth
					name="email"
					label="Correo electrónico"
					variant="filled"
					type="email"
					disabled={!enabled}
					value={data.email}
					onChange={(e) => setData({ email: e.target.value })}
					autoComplete="no"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<EmailOutlined />
							</InputAdornment>
						),
					}}
				/>
			</Stack>
			<Stack padding={'16px 16px 24px 16px'}>
				<Button
					fullWidth
					size="large"
					variant="contained"
					disabled={!enabled}
					onClick={handleStep}>
					Recuperar cuenta
				</Button>
			</Stack>
		</Card>
	);
}

export function VerifyStep({
	email = '',
	onAction = () => {},
	onError = () => {},
	onNext = () => {},
	onRestart = () => {},
}) {
	const isLarge = useLargeScreen();
	const [enabled, setEnabled] = useState(true);
	const [data, setData] = useState({ email: email, code: '' });

	const handleStep = async () => {
		setEnabled(false);
		onAction();
		// send verification
		const res = await requestVerificationValidate(data);
		if (res.status !== 200) {
			const errors = res.data?.errors ? Object.values(res.data?.errors) : [];
			onError(
				res.data?.message || 'Ha ocurrido un error',
				errors.length > 0
					? errors[0]
					: 'No fue posible verificar su cuenta. Si necesitas puedes pedir ayuda en la sección "Ayuda".'
			);
		} else {
			onNext();
		}
		setEnabled(true);
	};
	return (
		<Card
			variant="outlined"
			sx={(t) => ({
				width: '100%',
				maxWidth: isLarge ? '380px' : '400px',
				border: isLarge ? '' : 'none',
			})}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				gap={'8px'}
				padding={'12px 16px 4px 16px'}>
				<IconButton onClick={onRestart}>
					<ArrowBack />
				</IconButton>
				<Typography variant="h5" component={'h1'}>
					Verifica tu correo
				</Typography>
			</Stack>
			<Stack padding={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					Se ha enviado un código de verificación al correo{' '}
					<strong>{email}</strong>. Sólo es válido por 5 minutos. Si no lo
					encuentras, revisa en <strong>Spam</strong> o en{' '}
					<strong>No deseados</strong> y después ingresa el código aquí.
				</Typography>
			</Stack>
			<Stack component={'form'} padding={'16px'}>
				<TextField
					fullWidth
					name="code"
					label="Código de verificación"
					variant="filled"
					type="text"
					autoComplete="no"
					disabled={!enabled}
					value={data.code}
					onChange={(e) => setData({ ...data, code: e.target.value })}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<NumbersOutlined />
							</InputAdornment>
						),
					}}
				/>
			</Stack>
			<Stack padding={'16px 16px 24px 16px'}>
				<Button
					fullWidth
					size="large"
					variant="contained"
					disabled={!enabled}
					onClick={handleStep}>
					Verificar código
				</Button>
			</Stack>
		</Card>
	);
}

export function UpdateStep({
	email = '',
	onAction = () => {},
	onError = () => {},
	onNext = () => {},
}) {
	const isLarge = useLargeScreen();
	const [enabled, setEnabled] = useState(true);
	const [data, setData] = useState({
		email: email,
		password: '',
		password_confirm: '',
		password_match: false,
	});

	const handleStep = async () => {
		setEnabled(false);
		onAction();
		// send verification
		const res = await requestVerificationUpdate(data);
		if (res.status !== 200) {
			const errors = res.data?.errors ? Object.values(res.data?.errors) : [];
			onError(
				res.data?.message || 'Ha ocurrido un error',
				errors.length > 0
					? errors[0]
					: 'Por favor revisa la información e intenta de nuevo.'
			);
		} else {
			onNext();
		}
		setEnabled(true);
	};

	const handlePassword = (value) => {
		setData({
			...data,
			password: value,
			password_match: value !== '' && value === data.password_confirm,
		});
	};
	const handlePasswordConfirm = (value) => {
		setData({
			...data,
			password_confirm: value,
			password_match: value !== '' && value === data.password,
		});
	};
	return (
		<Card
			variant="outlined"
			sx={(t) => ({
				width: '100%',
				maxWidth: isLarge ? '380px' : '400px',
				border: isLarge ? '' : 'none',
			})}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				padding={'16px 16px 8px 16px'}>
				<Typography variant="h5" component={'h1'}>
					Actualiza tu contraseña
				</Typography>
			</Stack>
			<Stack padding={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					¡Ya casi terminas! Cambia tu contraseña para proteger tu cuenta,
					usa una contraseña segura y diferente a la actual.
				</Typography>
			</Stack>
			<Stack component={'form'} gap={'12px'} padding={'16px'}>
				<InputPassword
					name="password"
					label="Contraseña nueva"
					enabled={enabled}
					onChange={handlePassword}
				/>
				<InputPassword
					name="password-confirm"
					label="Confirma tu contraseña"
					enabled={enabled}
					onChange={handlePasswordConfirm}
					matchError={!data.password_match}
				/>
			</Stack>
			<Stack padding={'16px 16px 24px 16px'}>
				<Button
					fullWidth
					size="large"
					variant="contained"
					disabled={!enabled || !data.password_match}
					onClick={handleStep}>
					Actualizar contraseña
				</Button>
			</Stack>
		</Card>
	);
}

export function ResponseStep() {
	const isLarge = useLargeScreen();
	const navigate = useNavigate();
	return (
		<Card
			variant="outlined"
			sx={(t) => ({
				width: '100%',
				maxWidth: isLarge ? '400px' : '420px',
				border: isLarge ? '' : 'none',
			})}>
			<Stack padding={'28px 16px 8px 16px'} gap={'16px'} alignItems={'center'}>
				<Avatar
					sx={(t) => ({
						bgcolor: alpha(t.palette.primary.main, 0.28),
						color: t.palette.primary.main,
					})}>
					<CheckOutlined />
				</Avatar>
				<Typography variant="h5" component={'h1'}>
					Has actualizado tu contraseña
				</Typography>
			</Stack>
			<Stack padding={'16px'}>
				<Typography variant="body1" color={'text.secondary'}>
					Tu contraseña ha sido actualizada correctamente. Asegúrate de
					tener un correo actualizado para no perder el acceso a tu cuenta.
					Ahora, inicia sesión de manera normal.
				</Typography>
			</Stack>
			<Stack padding={'16px 16px 24px 16px'}>
				<Button
					onClick={() => navigate('/login')}
					fullWidth
					size="large"
					variant="contained">
					Iniciar sesión
				</Button>
			</Stack>
		</Card>
	);
}
