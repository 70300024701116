import { useLargeScreen } from '@utils/Media';

const { styled, Stack } = require('@mui/material');

export const Panel = styled(Stack)(() => {
	const isLarge = useLargeScreen();
	return {
		display: 'flex',
		flexDirection: 'row',
		gap: '16px',
		padding: '16px 16px 16px 16px',
		height: isLarge ? 'calc(100vh - 70px)' : 'calc(100vh - 56px)',
		flexGrow: '1',
		overflow: 'hidden',
	};
});

export const PanelCard = styled(Stack)(({ theme }) => {
	const isLarge = useLargeScreen();
	return {
		position: 'relative',
		width: '100%',
		height: isLarge ? 'calc(100vh - 102px)' : 'calc(100vh - 88px)',
		flexGrow: '1',
		overflow: 'hidden',
		border: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.background.default,
		borderRadius: '16px',
	};
});

export const PanelContent = styled(Stack)(() => {
	const isLarge = useLargeScreen();
	return {
		position: 'relative',
		height: isLarge ? 'calc(100vh - 102px)' : 'calc(100vh - 88px)',
		width: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
	};
});

export const PanelHeader = styled(Stack)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	borderBottom: `1px solid ${theme.palette.divider}`,
	zIndex: '20',
	top: '0',
	position: 'sticky',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	width: '100%',
	height: '64px',
	minHeight: '64px',
	padding: '0px 8px 0px 16px',
}));

export const PanelAction = styled(Stack)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	borderTop: `1px solid ${theme.palette.divider}`,
	zIndex: '20',
	bottom: '0',
	position: 'sticky',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: '64px',
	minHeight: '64px',
	padding: '0px 8px',
}));
