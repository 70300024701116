import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from '@mui/material';

export function VerifyDialog({
	open = false,
	name = '',
	email = '',
	onClose = (res) => {},
}) {
	return (
		<Dialog keepMounted fullWidth maxWidth="xs" open={open}>
			<DialogTitle>¡Bienvenido, {name}! 👋</DialogTitle>
			<DialogContent>
				<Typography color={'text.secondary'}>
					Gracias por usar nuestra aplicación desde hace tiempo.
					Recientemente se han actualizando todos los sistemas, por lo que
					te recomendamos que veriques tu cuenta y cambies tu contraseña.
					Así te mantienes actualizado y seguro con las últimas opciones de
					la nueva versión de Kaady Sport.
				</Typography>
				<Typography marginTop={'8px'}>
					¿Te gustaría verificar tu cuenta ahora mismo?
				</Typography>
				<Stack
					marginTop={'8px'}
					sx={(t) => ({
						backgroundColor: t.palette.primary.main,
						color: t.palette.primary.contrastText,
						padding: '6px 12px',
						borderRadius: '8px',
					})}>
					<Typography
						variant="body2"
						fontWeight={'bold'}
						color={'inherit'}>
						{email}
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={() => onClose(false)}>
					Omitir
				</Button>
				<Button onClick={() => onClose(true)}>Verificar ahora</Button>
			</DialogActions>
		</Dialog>
	);
}
