// Definiciones
export type PartnersRes = {
	title: string;
	hero_header: string;
	hero_text: string;
	hero_alt: string;
	benefit_header: string;
	benefit_1: string;
	benefit_2: string;
	benefit_3: string;
	benefit_4: string;
	benefit_5: string;
	activity_header: string;
	activity_text: string;
	joining_header: string;
	joining_text: string;
	joining_alt: string;
	joining_action: string;
};

export type PartnersLang = {
	es?: PartnersRes;
	en?: PartnersRes;
};

// Traducciones
export const PartnersDictionary: PartnersLang = {
	es: {
		title: 'Forma parte de nuestros socios',
		hero_header: 'Todos los centros deportivos son bienvenidos',
		hero_text: 'Aprovecha los diferentes beneficios de nuestros socios',
		hero_alt: 'Entrenamiento de running',
		benefit_header: 'Cada centro obtiene más ventajas',
		benefit_1: 'Más visibilidad y visitas',
		benefit_2: 'Más ganancias y ventas',
		benefit_3: 'Activación digital del centro',
		benefit_4: 'Publicidad en nuestras plataformas',
		benefit_5: 'Herramientas administrativas',
		activity_header: 'Están disponibles diferentes actividades',
		activity_text:
			'Toda actividad física, de activación o acondicionamiento está permitida para formar parte de Kaady Sport',
		joining_header: '¿Te gustaría unirte a nuestros socios?',
		joining_text:
			'La activación digital y el convenio de asociados se lleva personalmente con nuestros asesores. Solicita tu registro en el siguiente enlace',
		joining_alt: 'Formulario de registro',
		joining_action: 'Unirse ahora',
	},
};
