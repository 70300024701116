import { capitalizeFirstLetter } from '@utils/Format.js';
import { ContactType, DataImageType, StatusType, UserType } from '@values/Types';
import { Contact } from './Contacts.ts';
import { DataImage } from './DataImage.ts';
import { Status } from './Status.ts';

export class User implements UserType {
	id?: string;
	user: string;
	name: string;
	last_name: string;
	contacts: ContactType[];
	picture: DataImageType;
	sex: string;
	birthday: string;
	verified: boolean;
	verified_at: string;
	status_code: number;
	status: StatusType;

	constructor(data: any) {
		this.id = data?.id;
		this.user = data?.user || 'unknown';
		this.name = capitalizeFirstLetter(data?.name) || '';
		this.last_name = capitalizeFirstLetter(data?.last_name) || '';
		this.contacts = Array.isArray(data?.contacts)
			? data?.contacts.map((item: any) => new Contact(item))
			: [];
		this.picture = new DataImage(data?.picture);
		this.sex = data?.sex || 'HIDDEN';
		this.birthday = data?.birthday || '';
		this.verified = data?.verified || false;
		this.verified_at = data?.verified_at || '';
		this.status_code = data?.status_code || -1;
		this.status = new Status(data?.status);
	}
}
