import { styled, Stack } from '@mui/material';
import { useLargeScreen } from '@utils/Media';

const FormRoot = styled(Stack)(() => {
	const isLarge = useLargeScreen();
	return {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxSizing: 'border-box',
		padding: isLarge ? '8px 56px' : '24px 16px',
		minHeight: isLarge ? 'calc(100vh - 155px)' : 'calc(100vh - 190px)',
		marginBottom: '64px',
	};
});

export default FormRoot;
