import { useMediaQuery } from '@mui/material';

/**
 * Verify media screen large with media queries
 *
 * @returns True if is the screen is large.
 */
export function useLargeScreen() {
	return useMediaQuery('(min-width: 800px)');
}

/**
 * Verify media screen extra large with media queries
 *
 * @returns True if is the screen is extra large.
 */
export function useExtraScreen() {
	return useMediaQuery('(min-width: 1100px)');
}
