// Google
import { useGoogleLogin } from '@react-oauth/google';
import { Button, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { GoogleIcon } from './Icons';
import { useState } from 'react';

// Google blue : #1a73e8

const theme = createTheme({
	palette: {
		primary: {
			main: '#101010',
		},
		divider: '#747775',
	},
	typography: {
		button: {
			textTransform: 'none',
			lineHeight: 'normal',
			fontWeight: 'bold',
		},
	},
});

/**
 * A custom google login button
 * @param {Object} param0
 * @param {(token: string) => void} [param0.onSuccess=(token) => {}]
 * @param {(err: string) => void} [param0.onError=(err) => {}]
 * @returns
 */
export default function GLogin({
	onSuccess = (token) => {},
	onError = (err) => {},
}) {
	const [enabled, setEnabled] = useState(true);

	// Theming button
	const dark = useMediaQuery('(prefers-color-scheme: dark)');

	const handleGoogle = useGoogleLogin({
		onSuccess: (res) => {
			onSuccess(res.access_token);
			setEnabled(true);
		},
		onError: (res) => {
			onError(res.error_description);
			setEnabled(true);
		},
		onNonOAuthError: (res) => {
			onError(res.type);
			setEnabled(true);
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<Button
				sx={{
					borderRadius: '50px',
					padding: '8px 12px',
				}}
				disabled={!enabled}
				variant={dark ? 'contained' : 'outlined'}
				onClick={() => {
					setEnabled(false);
					handleGoogle();
				}}
				startIcon={<GoogleIcon />}>
				Continuar con Google
			</Button>
		</ThemeProvider>
	);
}
