// Definiciones
export type MembershipsRes = {
	title: string;
	hero_header: string;
	hero_text: string;
	activity_header_1: string;
	activity_text_1: string;
	activity_header_2: string;
	activity_text_2: string;
	activity_header_3: string;
	activity_text_3: string;
	activity_header_4: string;
	activity_text_4: string;
	card_header: string;
	card_text: string;
	card_policy: string;
	card_subscribe: string;
	month: string;
	months: string;
	year: string;
	save: string;
};

export type MembershipsLang = {
	es?: MembershipsRes;
	en?: MembershipsRes;
};

// Traducciones
export const MembershipsDictionary: MembershipsLang = {
	es: {
		title: 'Membresías y suscripciones con acceso a centros',
		hero_header: 'Una membresía con varias opciones',
		hero_text:
			'En Kaady Sport obtienes más ventajas con una membresía a tu medida, elije y organiza varias actividades a tu gusto',
		activity_header_1: 'Actividades deportivas',
		activity_text_1:
			'Elige una gran variedad de actividades deportivas y entrenamientos para mejorar tu condición física.',
		activity_header_2: 'Acceso a herramientas',
		activity_text_2:
			'Explora las diferentes herramientas especiales para un seguimiento de tus ejercicios.',
		activity_header_3: 'Clases en centros',
		activity_text_3:
			'Entrena en diferentes centros deportivos disponibles con una misma membresía.',
		activity_header_4: 'Promociones y descuentos',
		activity_text_4:
			'Aprovecha los descuentos en diferentes productos de los centros. Además obtienes promociones en tiendas.',
		card_header:
			'Todas las membresías incluyen acceso a diferentes centros deportivos',
		card_text: 'Elije la mejor opción a tu medida y cancela cuando quieras. *',
		card_policy:
			'* Aplica términos y condiciones según el periodo de la membresía. Consulta Términos y Condiciones.',
		card_subscribe: 'Suscribirme',
		month: 'mes',
		months: 'meses',
		year: 'año',
		save: 'Ahorra',
	},
};
