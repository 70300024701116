// Definiciones
export type NavbarRes = {
	memberships: string;
	partners: string;
	activities: string;
	centers: string;
	start: string;
	go_start: string;
	see_options: string;
};

export type NavbarLang = {
	es?: NavbarRes;
	en?: NavbarRes;
};

// Traducciones
export const NavbarDictionary: NavbarLang = {
	es: {
		memberships: 'Membresías',
		partners: 'Socios',
		activities: 'Actividades',
		centers: 'Centros',
		start: 'Empezar',
		go_start: 'Ir a inicio',
		see_options: 'Ver opciones',
	},
};
