import { List, ListItemButton, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useLargeScreen } from '@utils/Media';

const SidebarButton = styled(ListItemButton)(({ theme }) => ({
	flexGrow: '0',
	borderRadius: '100px',
	color: theme.palette.text.secondary,
	display: 'flex',
	flexDirection: 'row',
	gap: '12px',
	padding: '12px 16px',
	'&.Mui-selected': {
		color: theme.palette.text.primary,
	},
}));

/**
 * The sidebar uses a list of objects with 'label' and 'icon' properties
 */
export function Sidebar({
	header = '',
	index = -1,
	onChange = () => {},
	options = [],
}) {
	const [activeIndex, setActiveIndex] = useState(index);
	const isLarge = useLargeScreen();
	const year = new Date().getFullYear();
	const handleClick = (i) => {
		setActiveIndex(i);
		onChange(i);
	};

	const links = options.map((value, index) => (
		<SidebarButton
			key={index}
			selected={index === activeIndex}
			onClick={() => handleClick(index)}>
			{value.icon}
			<Typography fontWeight={'bold'} variant="subtitle2" color={'inherit'}>
				{value.label}
			</Typography>
		</SidebarButton>
	));

	return (
		<Stack
			width={!isLarge ? '272px' : '240px'}
			minWidth={!isLarge ? '272px' : '240px'}
			height={'100%'}
			padding={!isLarge ? '16px' : ''}>
			{header.trim() !== '' ? (
				<Stack
					direction={'row'}
					padding={'0px 16px'}
					height={'48px'}
					minHeight={'48px'}
					alignItems={'center'}>
					<Typography
						fontWeight={'bold'}
						variant="subtitle1"
						component={'h1'}>
						{header}
					</Typography>
				</Stack>
			) : null}
			<List
				sx={{
					width: '100%',
					height: isLarge ? 'calc(100vh - 186px)' : 'calc(100vh - 116px)',
					overflow: 'auto',
					display: 'flex',
					flexDirection: 'column',
					gap: '2px',
				}}>
				{options.length !== 0 ? links : null}
			</List>
			<Stack height={'36px'} minHeight={'36px'} justifyContent={'end'}>
				<Typography variant="caption" color={'text.secondary'}>
					© Kaady Sport S.A. de C.V. ({year}) México
				</Typography>
			</Stack>
		</Stack>
	);
}
