// Facebook
import FacebookIcon from '@mui/icons-material/FacebookOutlined';

// Material design
import { Button, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { FACEBOOK_APP_ID } from '@values/Default';
import loadFacebookSDK from '@utils/FacebookSDK';

// Theming
const theme = createTheme({
	palette: {
		primary: {
			main: '#1877F2',
		},
	},
	typography: {
		button: {
			textTransform: 'none',
			lineHeight: 'normal',
			fontWeight: 'bold',
		},
	},
});

/**
 *
 * A custom facebook login button
 * @param {Object} param0
 * @param {(token: string) => void} [param0.onSucces=(token)=>{}]
 * @param {(code: string) => void} [param0.onError=(code)=>{}]
 * @returns
 */
export default function FBLogin({
	onSucces = (token) => {},
	onError = (code) => {},
}) {
	const [enabled, setEnabled] = useState(true);

	// Theming button dark
	const dark = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		try {
			// load
			loadFacebookSDK(FACEBOOK_APP_ID);
		} catch (error) {
			console.log(error.message);
		}
	}, []);

	async function handleLogin() {
		setEnabled(false);
		if (window.FB) {
			window.FB.login(
				function (response) {
					if (response.status === 'connected') {
						onSucces(response.authResponse.accessToken);
					} else {
						onError(response.status);
					}
					setEnabled(true);
				},
				{ scope: 'public_profile,email' }
			);
		}
		setEnabled(true);
	}

	return (
		<ThemeProvider theme={theme}>
			<Button
				sx={{
					borderRadius: '50px',
					padding: '8px 12px',
				}}
				disabled={!enabled}
				onClick={handleLogin}
				variant={dark ? 'contained' : 'outlined'}
				startIcon={<FacebookIcon />}>
				Continuar con Facebook
			</Button>
		</ThemeProvider>
	);
}
