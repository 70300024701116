import { createTheme, useMediaQuery } from '@mui/material';

/**
 * Config some basic components and return partial Theme options
 *
 * @returns {import('@mui/material').ThemeOptions}
 */
function getBasicOptions() {
	return {
		components: {
			MuiCssBaseline: {
				styleOverrides: `
					html {
						color-scheme: light dark;
					}
				`,
			},
			MuiAutocomplete: {
				styleOverrides: {
					paper: {
						borderRadius: '16px',
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: '100px',
					},
				},
			},
			MuiMenu: {
				styleOverrides: {
					paper: {
						borderRadius: '16px',
						backgroundImage: 'none',
						minWidth: '200px',
					},
				},
			},
			MuiCard: {
				styleOverrides: {
					root: {
						borderRadius: '16px',
						backgroundImage: 'none',
					},
				},
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						borderRadius: '28px',
						backgroundImage: 'none',
					},
				},
			},
			MuiDialogActions: {
				styleOverrides: {
					root: {
						padding: '16px',
						gap: '8px',
					},
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						backgroundImage: 'none',
					},
				},
			},
		},
		typography: {
			button: {
				textTransform: 'none',
				fontWeight: 'bold',
			},
			h6: {
				fontWeight: 'normal',
			},
		},
	};
}

/**
 * Config the light colors palette using the them for expand
 * @param {import('@mui/material').Theme} theme
 * @returns {import('@mui/material').ThemeOptions}
 */
function getLightColors(theme) {
	return {
		palette: {
			primary: {
				main: '#075fac',
			},
			secondary: {
				main: '#545f71',
			},
			tonal: theme.palette.augmentColor({
				color: {
					main: '#d8e3f8',
				},
				name: 'tonal',
			}),
			elevated: theme.palette.augmentColor({
				color: {
					main: '#f3edf7',
					contrastText: theme.palette.primary.main,
				},
				name: 'elevated',
			}),
			error: {
				main: '#ba1a1a',
			},
			background: {
				default: '#FEF7FF',
				paper: '#FEF7FF',
				variant: '#ece6f0',
				logo: '#001c3a',
			},
			text: {
				primary: '#1a1c1e',
			},
			divider: '#c3c6cf',
		},
	};
}

/**
 * Config the light colors palette using the them for expand
 * @param {import('@mui/material').Theme} theme
 * @returns {import('@mui/material').ThemeOptions}
 */
function getDarkColors(theme) {
	return {
		palette: {
			primary: {
				main: '#a5c8ff',
			},
			secondary: {
				main: '#bcc7dc',
			},
			tonal: theme.palette.augmentColor({
				color: {
					main: '#3d4758',
				},
				name: 'tonal',
			}),
			elevated: theme.palette.augmentColor({
				color: {
					main: '#211f26',
					contrastText: theme.palette.primary.main,
				},
				name: 'elevated',
			}),
			error: {
				main: '#ffb4ab',
			},
			background: {
				default: '#141218',
				paper: '#141218',
				variant: '#2b2930',
				logo: '#ffffff',
			},
			text: {
				primary: '#e3e2e6',
			},
			divider: '#43474e',
		},
	};
}

export default function useCustomTheme() {
	const darkMode = useMediaQuery('(prefers-color-scheme: dark)');
	let theme = createTheme(
		{
			palette: {
				mode: darkMode ? 'dark' : 'light',
				contrastThreshold: 4.5,
				tonalOffset: 0.1,
			},
		},
		getBasicOptions()
	);
	const tokens = darkMode ? getDarkColors(theme) : getLightColors(theme);
	theme = createTheme(theme, tokens);
	return theme;
}
