import {
	CenterType,
	DataImageType,
	LocationType,
	ManagerType,
	SocialType,
	StatusType,
} from '@values/Types';
import { Manager } from './Manager.ts';
import { Location } from './Location.ts';
import { Social } from './Social.ts';
import { DataImage } from './DataImage.ts';
import { Status } from './Status.ts';
import { capitalizeFirstLetter } from '@utils/Format.js';

export class Center implements CenterType {
	id?: string;
	nanoid?: number;
	name: string;
	description: string;
	managers: ManagerType[];
	locations: LocationType[];
	ratings?: number[];
	social: SocialType;
	picture: DataImageType;
	portrait: DataImageType;
	images: DataImageType[];
	href: string;
	status_code?: number;
	status: StatusType;

	constructor(data: any) {
		this.id = data?.id;
		this.nanoid = data?.nanoid;
		this.name = capitalizeFirstLetter(data?.name) || '';
		this.description = data?.description || '';
		this.managers = Array.isArray(data?.managers)
			? data?.managers.map((item: any) => new Manager(item))
			: [];
		this.locations = Array.isArray(data?.locations)
			? data?.locations.map((item: any) => new Location(item))
			: [];
		this.ratings = data?.ratings || [];
		this.social = new Social(data?.social);
		this.picture = new DataImage(data?.picture);
		this.portrait = new DataImage(data?.portrait);
		this.images = Array.isArray(data?.images)
			? data?.images.map((item: any) => new DataImage(item))
			: [];
		this.href = data?.href || '/';
		this.status_code = data?.status_code;
		this.status = new Status(data?.status);
	}
}
