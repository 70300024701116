import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';

const List = styled('ul')({
	listStyle: 'none',
	padding: 0,
	margin: 0,
	display: 'flex',
	flexDirection: 'row',
	gap: '12px',
});

const PagBtn = styled('button')({
	height: '14px',
	width: '14px',
	border: 'none',
	borderRadius: '24px',
	cursor: 'pointer',
	'&:hover': {
		opacity: 0.64,
	},
});

/**
 * Creates a only icon pagination
 * @param {import('@mui/material/usePagination').UsePaginationProps} props
 */
export default function IconPagination(props) {
	const { items } = usePagination({
		hideNextButton: true,
		hidePrevButton: true,
		...props,
	});

	return (
		<nav>
			<List>
				{items.map(({ page, type, selected, ...item }, index) => {
					let children = null;

					if (type === 'start-ellipsis' || type === 'end-ellipsis') {
						children = '…';
					} else if (type === 'page') {
						children = (
							<PagBtn
								type="button"
								{...item}
								sx={(t) => ({
									backgroundColor: selected
										? t.palette.text.secondary
										: t.palette.background.variant,
								})}></PagBtn>
						);
					} else {
						children = (
							<button type="button" {...item}>
								{type}
							</button>
						);
					}

					return <li key={index}>{children}</li>;
				})}
			</List>
		</nav>
	);
}
