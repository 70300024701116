// Definiciones
export type CentersRes = {
	title: string;
	hero_header: string;
	hero_text: string;
	classes: string;
	gyms: string;
	stores: string;
	search_center: string;
	near_me: string;
	places: string;
};

export type CentersLang = {
	es?: CentersRes;
	en?: CentersRes;
};

// Traducciones
export const CentersDictionary: CentersLang = {
	es: {
		title: 'Busca tu centro deportivo más cercano',
		hero_header: 'Centros y gimnasios',
		hero_text:
			'Encuentra tu centro deportivo más cercano y entrena cuando quieras, en cualquier lugar que estés. Revisa los centros asociados',
		classes: 'Clases',
		gyms: 'Gimnasios',
		stores: 'Tiendas',
		near_me: 'Cerca de mí',
		places: 'Lugares',
		search_center: 'Buscar centro',
	},
};
