// Material design
import { Button, Card, Checkbox, Link, Stack, Typography } from '@mui/material';

// Icons
import { useState } from 'react';

// Validations
import { isValidEmail } from '@utils/Validations';
import { useNavigate } from 'react-router-dom';
import { useLargeScreen } from '@utils/Media';
import BasicModal from '@components/BasicModal';
import { InputEmail, InputPassword } from './Forms';
import { requestRegister } from '@utils/Auth';

/**
 * Creates the module for register users : Module 2
 *
 * By Frankil A.
 * @returns The JSX element for register users
 */
export default function UserRegister() {
	const isLarge = useLargeScreen();
	const navigate = useNavigate();

	// Data states
	const [enabled, setEnabled] = useState(true);
	const [data, setData] = useState({
		email: '',
		password: '',
		password_confirm: '',
		password_match: false,
		policies_agreement: false,
	});

	const [dialog, setDialog] = useState({
		open: false,
		title: '',
		message: '',
		onClose: () => setDialog({ ...dialog, open: false }),
	});

	const handleEmail = (value) => {
		setData({
			...data,
			email: value,
		});
	};

	const handlePassword = (value) => {
		setData({
			...data,
			password: value,
			password_match: value !== '' && value === data.password_confirm,
		});
	};

	const handlePasswordConfirm = (value) => {
		setData({
			...data,
			password_confirm: value,
			password_match: value !== '' && value === data.password,
		});
	};

	const handlePolicies = (state) => {
		setData({
			...data,
			policies_agreement: state,
		});
	};

	// Got to login
	const handleAccount = () => {
		navigate('/login');
	};

	// Send the register
	const handleSignup = async () => {
		setEnabled(false);
		const res = await requestRegister(data);
		if (res.status !== 201) {
			const errors = res.data?.errors ? Object.values(res.data?.errors) : [];
			setDialog({
				...dialog,
				open: true,
				title: res.data?.message || 'Ha ocurrido un error',
				message:
					errors.length > 0
						? errors[0]
						: 'No fue posible realizar el registro. Intenta de nuevo más tarde.',
			});
		} else {
			navigate('/home');
		}
		setEnabled(true);
	};

	// Validates the form
	const dataIsValid = () => {
		return (
			data.email.trim() !== '' &&
			isValidEmail(data.email) &&
			data.password.trim() !== '' &&
			data.password.length >= 8 &&
			data.password_match &&
			data.policies_agreement
		);
	};

	return (
		<Card
			variant="outlined"
			sx={(t) => ({
				width: '100%',
				maxWidth: isLarge ? '380px' : '400px',
				border: isLarge ? '' : 'none',
			})}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				padding={'16px 16px 8px 16px'}>
				<Typography variant="h5" component={'h1'}>
					Crea una cuenta
				</Typography>
			</Stack>
			<Stack component={'form'} padding={'16px'} gap={'12px'}>
				<InputEmail enabled={enabled} onChange={handleEmail} />
				<InputPassword
					label="Crea una contraseña"
					name="password"
					enabled={enabled}
					onChange={handlePassword}
				/>
				<InputPassword
					label="Confirma tu contraseña"
					name="password-confirm"
					enabled={enabled}
					matchError={!data.password_match}
					onChange={handlePasswordConfirm}
				/>
				<Stack
					direction="row"
					spacing={1}
					alignItems="center"
					paddingTop={'16px'}>
					<Checkbox
						name="policies"
						disabled={!enabled}
						onChange={(_e, checked) => handlePolicies(checked)}
					/>
					<Typography variant="body2" color={'text.secondary'}>
						Al seleccionar esta casilla, aceptas leer y estar de acuerdo
						con todos nuestros{' '}
						<Link
							href="/policies"
							target="_blank"
							variant="body2"
							color={'inherit'}>
							Términos y Políticas
						</Link>
						.
					</Typography>
				</Stack>
			</Stack>
			<Stack
				direction="row"
				spacing={1}
				padding={'16px'}
				justifyContent="center">
				<Button
					fullWidth
					variant="text"
					onClick={handleAccount}
					size="large">
					Ya tengo cuenta
				</Button>
				<Button
					fullWidth
					variant="contained"
					onClick={handleSignup}
					size="large"
					disabled={!dataIsValid() || !enabled}>
					Registrarse
				</Button>
				<BasicModal {...dialog} />
			</Stack>
		</Card>
	);
}
