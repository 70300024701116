import { StatusType } from '@values/Types';

export class Status implements StatusType {
	code: number;
	name: string;
	details?: string;

	constructor(data: any) {
		this.code = data?.code || -1;
		this.name = data?.name || '';
		this.details = data?.details;
	}
}
