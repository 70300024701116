import { useMemo } from 'react';

import FacebooIcon from '@mui/icons-material/FacebookOutlined';
import { InstagramIcon, TiktokIcon, TwitterIcon, YoutubeIcon } from './Icons';
import Logo from './Logo';

import { Link, Stack, Typography, styled } from '@mui/material';
import LinkNavigate from './LinkNavigate';
import SocialLink from './SocialLink';

import { useLargeScreen } from '@utils/Media';
import useDictionary from '@values/Dictionary.ts';

const FooterRoot = styled('footer')(({ theme }) => {
	return {
		borderTopColor: theme.palette.divider,
		borderTopWidth: '1px',
		borderTopStyle: 'solid',
		width: '100%',
		padding: '0 24px',
	};
});

/**
 *
 * Manage the footer app in diferent context.
 *
 * By Frankil A.
 *
 * @param {{
 * small: boolean
 * }} param
 * @returns
 */
export default function Footer({ small = false }) {
	const { FOOTER } = useDictionary();
	const isLarge = useLargeScreen();
	const year = useMemo(() => new Date().getFullYear(), []);

	const options = [
		{
			title: FOOTER.contact,
			navigate: false,
			link: 'mailto:contacto@kaadysport.com?subject=Hola%20Kaady%20Sport&body=Hola!',
		},
		{
			title: FOOTER.help,
			navigate: false,
			link: 'mailto:contacto@kaadysport.com?subject=Ayuda%20Kaady%20Sport&body=Hola,%20tengo%20un%20problema%20con...',
		},
		{ title: FOOTER.about, link: '/about', navigate: true },
		{ title: FOOTER.terms, link: '/policies', navigate: true },
		{ title: FOOTER.policies, link: '/policies', navigate: true },
	];
	const socials = [
		{
			name: 'Facebook',
			link: 'https://www.facebook.com/KaadySport/',
			icon: <FacebooIcon fontSize="large" />,
		},
		{
			name: 'Instagram',
			link: 'https://www.instagram.com/kaadysport/',
			icon: <InstagramIcon fontSize="large" />,
		},
		{
			name: 'X',
			link: 'https://twitter.com/kaadysport',
			icon: <TwitterIcon fontSize="large" />,
		},
		{
			name: 'Youtube',
			link: 'https://www.youtube.com/@kaadysport',
			icon: <YoutubeIcon fontSize="large" />,
		},
		{
			name: 'Tiktok',
			link: 'https://www.tiktok.com/@kaady.sport',
			icon: <TiktokIcon fontSize="large" />,
		},
	];

	const Badges = () => (
		<Stack
			direction={'column'}
			alignItems={isLarge ? 'start' : 'center'}
			spacing={1}>
			<Stack direction={'row'} spacing={1}>
				<a href=".">
					<img
						src="/badges/google-play-badge-es-lat.png"
						alt="Google Play"
						height={'40px'}
					/>
				</a>
				<a href=".">
					<img
						src="/badges/app_store_badge_es_mx.png"
						alt="App Store"
						height={'40px'}
					/>
				</a>
			</Stack>
			<Stack
				direction={'column'}
				alignItems={isLarge ? 'start' : 'center'}
				spacing={0.5}>
				<Logo height="24px" />
				<Typography
					variant="caption"
					color={(t) => t.palette.text.secondary}>
					© Kaady Sport S.A. de C.V. México, {year}
				</Typography>
			</Stack>
		</Stack>
	);

	// Social links
	const socialLinks = socials.map((link, index) => (
		<SocialLink
			key={index}
			title={link.name}
			href={link.link}
			target="_blank"
			rel="noreferrer">
			{link.icon}
		</SocialLink>
	));

	// Options links
	const optionLinks = options.map((link, index) =>
		link.navigate ? (
			<LinkNavigate
				key={index}
				underline="none"
				color="text.secondary"
				variant="body2"
				href={link.link}
				sx={{
					':hover': {
						opacity: 0.78,
					},
				}}>
				{link.title}
			</LinkNavigate>
		) : (
			<Link
				key={index}
				underline="none"
				color="text.secondary"
				variant="body2"
				href={link.link}
				sx={{
					':hover': {
						opacity: 0.78,
					},
				}}>
				{link.title}
			</Link>
		)
	);

	if (small) {
		return (
			<FooterRoot>
				<Stack
					sx={{
						flexDirection: isLarge ? 'row' : 'column-reverse',
						gap: isLarge ? '16px' : '24px',
						padding: '24px 0',
						margin: '0 auto',
						maxWidth: '880px',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					<Stack direction={'row'} spacing={0.5} alignItems={'center'}>
						<Logo height="24px" type="icon" />
						<Typography
							variant="caption"
							lineHeight={1}
							color={(t) => t.palette.text.secondary}>
							© Kaady Sport S.A. de C.V. México, {year}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap={'24px'}
						flexWrap={'wrap'}
						justifyContent={'center'}>
						{optionLinks}
					</Stack>
				</Stack>
			</FooterRoot>
		);
	}

	return (
		<FooterRoot>
			<Stack
				sx={{
					flexDirection: isLarge ? 'row' : 'column-reverse',
					gap: '16px',
					padding: '24px 0',
					margin: '0 auto',
					maxWidth: '880px',
				}}>
				<Badges />
				<Stack
					direction={'column'}
					spacing={isLarge ? 4 : 3}
					flexGrow={1}
					paddingBottom={!isLarge ? '24px' : ''}
					alignItems={isLarge ? 'end' : 'center'}>
					<Stack
						justifyContent={'center'}
						direction={'row'}
						gap={'16px'}
						flexWrap={'wrap'}>
						{socialLinks}
					</Stack>
					<Stack
						justifyContent={'center'}
						direction={'row'}
						gap={'24px'}
						flexWrap={'wrap'}>
						{optionLinks}
					</Stack>
				</Stack>
			</Stack>
		</FooterRoot>
	);
}
