import { styled } from '@mui/material';

const Image = styled('img')({
	flexGrow: 1,
	width: '100%',
	maxHeight: '250px',
	objectFit: 'contain',
});

export default Image;
