// Definiciones
export type FooterRes = {
	contact: string;
	help: string;
	about: string;
	terms: string;
	policies: string;
};

export type FooterLang = {
	es?: FooterRes;
	en?: FooterRes;
};

// Traducciones
export const FooterDictionary: FooterLang = {
	es: {
		contact: 'Contacto',
		help: 'Ayuda',
		about: 'Nosotros',
		terms: 'Términos',
		policies: 'Políticas',
	},
};
