import BasicModal from '@components/BasicModal';
import {
	PanelAction,
	PanelCard,
	PanelContent,
	PanelHeader,
} from '@components/Panel';
import { Center } from '@models/Center.ts';
import { DataImage } from '@models/DataImage.ts';
import {
	AddOutlined,
	CheckCircleOutlined,
	CloseOutlined,
	CloudDoneOutlined,
	CloudOffOutlined,
	DeleteOutline,
	FileUploadOutlined,
	RemoveCircleOutline,
	SaveOutlined,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	ImageList,
	ImageListItem,
	LinearProgress,
	Stack,
	Tooltip,
	Typography,
	alpha,
	styled,
} from '@mui/material';
import { deleteOneImage, updateCenterImages } from '@utils/Centers';
import { useCallback, useEffect, useRef, useState } from 'react';

const CenterImage = styled('img')(({ theme, src }) => ({
	display: src ? '' : 'none',
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	borderRadius: '16px',
	overflow: 'hidden',
	border: `1px solid ${theme.palette.divider}`,
}));

const ImageContainer = styled(Stack)(({ theme, children }) => ({
	backgroundPosition: 'center',
	backgroundColor: alpha(theme.palette.primary.main, 0.18),
	backgroundImage: children ? '' : `url('/icons/kaady_light_foreground.png')`,
	backgroundRepeat: 'no-repeat',
	width: '200px',
	height: '200px',
	borderRadius: '16px',
	overflow: 'hidden',
	objectFit: 'cover',
}));

function ConfirmDeleteImageDialog({
	open = false,
	url = '',
	onClose = (res) => {},
}) {
	return (
		<Dialog keepMounted fullWidth maxWidth="xs" open={open}>
			<DialogTitle>¿Desea eliminar esta imagen?</DialogTitle>
			<DialogContent>
				<Stack gap={'16px'}>
					<CenterImage src={url} sx={{ height: '200px' }} />
					<Typography>
						Esta imagen está en el servidor, si continua no la podrá
						recuperar. ¿Desea eliminar la imagen?
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose(false)}>Cancelar</Button>
				<Button color="error" onClick={() => onClose(true)}>
					Eliminar
				</Button>
			</DialogActions>
		</Dialog>
	);
}

/**
 * Edit center images
 * @param {Object} param0
 * @param {import('@values/Types').CenterType} [param0.item]
 * @param {() => void} [param0.onClose]
 * @param {()=> void} [param0.onSaved]
 * @returns
 */
export default function CenterImages({
	item = {},
	onClose = () => {},
	onSaved = () => {},
}) {
	const startData = useCallback(
		(item) => ({
			id: item.id,
			name: item.name || 'Sin nombre',
			picture: { ...item.picture },
			portrait: { ...item.portrait },
			images: [...item.images],
		}),
		[]
	);

	const fileInputRef = useRef(null);
	const [enabled, setEnabled] = useState(true);
	const [data, setData] = useState(startData(item));
	const [modal, setModal] = useState({
		open: false,
		title: '',
		message: '',
		onClose: () => setModal({ ...modal, open: false }),
	});
	const [confirm, setConfirm] = useState({
		open: false,
		url: '',
		onClose: (response) => {},
	});
	const [select, setSelect] = useState({
		picture: false,
		portrait: false,
		images: false,
	});

	useEffect(() => {
		setData(startData(item));
	}, [startData, item]);

	const showConfirmDialog = (url) => {
		return new Promise((resolve) => {
			setConfirm({
				open: true,
				url: url,
				onClose: (response) => {
					setConfirm({ ...confirm, open: false });
					resolve(response);
				},
			});
		});
	};

	/**
	 * Return true for delete action confirm
	 * @returns {Promise<boolean>}
	 */
	const deleteIfExistInServer = async (field, index = -1) => {
		let image = data[field];
		let actual = {
			id: data.id,
			[field]: true,
		};
		if (index !== -1 && field === 'images') {
			image = data.images[index];
			actual = {
				...actual,
				image: index,
			};
		}
		if (image.url !== '' && !image.file) {
			const res = await showConfirmDialog(image.url);
			if (res) {
				await deleteOneImage(actual);
				onSaved();
			}
			return res;
		}
		return true;
	};

	const handleUploadClick = () => {
		fileInputRef.current.click();
	};

	const handleDeleteClick =
		(field, index = -1) =>
		async () => {
			if (index !== -1 && field === 'images') {
				// Verificar si existe
				if (!(await deleteIfExistInServer(field, index))) return;

				const values = [...data.images];
				values.splice(index, 1);
				setData((prev) => ({
					...prev,
					images: values,
				}));
			} else {
				// Verificar si existe
				if (!(await deleteIfExistInServer(field))) return;

				setData((prev) => ({
					...prev,
					[field]: new DataImage(),
				}));
			}
		};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const value = new DataImage();
			value.file = file;
			value.url = URL.createObjectURL(file);

			if (select.picture) {
				setData((prev) => ({
					...prev,
					picture: value,
				}));
				setSelect({ picture: false });
			}
			if (select.portrait) {
				setData((prev) => ({
					...prev,
					portrait: value,
				}));
				setSelect({ portrait: false });
			}
			if (select.images) {
				setData((prev) => ({
					...prev,
					images: [...prev.images, value],
				}));
				setSelect({ images: false });
			}
		}
		if (fileInputRef.current) {
			fileInputRef.current.value = null;
		}
	};

	const handleUpdate = async () => {
		setEnabled(false);

		// Manejar archivos y datos
		const multi = new FormData();
		multi.append('id', data.id);
		if (data.picture.file) {
			multi.append('picture', data.picture.file);
		}
		if (data.portrait.file) {
			multi.append('portrait', data.portrait.file);
		}
		if (data.images.length > 0) {
			data.images.forEach((image) => {
				if (image.file) {
					multi.append('images[]', image.file);
				} else {
					multi.append('images_prev[]', JSON.stringify(image));
				}
			});
		}

		// Enviar petición
		const res = await updateCenterImages(multi);
		// Show errors
		if (res.status !== 200) {
			const errors = res.data?.errors ? Object.values(res.data?.errors) : [];
			setModal({
				...modal,
				open: true,
				title: res.data?.message || 'Ha ocurrido un error',
				message:
					errors.length > 0
						? errors[0]
						: 'No fue posible subir las imágenes. Intenta de nuevo más tarde.',
			});
		} else {
			onSaved();
			setData(startData(new Center(res.data?.center)));
		}
		setEnabled(true);
	};

	return (
		<PanelCard>
			<PanelContent>
				<PanelHeader>
					<Stack
						flexGrow={1}
						sx={{
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}>
						<Typography variant="h6" component={'h1'}>
							{data.name}
						</Typography>
						<Typography variant="caption" color={'text.secondary'}>
							Imágenes y recursos
						</Typography>
					</Stack>
					<IconButton title="Cerrar" onClick={onClose}>
						<CloseOutlined />
					</IconButton>
					{!enabled && (
						<Box
							sx={{
								width: '100%',
								position: 'absolute',
								bottom: 0,
								left: 0,
							}}>
							<LinearProgress />
						</Box>
					)}
				</PanelHeader>
				<Stack flexGrow={1} component={'form'}>
					<Stack padding={'16px'} gap={'8px'}>
						<Typography fontWeight={'bold'} variant="subtitle1">
							Requisitos de imagen
						</Typography>
						<Stack direction={'row'} gap={'8px'} alignItems={'center'}>
							<CheckCircleOutlined />
							<Typography>
								Puedes cargar hasta un máximo de 10 fotos, contando
								foto de perfil y portada.
							</Typography>
						</Stack>
						<Stack direction={'row'} gap={'8px'} alignItems={'center'}>
							<CheckCircleOutlined />
							<Typography>
								Verifica el tipo de archivo de imagen, debe ser PNG,
								JPG o JPEG.
							</Typography>
						</Stack>
						<Stack direction={'row'} gap={'8px'} alignItems={'center'}>
							<CheckCircleOutlined />
							<Typography>
								El peso máximo por cada imagen es de 5MB, comprime o
								recorta tus imagenes.
							</Typography>
						</Stack>
					</Stack>
					<input
						title="upload-file"
						type="file"
						accept=".png,.jpg,.jpeg"
						ref={fileInputRef}
						style={{ display: 'none' }}
						onChange={handleFileChange}
					/>
					<Stack padding={'16px'} gap={'8px'}>
						<Stack
							direction={'row'}
							gap={'8px'}
							justifyContent={'center'}>
							<Typography variant="subtitle1" fontWeight={'bold'}>
								Foto de perfil
							</Typography>
							{data.picture.file ? (
								<CloudOffOutlined color="secondary" />
							) : (
								data.picture.url !== '' && (
									<CloudDoneOutlined color="success" />
								)
							)}
						</Stack>
						<Stack width={'100%'} alignItems={'center'} gap={'8px'}>
							<ImageContainer>
								{data.picture.url !== '' && (
									<CenterImage
										src={data.picture.url}
										alt="Foto de perfil"
									/>
								)}
							</ImageContainer>
							<Stack direction={'row'} gap={'16px'}>
								<Tooltip title="Subir imagen">
									<IconButton
										disabled={!enabled}
										onClick={() => {
											setSelect({ picture: true });
											handleUploadClick();
										}}>
										<FileUploadOutlined />
									</IconButton>
								</Tooltip>
								{data.picture.url !== '' && (
									<Tooltip title="Eliminar imagen">
										<IconButton
											disabled={!enabled}
											onClick={handleDeleteClick('picture')}>
											{data.picture.file ? (
												<RemoveCircleOutline />
											) : (
												<DeleteOutline />
											)}
										</IconButton>
									</Tooltip>
								)}
							</Stack>
						</Stack>
					</Stack>
					<Stack padding={'16px'} gap={'8px'}>
						<Stack
							direction={'row'}
							gap={'8px'}
							justifyContent={'center'}>
							<Typography variant="subtitle1" fontWeight={'bold'}>
								Foto de portada
							</Typography>
							{data.portrait.file ? (
								<CloudOffOutlined color="secondary" />
							) : (
								data.portrait.url !== '' && (
									<CloudDoneOutlined color="success" />
								)
							)}
						</Stack>
						<Stack width={'100%'} alignItems={'center'} gap={'8px'}>
							<ImageContainer sx={{ width: '100%', height: '250px' }}>
								{data.portrait.url !== '' && (
									<CenterImage
										src={data.portrait.url}
										alt="Foto de portada"
									/>
								)}
							</ImageContainer>
							<Stack direction={'row'} gap={'16px'}>
								<Tooltip title="Subir imagen">
									<IconButton
										disabled={!enabled}
										onClick={() => {
											setSelect({ portrait: true });
											handleUploadClick();
										}}>
										<FileUploadOutlined />
									</IconButton>
								</Tooltip>
								{data.portrait.url !== '' && (
									<Tooltip title="Eliminar imagen">
										<IconButton
											disabled={!enabled}
											onClick={handleDeleteClick('portrait')}>
											{data.portrait.file ? (
												<RemoveCircleOutline />
											) : (
												<DeleteOutline />
											)}
										</IconButton>
									</Tooltip>
								)}
							</Stack>
						</Stack>
					</Stack>
					<Stack padding={'16px 16px 48px 16px'} gap={'8px'}>
						<Typography fontWeight={'bold'} variant="subtitle1">
							Imágenes del centro
						</Typography>
						<ImageList sx={{ margin: 0 }} gap={8} rowHeight={200}>
							{data.images.map((item, index) => (
								<ImageListItem key={item.url}>
									<CenterImage src={item.url} alt={item.url} />
									<Stack
										padding={'4px 8px'}
										direction={'row'}
										gap={'16px'}
										alignItems={'center'}>
										<Stack
											direction={'row'}
											gap={'8px'}
											alignItems={'center'}
											flexGrow={1}>
											<Typography variant="body1">
												Imagen {index + 1}
											</Typography>
											{item.file ? (
												<CloudOffOutlined color="secondary" />
											) : (
												<CloudDoneOutlined color="success" />
											)}
										</Stack>
										<Tooltip
											title="Eliminar imagen"
											placement="left">
											<IconButton
												disabled={!enabled}
												onClick={handleDeleteClick(
													'images',
													index
												)}>
												{item.file ? (
													<RemoveCircleOutline />
												) : (
													<DeleteOutline />
												)}
											</IconButton>
										</Tooltip>
									</Stack>
								</ImageListItem>
							))}
							{data.images.length < 8 && (
								<ImageListItem
									sx={{
										paddingBottom: '48px',
									}}>
									<Stack
										sx={(t) => ({
											backgroundColor: alpha(
												t.palette.primary.main,
												0.18
											),
											minHeight: '152px',
											borderRadius: '16px',
											alignItems: 'center',
											justifyContent: 'center',
										})}>
										<Tooltip title="Nueva imagen">
											<IconButton
												disabled={!enabled}
												onClick={() => {
													setSelect({ images: true });
													handleUploadClick();
												}}>
												<AddOutlined fontSize="large" />
											</IconButton>
										</Tooltip>
									</Stack>
								</ImageListItem>
							)}
						</ImageList>
					</Stack>
				</Stack>
				<PanelAction>
					<Button
						variant="contained"
						sx={{
							padding: '8px 16px',
							lineHeight: 'normal',
						}}
						disabled={!enabled}
						onClick={handleUpdate}
						startIcon={<SaveOutlined />}>
						Guardar
					</Button>
				</PanelAction>
			</PanelContent>
			<BasicModal {...modal} />
			<ConfirmDeleteImageDialog {...confirm} />
		</PanelCard>
	);
}
