import { LocationType, PointType, ZoneType } from '@values/Types';
import { Point } from './Point.ts';
import { Zone } from './Zone.ts';

export class Location implements LocationType {
	id?: number;
	label: string;
	short: string;
	point: PointType;
	zone_id: number;
	center_id?: number;
	zone: ZoneType;

	constructor(data: any) {
		this.id = data?.id;
		this.label = data?.label || '';
		this.short = data?.short || '';
		this.point = new Point(data?.point);
		this.zone_id = data?.zone_id || 11;
		this.center_id = data?.center_id;
		this.zone = new Zone(data?.zone);
	}
}
