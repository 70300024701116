import Logo from '@components/Logo';
import { CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';

/**
 * Show a modal for loadings process
 * @param {Object} param0
 * @param {boolean} [param0.open=false]
 * @param {string} [param0.message='']
 * @returns
 */
export default function Loading({ open = false, message = 'Por favor, espere...' }) {
	return (
		<>
			{open && (
				<HelmetProvider>
					<Helmet>
						<title>{message}</title>
					</Helmet>
				</HelmetProvider>
			)}
			<Dialog
				open={open}
				keepMounted
				fullScreen
				PaperProps={{
					sx: {
						borderRadius: 0,
					},
				}}>
				<DialogContent
					sx={(t) => ({
						display: 'flex',
						flexDirection: 'column',
						gap: '24px',
						alignItems: 'center',
						justifyContent: 'center',
					})}>
					<Logo
						type="square"
						height="calc(100vw - 200px)"
						maxHeight="280px"
					/>
					<Typography variant="h6" component={'h1'}>
						{message}
					</Typography>
					<CircularProgress />
				</DialogContent>
			</Dialog>
		</>
	);
}
