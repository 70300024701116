import { Check } from '@mui/icons-material';
import { Button, Stack, Typography, alpha, styled } from '@mui/material';
import { useState } from 'react';

const ListMenuItem = styled(Button)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'start',
	padding: '12px 16px',
	borderRadius: '16px',
	gap: '16px',
	color: theme.palette.text.secondary,
	'&.selected': {
		backgroundColor: alpha(theme.palette.primary.main, 0.21),
	},
}));

/**
 * A custom menu like a radiogroup
 * @param {{
 * options: string[],
 * selected: number,
 * onChange: (index: number)=>void
 * }} param
 */
export default function ListMenu({
	options = [],
	selected = -1,
	onChange = () => {},
}) {
	const [select, setSelect] = useState(selected);
	const handleClick = (index) => {
		setSelect(index);
		onChange(index);
	};

	if (options.length === 0) return;
	return (
		<Stack padding={'16px'} gap={'2px'}>
			{options.map((value, index) => (
				<ListMenuItem
					key={index}
					className={index === select ? 'selected' : ''}
					onClick={() => handleClick(index)}>
					<Stack width={'24px'} height={'24px'} className="inset">
						{index === select && <Check />}
					</Stack>
					<Typography variant="body1">{value}</Typography>
				</ListMenuItem>
			))}
		</Stack>
	);
}
