import { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Section from './Section';
import Image from './Image';
import { Stack, Typography } from '@mui/material';
import { useLargeScreen } from '@utils/Media';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Offline({ children }) {
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const isLarge = useLargeScreen();

	useEffect(() => {
		const handleOnline = () => setIsOnline(true);
		const handleOffline = () => setIsOnline(false);

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	if (!isOnline) {
		return (
			<>
				<HelmetProvider>
					<Helmet>
						<title>Sin conexión a internet</title>
					</Helmet>
				</HelmetProvider>
				<Navbar showOptions={false} />
				<Section component={'section'}>
					<Stack
						direction={'column'}
						gap={'16px'}
						textAlign={!isLarge ? 'center' : ''}>
						<Typography variant={isLarge ? 'h3' : 'h4'} component={'h1'}>
							Sin conexión
						</Typography>
						<Typography variant="h6" color={'text.secondary'}>
							Estás desconectado, revisa tu conexión a internet y
							recarga la página.
						</Typography>
					</Stack>
					<Image src="/images/kaady_offline.png" alt="Sin conexión" />
				</Section>
			</>
		);
	}

	return children;
}
