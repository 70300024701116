import { Stack, Typography } from '@mui/material';
import Image from './Image';

export default function Developing() {
	return (
		<Stack
			width={'100%'}
			padding={'36px 24px'}
			gap={'16px'}
			alignItems={'center'}>
			<Image src="/images/kaady_updating_1.png" />
			<Typography variant="h6" color={'text.secondary'}>
				Opción en actualización
			</Typography>
		</Stack>
	);
}
