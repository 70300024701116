import MarkerMap from '@components/MarkerMap';
import {
	PanelAction,
	PanelCard,
	PanelContent,
	PanelHeader,
} from '@components/Panel';
import { Location } from '@models/Location.ts';
import { Point } from '@models/Point.ts';
import { Zone } from '@models/Zone.ts';
import { CloseOutlined, SaveOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	FormControl,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { updateCenterLocation } from '@utils/Centers';
import { getZonesFromStorage } from '@utils/Zones';
import { MAP_PROPS } from '@values/Default';
import { useCallback, useEffect, useState } from 'react';

/**
 * @param {Object} param0
 * @param {import('@values/Types').CenterType} [param0.item]
 * @param {() => void} [param0.onClose]
 * @param {()=> void} [param0.onSaved]
 * @returns
 */
export default function CenterLocation({
	item = {},
	onClose = () => {},
	onSaved = () => {},
}) {
	const startData = useCallback(
		(item) => ({
			id: item.id,
			name: item.name || 'Sin nombre',
			locations:
				item.locations.length > 0
					? item.locations.map((value) => new Location(value))
					: [new Location()],
		}),
		[]
	);

	const [enabled, setEnabled] = useState(true);
	const [reload, setReload] = useState(true);
	const [data, setData] = useState(startData(item));
	const [zones, setZones] = useState([]);
	const [regions, setRegions] = useState([]);

	useEffect(() => {
		setData(startData(item));
		setReload(true);
	}, [startData, item]);

	useEffect(() => {
		const loadZones = async () => {
			const res = await getZonesFromStorage();
			setZones(res || []);
			if (res) {
				const list = res.map((value) => value.region);
				setRegions([...new Set(list)]);
			}
		};
		loadZones();
	}, []);

	const handleZoneChange = (field) => (e) => {
		const zone = zones.filter((value) => value[field] === e.target.value)[0];
		const value = [...data.locations];
		value[0].zone = new Zone(zone);
		value[0].zone_id = zone.id;
		setData((prev) => ({
			...prev,
			locations: value,
		}));
	};

	const handleAddressChange = (e) => {
		const { value, name } = e.target;
		const value_location = [...data.locations];
		value_location[0][name] = value;

		setData((prev) => ({
			...prev,
			locations: value_location,
		}));
	};

	const handlePointChange = (e) => {
		const { value, name } = e.target;
		const value_location = [...data.locations];
		value_location[0].point[name] = value;

		setData((prev) => ({
			...prev,
			locations: value_location,
		}));
	};

	const handleMapChange = (value) => {
		const value_location = [...data.locations];
		value_location[0].point = new Point(value);
		setData((prev) => ({
			...prev,
			locations: value_location,
		}));
		setReload(false);
	};

	const handleUpdate = async () => {
		setEnabled(false);
		setReload(false);
		const values = [...data.locations];
		values[0].point.lat = !isNaN(parseFloat(values[0].point.lat))
			? parseFloat(values[0].point.lat)
			: MAP_PROPS.center.lat;
		values[0].point.lng = !isNaN(parseFloat(values[0].point.lng))
			? parseFloat(values[0].point.lng)
			: MAP_PROPS.center.lng;
		setData((prev) => ({
			...prev,
			locations: values,
		}));
		await updateCenterLocation(data);
		onSaved();
		setEnabled(true);
		setReload(true);
	};

	const filteredZones = zones.filter(
		(value) => value.region === data.locations[0].zone.region
	);

	return (
		<PanelCard>
			<PanelContent>
				<PanelHeader>
					<Stack flexGrow={'1'}>
						<Typography variant="h6" component={'h1'}>
							{data.name}
						</Typography>
						<Typography variant="caption" color={'text.secondary'}>
							Ubicación del centro
						</Typography>
					</Stack>
					<IconButton title="Cerrar" onClick={onClose}>
						<CloseOutlined />
					</IconButton>
					{!enabled && (
						<Box
							sx={{
								width: '100%',
								position: 'absolute',
								bottom: 0,
								left: 0,
							}}>
							<LinearProgress />
						</Box>
					)}
				</PanelHeader>
				<Stack flexGrow={1} component={'form'} autoComplete="off">
					<Stack padding={'16px'} gap={'8px'}>
						<Typography fontWeight={'bold'} variant="subtitle1">
							Zona de ubicación
						</Typography>
						<Stack direction={'row'} gap={'8px'}>
							<FormControl
								disabled={!enabled}
								variant="filled"
								sx={{ minWidth: 150 }}>
								<InputLabel>Región</InputLabel>
								<Select
									MenuProps={{
										MenuListProps: {
											sx: {
												maxHeight: '200px',
												overflowY: 'auto',
											},
										},
									}}
									value={data.locations[0].zone.region}
									onChange={handleZoneChange('region')}>
									{regions.length === 0 && (
										<MenuItem
											value={data.locations[0].zone.region}>
											{data.locations[0].zone.region}
										</MenuItem>
									)}
									{regions.map((value, index) => (
										<MenuItem key={index} value={value}>
											{value}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl
								disabled={!enabled}
								variant="filled"
								fullWidth>
								<InputLabel>Zona</InputLabel>
								<Select
									MenuProps={{
										MenuListProps: {
											sx: {
												maxHeight: '200px',
												overflowY: 'auto',
											},
										},
									}}
									value={data.locations[0].zone.name}
									onChange={handleZoneChange('name')}>
									{filteredZones.length === 0 && (
										<MenuItem
											value={data.locations[0].zone.name}>
											{data.locations[0].zone.name}
										</MenuItem>
									)}
									{filteredZones.map((value, index) => (
										<MenuItem key={index} value={value.name}>
											{value.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Stack>
					</Stack>
					<Stack padding={'16px'} gap={'8px'}>
						<Typography fontWeight={'bold'} variant="subtitle1">
							Dirección completa
						</Typography>
						<Stack direction={'row'} gap={'8px'} flexWrap={'wrap'}>
							<TextField
								fullWidth
								variant="filled"
								label="Dirección "
								name="label"
								autoComplete="off"
								disabled={!enabled}
								value={data.locations[0].label}
								onChange={handleAddressChange}
							/>
							<TextField
								sx={{
									flex: '1 0 200px',
								}}
								variant="filled"
								label="Latitud"
								name="lat"
								disabled={!enabled}
								value={data.locations[0].point.lat}
								onChange={handlePointChange}
							/>
							<TextField
								sx={{
									flex: '1 0 200px',
								}}
								variant="filled"
								label="Longitud"
								name="lng"
								disabled={!enabled}
								value={data.locations[0].point.lng}
								onChange={handlePointChange}
							/>
						</Stack>
					</Stack>
					<Stack padding={'16px'} gap={'8px'}>
						<Typography fontWeight={'bold'} variant="subtitle1">
							Ubicación en el mapa
						</Typography>
						<Card
							variant="outlined"
							sx={{
								width: '100%',
								height: '320px',
							}}>
							<MarkerMap
								disabled={!enabled}
								reload={reload}
								center={data.locations[0].point}
								onChange={handleMapChange}
							/>
						</Card>
					</Stack>
				</Stack>
				<PanelAction>
					<Button
						variant="contained"
						sx={{
							padding: '8px 16px',
							lineHeight: 'normal',
						}}
						onClick={handleUpdate}
						disabled={!enabled}
						startIcon={<SaveOutlined />}>
						Guardar
					</Button>
				</PanelAction>
			</PanelContent>
		</PanelCard>
	);
}
