import Navbar from '@components/Navbar';
import { Panel } from '@components/Panel';
import { Sidebar } from '@components/Sidebar';
import {
	CardMembershipOutlined,
	FitnessCenterOutlined,
	PersonOutline,
	QueryStatsOutlined,
	StoreOutlined,
	WysiwygOutlined,
} from '@mui/icons-material';
import { Drawer, Stack, alpha } from '@mui/material';
import { useLargeScreen } from '@utils/Media';
import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function AdminHome() {
	const base = '/admin';
	const options = [
		{ label: 'Resumen', to: `${base}`, icon: <QueryStatsOutlined /> },
		{
			label: 'Membresías',
			to: `${base}/memberships`,
			icon: <CardMembershipOutlined />,
		},
		{
			label: 'Centros',
			to: `${base}/centers`,
			icon: <FitnessCenterOutlined />,
		},
		{
			label: 'Tiendas',
			to: `${base}/stores`,
			icon: <StoreOutlined />,
		},
		{
			label: 'Actividades',
			to: `${base}/activities`,
			icon: <WysiwygOutlined />,
		},
		{
			label: 'Usuarios',
			to: `${base}/users`,
			icon: <PersonOutline />,
		},
	];

	const location = useLocation();
	const startIndex = options.findIndex((value) => value.to === location.pathname);
	const [index, setIndex] = useState(startIndex === -1 ? 0 : startIndex);
	const [open, setOpen] = useState(false);
	const isLarge = useLargeScreen();
	const navigate = useNavigate();
	const onChange = (i) => {
		setIndex(i);
		setOpen(false);
		navigate(options[i].to);
	};

	return (
		<Stack
			component={'main'}
			width={'100%'}
			height={'100vh'}
			sx={(t) => ({
				backgroundColor: alpha(t.palette.primary.main, 0.05),
			})}>
			<>
				<HelmetProvider>
					<Helmet>
						<title>Panel de administración</title>
					</Helmet>
				</HelmetProvider>
				<Navbar isAuth base={base} onDrawer={() => setOpen(true)} />
				<Panel component={'section'}>
					{isLarge && (
						<Sidebar
							index={index}
							onChange={onChange}
							header="Administrador"
							options={options}
						/>
					)}
					<Outlet />
				</Panel>
				<Drawer open={open} onClose={() => setOpen(false)}>
					<Sidebar
						index={index}
						onChange={onChange}
						header="Administrador"
						options={options}
					/>
				</Drawer>
			</>
		</Stack>
	);
}
