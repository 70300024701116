import { Check } from '@mui/icons-material';
import { Button, Typography, alpha, styled } from '@mui/material';
import { useState } from 'react';

const ChipRoot = styled(Button, {
	name: 'MuiChip3',
	slot: 'root',
})(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'start',
	alignSelf: 'center',
	minWidth: 'auto',
	height: 'fit-content',
	whiteSpace: 'nowrap',
	borderRadius: '50px',
	padding: '6px 12px',
	gap: '6px',
	border: `1px solid ${theme.palette.divider}`,
	color: theme.palette.text.secondary,
	'&.checked': {
		padding: '7px 12px 7px 8px',
		border: 'none',
		background: alpha(theme.palette.primary.main, 0.28),
		color: theme.palette.text.primary,
	},
}));

/**
 *
 * @param {{
 * label: string,
 * checked: boolean,
 * onChange: (checked: boolean)=> void,
 * props: import('@mui/material').ButtonBaseProps
 * }} param
 * @returns
 */
export default function Chip({
	label = 'Enabled',
	checked = false,
	onChange = () => {},
	...props
}) {
	const [check, setCheck] = useState(checked);
	const handleClick = () => {
		setCheck((c) => {
			onChange(!c);
			return !c;
		});
	};

	return (
		<ChipRoot
			{...props}
			className={check ? 'checked' : ''}
			onClick={handleClick}>
			{check && <Check fontSize="small" />}
			<Typography color={'inherit'}>{label}</Typography>
		</ChipRoot>
	);
}
