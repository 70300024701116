// Components
import Logo from './Logo';

import {
	AccountCircleOutlined,
	LogoutOutlined,
	MenuOutlined,
	PolicyOutlined,
	SecurityOutlined,
	SettingsOutlined,
} from '@mui/icons-material';

// Material design
import {
	Avatar,
	Button,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
	styled,
} from '@mui/material';

// Icons
import MoreIcon from '@mui/icons-material/MoreVertOutlined';

// React
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useState } from 'react';

import LinkNavigate from './LinkNavigate';
import useDictionary from '@values/Dictionary.ts';
import { useLargeScreen } from '@utils/Media';

const NavRoot = styled('nav')(({ theme }) => {
	return {
		backgroundColor: theme.palette.background.default,
		zIndex: 20,
		position: 'relative',
		'&.top': {
			top: '0px',
			position: 'sticky',
			boxShadow:
				'0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.15)',
		},
	};
});

// eslint-disable-next-line
const HeaderRoot = styled('header')(() => {
	return {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '8px 16px',
	};
});

const StyledLink = styled(Link)({
	lineHeight: 1,
	display: 'flex',
	flexDirection: 'row',
	gap: '4px',
	alignItems: 'center',
});

/**
 * Manage the navbar for diferents context.
 *
 * By Frankil A.
 * 2024
 *
 * @param {{
 * base: string,
 * bgColor: string,
 * isFixed: boolean,
 * showOptions: boolean,
 * isAuth: boolean,
 * onDrawer: ()=>void,
 * }} param
 * @returns
 */
function Navbar({
	base = '',
	bgColor = '',
	isFixed = true,
	showOptions = true,
	isAuth = false,
	onDrawer = () => {},
}) {
	const { NAVBAR } = useDictionary();
	const [anchorDef, setAnchorDef] = useState(null);
	const [anchorAuth, setAnchorAuth] = useState(null);
	const [classNav, setClassNav] = useState('');
	const isLarge = useLargeScreen();
	const theme = useTheme();
	const navigate = useNavigate();
	const handleAnchorDef = (event) => {
		setAnchorDef(event.currentTarget);
	};
	const handleAnchorAuth = (event) => {
		setAnchorAuth(event.currentTarget);
	};
	const handleCloseDef = () => {
		setAnchorDef(null);
	};
	const handleCloseAuth = () => {
		setAnchorAuth(null);
	};
	const handleStart = () => {
		navigate('/login');
	};
	const options = [
		{ title: NAVBAR.partners, link: '/partners' },
		{ title: NAVBAR.memberships, link: '/memberships' },
		{ title: NAVBAR.activities, link: '/activities' },
		{ title: NAVBAR.centers, link: '/centers' },
	];
	const authOptions = [
		{
			icon: <AccountCircleOutlined />,
			label: 'Ver cuenta',
			to: `${base}/account`,
			divider: false,
		},
		{
			icon: <SecurityOutlined />,
			label: 'Seguridad',
			to: `${base}/security`,
			divider: false,
		},
		{
			divider: true,
		},
		{
			icon: <PolicyOutlined />,
			label: 'Terminos y condiciones',
			to: '/policies',
			divider: false,
		},
		{
			icon: (
				<Logo
					color={theme.palette.action.active}
					type="icon"
					height="24px"
				/>
			),
			label: 'Acerca de',
			to: '/about',
			divider: false,
		},
		{
			divider: true,
		},
		{
			icon: <SettingsOutlined />,
			label: 'Ajustes',
			to: `${base}/settings`,
			divider: false,
		},
		{
			icon: <LogoutOutlined />,
			label: 'Cerrar sesion',
			to: `${base}/logout`,
			divider: false,
		},
	];
	const linkItems = options.map((link, index) => (
		<LinkNavigate
			key={index}
			underline="none"
			title={link.title}
			href={link.link}
			sx={(t) => ({
				cursor: 'pointer',
				color: t.palette.text.secondary,
				padding: '6px 12px',
				':hover': {
					opacity: 0.78,
				},
			})}>
			{link.title}
		</LinkNavigate>
	));
	const menuItems = options.map((link, index) => (
		<MenuItem
			key={index}
			onClick={() => {
				handleCloseDef();
				navigate(link.link);
			}}>
			<Typography color="text.secondary">{link.title}</Typography>
		</MenuItem>
	));

	if (isFixed && !isAuth) {
		document.body.onscroll = () => {
			if (document.body.getBoundingClientRect().top < 0) {
				setClassNav('top');
			} else {
				setClassNav('');
			}
		};
	}

	if (isAuth) {
		return (
			<NavRoot sx={{ background: 'none' }}>
				<Stack
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						padding: isLarge ? '0 24px' : '0 16px',
						height: isLarge ? '70px' : '56px',
						gap: '16px',
					}}>
					<IconButton
						onClick={() => onDrawer()}
						sx={{
							display: isLarge ? 'none' : '',
						}}>
						<MenuOutlined />
					</IconButton>
					<StyledLink
						title={NAVBAR.go_start}
						to={base}
						sx={{
							height: isLarge ? '40px' : '28px',
							':hover': {
								opacity: 0.78,
							},
						}}>
						<Logo height={isLarge ? '40px' : '28px'} />
					</StyledLink>
					<Stack
						sx={{
							display: isLarge ? '' : 'none',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '8px',
						}}>
						<Stack direction={'row'} gap={'8px'}>
							{authOptions.slice(-2).map((item, index) => (
								<StyledLink
									to={item.to}
									key={index}
									sx={(t) => ({
										textDecoration: 'none',
										color: t.palette.text.secondary,
										padding: '4px 8px',
										':hover': {
											opacity: 0.78,
										},
									})}>
									{item.icon}
									{item.label}
								</StyledLink>
							))}
						</Stack>
						<IconButton
							onClick={handleAnchorAuth}
							aria-label={NAVBAR.see_options}
							aria-haspopup="true"
							size="small">
							<Avatar
								sx={(t) => ({
									bgcolor: t.palette.secondary.main,
								})}>
								A
							</Avatar>
						</IconButton>
					</Stack>
					<IconButton
						onClick={handleAnchorAuth}
						aria-label={NAVBAR.see_options}
						aria-haspopup="true"
						sx={{ display: !isLarge ? '' : 'none' }}
						size="small">
						<Avatar
							sx={(t) => ({
								bgcolor: t.palette.secondary.main,
							})}>
							A
						</Avatar>
					</IconButton>
					<Menu
						anchorEl={anchorAuth}
						open={Boolean(anchorAuth)}
						onClose={handleCloseAuth}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}>
						{/* <HeaderRoot>
							<Typography variant="h6">F. Aldair Pérez</Typography>
							<Typography variant="body2" color={'text.secondary'}>
								aldair11rosse@gmail.com
							</Typography>
						</HeaderRoot> */}
						{authOptions.map((item, index) =>
							item.divider ? (
								<Divider key={index} />
							) : (
								<MenuItem
									key={index}
									onClick={() => {
										handleCloseAuth();
										navigate(item.to);
									}}>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText>{item.label}</ListItemText>
								</MenuItem>
							)
						)}
					</Menu>
				</Stack>
			</NavRoot>
		);
	}

	return (
		<NavRoot
			className={classNav}
			sx={{
				background: bgColor,
			}}>
			<Stack
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: isLarge ? '0 24px' : '0 16px',
					height: isLarge ? '80px' : '56px',
					maxWidth: showOptions ? '880px' : '100%',
					margin: '0 auto',
					gap: '16px',
				}}>
				<StyledLink
					title={NAVBAR.go_start}
					to="/"
					sx={{
						height: isLarge ? '40px' : '28px',
						':hover': {
							opacity: 0.78,
						},
					}}>
					<Logo height={isLarge ? '40px' : '28px'} />
				</StyledLink>
				<Stack
					sx={{
						display: isLarge && showOptions ? '' : 'none',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '16px',
					}}>
					<Stack direction={'row'}>{linkItems}</Stack>
					<Button
						variant="contained"
						onClick={handleStart}
						disableElevation>
						{NAVBAR.start}
					</Button>
				</Stack>
				<IconButton
					sx={{
						display: !isLarge && showOptions ? '' : 'none',
					}}
					onClick={handleAnchorDef}
					aria-label={NAVBAR.see_options}
					aria-haspopup="true">
					<MoreIcon />
				</IconButton>
				<Menu
					anchorEl={anchorDef}
					open={Boolean(anchorDef)}
					onClose={handleCloseDef}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					{menuItems}
				</Menu>
			</Stack>
		</NavRoot>
	);
}

export default Navbar;
