import { useCallback, useState } from 'react';

import { Avatar, alpha } from '@mui/material';

import {
	AdvancedMarker,
	InfoWindow,
	Map,
	useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { GOOGLE_MAP_ID, MAP_PROPS } from '@values/Default';
import { capitalizeFirstLetter } from '@utils/Format';

function CustomMarker({ position, name = '', picture = '', label = '' }) {
	const [markerRef, marker] = useAdvancedMarkerRef();
	const [show, setShow] = useState(false);

	const handleClick = useCallback(() => {
		setShow((p) => !p);
	}, []);
	const handleClose = useCallback(() => {
		setShow(false);
	}, []);

	return (
		<>
			<AdvancedMarker
				ref={markerRef}
				position={position}
				onClick={handleClick}>
				<Avatar
					alt={name}
					src={picture}
					sx={(t) => ({
						border: `1px solid ${t.palette.divider}`,
						bgcolor: alpha(t.palette.primary.main, 0.72),
					})}>
					{name.charAt(0)}
				</Avatar>
			</AdvancedMarker>
			{show && (
				<InfoWindow anchor={marker} onClose={handleClose}>
					<h2
						style={{
							fontSize: '18px',
							color: '#2c2c2e',
							fontWeight: 'normal',
						}}>
						{name}
					</h2>
					<p
						style={{
							color: '#707070',
						}}>
						{label}
					</p>
				</InfoWindow>
			)}
		</>
	);
}

/**
 *
 * @param {Object} param0
 * @param {import('@values/Types').CenterType[]} param0.centers
 * @returns
 */
export default function CentersMap({ centers }) {
	const markers = centers.map((item, i) =>
		item.locations.map((location, j) => (
			<CustomMarker
				key={`${i}_${j}`}
				position={location.point}
				name={item.name}
				picture={item.picture.url}
				label={
					capitalizeFirstLetter(location.zone.name) +
					', ' +
					location.zone.region
				}
			/>
		))
	);
	return (
		<Map
			id="all-centers-map"
			reuseMaps={true}
			style={{ width: '100%', height: '100%' }}
			mapId={GOOGLE_MAP_ID}
			defaultCenter={MAP_PROPS.center}
			defaultZoom={MAP_PROPS.zoom}
			disableDefaultUI={true}
			zoomControl={true}
			clickableIcons={false}>
			{markers}
		</Map>
	);
}
