import Footer from '@components/Footer';
import Navbar from '@components/Navbar';
import FormRoot from '@components/FormRoot';
import ListMenu from '@components/ListMenu';

import { ArrowForward } from '@mui/icons-material';
import { Avatar, Button, Card, Stack, Typography, alpha } from '@mui/material';

import { requestLogout } from '@utils/Auth';
import { useLargeScreen } from '@utils/Media';
import { getUserRole } from '@utils/Roles';

import { useEffect, useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

export default function Account() {
	const isLarge = useLargeScreen();
	const [completed, setCompleted] = useState(false);
	const [roles, setRoles] = useState([]);
	const [option, setOption] = useState(0);
	const navigate = useNavigate();

	const options_es = useMemo(
		() => [
			{
				id: 1,
				option: 'Panel de administración',
				to: '/admin',
			},
			{
				id: 2,
				option: 'Base de centros',
				to: '/base-centers',
			},
			{
				id: 3,
				option: 'Aplicación de usuario',
				to: '/home',
			},
		],
		[]
	);

	useEffect(() => {
		async function initRoles() {
			const data = await getUserRole();
			if (data.length > 0) {
				const filterRoles = options_es.filter((option) =>
					data.some((item) => item.id === option.id)
				);
				if (filterRoles.length === 1) {
					navigate(filterRoles[0].to);
				}
				setRoles(filterRoles);
			} else {
				await requestLogout();
			}
			setCompleted(true);
		}
		initRoles();
	}, [options_es, navigate]);

	const handleChange = (i) => {
		setOption(i);
	};

	const handleContinue = () => {
		if (roles.length > 0) {
			navigate(roles[option].to);
		}
	};

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>Opciones asociadas a la cuenta</title>
				</Helmet>
				<Navbar showOptions={false} isFixed={false} />
				<FormRoot>
					<Card
						variant="outlined"
						sx={(t) => ({
							width: '100%',
							maxWidth: isLarge ? '380px' : '400px',
							border: isLarge ? '' : 'none',
						})}>
						<Stack
							direction={'row'}
							alignItems={'center'}
							padding={'16px 16px 8px 16px'}>
							<Typography variant="h5" component={'h1'}>
								Selecciona una opción
							</Typography>
						</Stack>
						{roles.length === 0 && (
							<>
								<Stack
									padding={'24px 16px'}
									gap={'16px'}
									alignItems={'center'}>
									<Avatar
										sx={(t) => ({
											bgcolor: alpha(
												t.palette.error.main,
												0.2
											),
											color: t.palette.error.main,
										})}>
										!
									</Avatar>
									<Typography color={'text.secondary'}>
										Tu usuario no cuenta con opciones activas o
										puede que este bloqueado temporalmente.
									</Typography>
									<Typography color={'text.secondary'}>
										Intenta comunicarte con nuestra sección de
										Ayuda o Soporte.
									</Typography>
								</Stack>
								<Stack padding={'16px 16px 24px 16px'}>
									<Button
										fullWidth
										size="large"
										onClick={() => navigate('/')}
										variant="contained">
										Ir a inicio
									</Button>
								</Stack>
							</>
						)}
						<ListMenu
							selected={0}
							options={roles.map((v) => v.option)}
							onChange={handleChange}
						/>
						{roles.length > 0 && (
							<Stack padding={'16px 16px 24px 16px'}>
								<Button
									fullWidth
									size="large"
									variant="contained"
									onClick={handleContinue}
									startIcon={<ArrowForward />}>
									Continuar
								</Button>
							</Stack>
						)}
					</Card>
					<Loading
						open={!completed}
						message="Cargando opciones de la cuenta"
					/>
				</FormRoot>
				<Footer small />
			</HelmetProvider>
		</>
	);
}
