import Section from '@components/Section';
import Banners from '@components/Banners';
import Content from '@components/Content';

import { Button, Stack, Typography } from '@mui/material';
import useDictionary from '@values/Dictionary.ts';
import { useLargeScreen } from '@utils/Media';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

export default function HomeSection() {
	const navigate = useNavigate();
	const isLarge = useLargeScreen();
	const { HOME } = useDictionary();
	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>{HOME.title}</title>
				</Helmet>
			</HelmetProvider>
			<Section component={'section'}>
				<Stack
					component={'header'}
					direction={'column'}
					gap={'36px'}
					flexGrow={1}
					width={'100%'}>
					<Stack
						direction={'column'}
						gap={'16px'}
						textAlign={!isLarge ? 'center' : ''}>
						<Typography variant={isLarge ? 'h3' : 'h4'} component={'h1'}>
							{HOME.hero_header}
						</Typography>
						<Typography variant="h6" color={'text.secondary'}>
							{HOME.hero_text}
						</Typography>
					</Stack>
					<Stack
						width={'100%'}
						direction={'row'}
						gap={'16px'}
						flexWrap={'wrap'}
						justifyContent={!isLarge ? 'center' : ''}>
						<Button
							variant="contained"
							onClick={() => navigate('/login')}>
							{HOME.hero_start}
						</Button>
						<Button variant="text" onClick={() => navigate('/signup')}>
							{HOME.hero_create}
						</Button>
					</Stack>
				</Stack>
				<Banners />
			</Section>
			<Content>
				<Stack
					direction={'column'}
					gap={'16px'}
					textAlign={!isLarge ? 'center' : ''}>
					<Typography variant={'h4'} component={'h1'}>
						{HOME.users_header_1}
					</Typography>
					<Typography variant={'h4'} component={'h1'}>
						{HOME.users_header_2}
					</Typography>
					<Typography variant="h6" color={'text.secondary'}>
						{HOME.users_text}
					</Typography>
				</Stack>
				<Stack
					width={'100%'}
					direction={'row'}
					gap={'16px'}
					flexWrap={'wrap'}
					justifyContent={!isLarge ? 'center' : ''}>
					<Button
						variant="outlined"
						onClick={() => navigate('/memberships')}>
						{HOME.users_action}
					</Button>
				</Stack>
			</Content>
			<Content>
				<Stack
					direction={'column'}
					gap={'16px'}
					textAlign={!isLarge ? 'center' : ''}>
					<Typography variant={'h4'} component={'h1'}>
						{HOME.centers_header}
					</Typography>
					<Typography variant="h6" color={'text.secondary'}>
						{HOME.centers_text}
					</Typography>
				</Stack>
				<Stack
					width={'100%'}
					direction={'row'}
					gap={'16px'}
					flexWrap={'wrap'}
					justifyContent={!isLarge ? 'center' : ''}>
					<Button variant="outlined" onClick={() => navigate('/partners')}>
						{HOME.centers_action}
					</Button>
				</Stack>
			</Content>
		</>
	);
}
