// Components
import Footer from '@components/Footer';
import Navbar from '@components/Navbar';
import FormRoot from '@components/FormRoot';
import { UserLogin } from '@modules/shared';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Login() {
	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>Para continuar debe iniciar sesión</title>
				</Helmet>
			</HelmetProvider>
			<Navbar showOptions={false} isFixed={false} />
			<FormRoot>
				<UserLogin />
			</FormRoot>
			<Footer small />
		</>
	);
}
