import { User } from '@models/User.ts';
import axios from 'axios';

export function authConfig() {
	return {
		headers: {
			Authorization: `Bearer ${getToken()}`,
		},
	};
}

export async function requestVerificationStart(data) {
	try {
		const res = await axios.post('/api/send-verification', data);
		return res;
	} catch (error) {
		console.error(error.message);
		return error.response;
	}
}

export async function requestVerificationValidate(data) {
	try {
		const res = await axios.post('/api/validate-verification', data);
		return res;
	} catch (error) {
		console.error(error.message);
		return error.response;
	}
}

export async function requestVerificationUpdate(data) {
	try {
		const res = await axios.post('/api/restore-password', data);
		return res;
	} catch (error) {
		console.error(error.message);
		return error.response;
	}
}

export async function requestBasicInfo() {
	try {
		const res = await axios.get('/api/users/basic-info', authConfig());
		return new User(res.data);
	} catch (error) {
		console.error(error.message);
	}
}

export async function requestRegister(data) {
	try {
		const res = await axios.post('/api/users/register', data);
		if (res.data.token) {
			setToken(res.data.token);
		}
		return res;
	} catch (error) {
		console.error('Register error:', error.message);
		return error.response;
	}
}

export async function requestLogin(data) {
	try {
		const res = await axios.post('/api/users/login', data);
		if (res.data.token) {
			setToken(res.data.token);
			return true;
		}
		return false;
	} catch (error) {
		console.error('Login error:', error.message);
		return false;
	}
}

export async function requestLoginExternal(data) {
	try {
		const res = await axios.post('/api/users/login-external', data);
		if (res.data.token) {
			setToken(res.data.token);
			return true;
		}
		return false;
	} catch (error) {
		console.error('Login error:', error.message);
		return false;
	}
}

export async function requestLogout() {
	try {
		await axios.post('/api/users/logout', null, authConfig());
	} catch (error) {
		console.error('Logout error', error.message);
	}
}

export async function isAuthenticated() {
	const token = getToken();
	if (!token) {
		return false;
	}
	try {
		const res = await axios.get('/api/users/auth-validate', authConfig());

		if (res.data.is_valid) {
			return true;
		} else {
			localStorage.removeItem('jwt');
			return false;
		}
	} catch (error) {
		console.error('Auth error:', error.message);
		return false;
	}
}

function getToken() {
	return localStorage.getItem('jwt');
}

function setToken(token) {
	return localStorage.setItem('jwt', token);
}
