// Definiciones
export type ActivitiesRes = {
	title: string;
	hero_header: string;
	hero_text: string;
	classes: string;
	routines: string;
	in_centers: string;
	search_activity: string;
	no_available: string;
};

export type ActivitiesLang = {
	es?: ActivitiesRes;
	en?: ActivitiesRes;
};

// Traducciones
export const ActivitiesDictionary: ActivitiesLang = {
	es: {
		title: 'Actividades deportivas en Kaady Sport',
		hero_header: 'Actividades deportivas',
		hero_text:
			'Puedes buscar tu actividad favorita y empezar a entrenar como más te guste, en casa o en los centros',
		classes: 'Clases',
		routines: 'Rutinas',
		in_centers: 'Disponible en centros',
		search_activity: 'Buscar actividad',
		no_available: 'No disponible',
	},
};
