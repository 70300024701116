import { InstagramIcon, TiktokIcon, TwitterIcon } from '@components/Icons';
import {
	PanelAction,
	PanelCard,
	PanelContent,
	PanelHeader,
} from '@components/Panel';
import SocialLink from '@components/SocialLink';
import {
	CloseOutlined,
	ContentPasteOutlined,
	FacebookOutlined,
	SaveOutlined,
} from '@mui/icons-material';
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	LinearProgress,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { updateCenterSocial } from '@utils/Centers';
import { useMemo, useState } from 'react';

/**
 * @param {Object} param0
 * @param {import('@values/Types').CenterType} [param0.item]
 * @param {() => void} [param0.onClose]
 * @returns
 */
export default function CenterSocial({ item = {}, onClose = () => {} }) {
	const startData = useMemo(
		() => ({
			id: item.id,
			name: item.name || 'Sin nombre',
			social: { ...item.social },
		}),
		[item]
	);

	const [enabled, setEnabled] = useState(true);
	const [data, setData] = useState(startData);

	const handleChange = (e) => {
		const { name, value } = e.target;
		const new_social = { ...data.social };
		new_social[name] = value;
		setData((prev) => ({
			...prev,
			social: new_social,
		}));
	};

	const handlePaste = async (name) => {
		try {
			const text = await navigator.clipboard.readText();
			const new_social = { ...data.social };
			new_social[name] = text;
			setData((prev) => ({
				...prev,
				social: new_social,
			}));
		} catch (error) {
			console.error('Pasting: ', error.message);
		}
	};

	const handleUpdate = async () => {
		setEnabled(false);
		await updateCenterSocial(data);
		setEnabled(true);
	};

	return (
		<PanelCard>
			<PanelContent>
				<PanelHeader>
					<Stack flexGrow={'1'}>
						<Typography variant="h6" component={'h1'}>
							{data.name}
						</Typography>
						<Typography variant="caption" color={'text.secondary'}>
							Redes sociales
						</Typography>
					</Stack>
					<IconButton title="Cerrar" onClick={onClose}>
						<CloseOutlined />
					</IconButton>
					{!enabled && (
						<Box
							sx={{
								width: '100%',
								position: 'absolute',
								bottom: 0,
								left: 0,
							}}>
							<LinearProgress />
						</Box>
					)}
				</PanelHeader>
				<Stack flexGrow={1} component={'form'} autoComplete="off">
					<Stack padding={'16px'} gap={'8px'}>
						<Typography fontWeight={'bold'} variant="subtitle1">
							Enlaces principales
						</Typography>
						<Stack direction={'row'} gap={'8px'} alignItems={'center'}>
							<SocialLink
								title="Facebook"
								target="_blank"
								rel="noreferrer"
								href={data.social.facebook}>
								<FacebookOutlined fontSize="large" />
							</SocialLink>
							<TextField
								fullWidth
								disabled={!enabled}
								variant="filled"
								autoComplete="off"
								label="Enlace de Facebook"
								name="facebook"
								onChange={handleChange}
								value={data.social.facebook}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												disabled={!enabled}
												onClick={() =>
													handlePaste('facebook')
												}>
												<ContentPasteOutlined />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Stack>
						<Stack direction={'row'} gap={'8px'} alignItems={'center'}>
							<SocialLink
								title="Twitter"
								target="_blank"
								rel="noreferrer"
								href={data.social.twitter}>
								<TwitterIcon fontSize="large" />
							</SocialLink>
							<TextField
								fullWidth
								disabled={!enabled}
								variant="filled"
								autoComplete="off"
								label="Enlace de Twitter (X)"
								name="twitter"
								onChange={handleChange}
								value={data.social.twitter}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												disabled={!enabled}
												onClick={() =>
													handlePaste('twitter')
												}>
												<ContentPasteOutlined />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Stack>
						<Stack direction={'row'} gap={'8px'} alignItems={'center'}>
							<SocialLink
								title="Instagram"
								target="_blank"
								rel="noreferrer"
								href={data.social.instagram}>
								<InstagramIcon fontSize="large" />
							</SocialLink>
							<TextField
								fullWidth
								disabled={!enabled}
								variant="filled"
								autoComplete="off"
								label="Enlace de Instagram"
								name="instagram"
								onChange={handleChange}
								value={data.social.instagram}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												disabled={!enabled}
												onClick={() =>
													handlePaste('instagram')
												}>
												<ContentPasteOutlined />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Stack>
						<Stack direction={'row'} gap={'8px'} alignItems={'center'}>
							<SocialLink
								title="Tiktok"
								target="_blank"
								rel="noreferrer"
								href={data.social.tiktok}>
								<TiktokIcon fontSize="large" />
							</SocialLink>
							<TextField
								fullWidth
								disabled={!enabled}
								variant="filled"
								autoComplete="off"
								label="Enlace de Tiktok"
								name="tiktok"
								onChange={handleChange}
								value={data.social.tiktok}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												disabled={!enabled}
												onClick={() =>
													handlePaste('tiktok')
												}>
												<ContentPasteOutlined />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Stack>
					</Stack>
				</Stack>
				<PanelAction>
					<Button
						variant="contained"
						sx={{
							padding: '8px 16px',
							lineHeight: 'normal',
						}}
						disabled={!enabled}
						onClick={handleUpdate}
						startIcon={<SaveOutlined />}>
						Guardar
					</Button>
				</PanelAction>
			</PanelContent>
		</PanelCard>
	);
}
