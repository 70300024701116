import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import { Outlet } from 'react-router-dom';

/**
 * Home page for main web app
 *
 * By Frankil A.
 * © Kaady Sport S.A. de C.V. 2024
 */
export default function Home() {
	return (
		<>
			<Navbar />
			<Outlet />
			<Footer />
		</>
	);
}
