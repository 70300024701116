import { MAP_PROPS } from '@values/Default';
import { PointType } from '@values/Types';

export class Point implements PointType {
	lat: number;
	lng: number;

	constructor(data: any) {
		this.lat = data?.lat || MAP_PROPS.center.lat;
		this.lng = data?.lng || MAP_PROPS.center.lng;
	}
}
