import BasicModal from '@components/BasicModal';
import {
	PanelAction,
	PanelCard,
	PanelContent,
	PanelHeader,
} from '@components/Panel';
import { Center } from '@models/Center.ts';
import { Contact } from '@models/Contacts.ts';
import { Manager } from '@models/Manager.ts';
import { CloseOutlined, DeleteOutlined, SaveOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { updateOrCreateCenter } from '@utils/Centers';
import { useCallback, useEffect, useState } from 'react';

/**
 * @param {Object} param0
 * @param {import('@values/Types').CenterType} [param0.item]
 * @param {() => void} [param0.onClose]
 * @param {()=> void} [param0.onSaved]
 * @returns
 */
export default function CenterProfile({
	item = {},
	onClose = () => {},
	onSaved = () => {},
}) {
	const startData = useCallback(
		(item) => ({
			id: item.id,
			nanoid: item.nanoid,
			name: item.name || '',
			description: item.description || '',
			managers:
				item.managers.length > 0
					? item.managers.map((value) => new Manager(value))
					: [new Manager()],
		}),
		[]
	);

	const [open, setOpen] = useState(false);
	const [enabled, setEnabled] = useState(true);
	const [data, setData] = useState(startData(item));
	const [modal, setModal] = useState({
		title: '',
		message: '',
	});

	useEffect(() => {
		setData(startData(item));
	}, [startData, item]);

	const handleChange = (e) => {
		const { value, name } = e.target;
		setData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleManagerChange = (field) => (e) => {
		const value = [...data.managers];
		value[0][field] = e.target.value;
		setData((prev) => ({
			...prev,
			managers: value,
		}));
	};

	const handleContactsChange = (index, field) => (e) => {
		const value = [...data.managers];
		value[0].contacts[index][field] = e.target.value;
		setData((prev) => ({
			...prev,
			managers: value,
		}));
	};

	const handleContactsAppend = () => {
		const value = [...data.managers];
		value[0].contacts.push(new Contact({ type: 'EMAIL' }));
		setData((prev) => ({
			...prev,
			managers: value,
		}));
	};

	const handleContactsDelete = (index) => () => {
		const value = [...data.managers];
		value[0].contacts.splice(index, 1);
		setData((prev) => ({
			...prev,
			managers: value,
		}));
	};

	const handleUpdate = async () => {
		setEnabled(false);
		// Filter empty contacts
		const value = [...data.managers];
		const filtered = value[0].contacts.filter(
			(item) => item.id || item.value !== ''
		);
		// Filter empty manager
		if (
			filtered.length === 0 &&
			value[0].name === '' &&
			value[0].last_name === ''
		) {
			value.splice(0, 1);
		} else {
			value[0].contacts = filtered;
		}
		// Send data
		const res = await updateOrCreateCenter({
			...data,
			managers: value,
		});
		// Show errors
		if (res.status !== 200 && res.status !== 201) {
			const errors = res.data?.errors ? Object.values(res.data?.errors) : [];
			setModal({
				title: res.data?.message || 'Ha ocurrido un error',
				message:
					errors.length > 0
						? errors[0]
						: 'No fue posible crear o actualizar el perfil. Intenta de nuevo más tarde.',
			});
			setOpen(true);
		} else {
			onSaved();
		}
		if (res.status === 201) {
			setData(startData(new Center(res.data?.center)));
		}
		setEnabled(true);
	};

	const handleModalClose = () => {
		setOpen(false);
	};

	const loadContactsForm = () => {
		if (data.managers[0].contacts.length === 0) {
			const value = [...data.managers];
			value[0].contacts = [
				new Contact({ type: 'EMAIL' }),
				new Contact({ type: 'PHONE' }),
			];
			setData((prev) => ({
				...prev,
				managers: value,
			}));
		}

		return data.managers[0].contacts.map((item, index) => (
			<Stack key={index} direction={'row'} gap={'8px'} alignItems={'center'}>
				<FormControl
					disabled={!enabled}
					variant="filled"
					sx={{ minWidth: 120 }}>
					<InputLabel>Tipo</InputLabel>
					<Select
						label={`Contact${index}`}
						value={item.type}
						onChange={handleContactsChange(index, 'type')}>
						<MenuItem value={'EMAIL'}>Email</MenuItem>
						<MenuItem value={'PHONE'}>Teléfono</MenuItem>
					</Select>
				</FormControl>
				<TextField
					fullWidth
					disabled={!enabled}
					variant="filled"
					label="Contacto"
					name="value"
					value={item.value}
					onChange={handleContactsChange(index, 'value')}
				/>
				<IconButton
					disabled={!enabled}
					color="secondary"
					onClick={handleContactsDelete(index)}>
					<DeleteOutlined />
				</IconButton>
			</Stack>
		));
	};

	return (
		<PanelCard>
			<PanelContent>
				<PanelHeader>
					<Stack flexGrow={'1'}>
						<Typography variant="h6" component={'h1'}>
							{data.id
								? data.name !== ''
									? data.name
									: 'Sin nombre'
								: 'Crear nuevo centro'}
						</Typography>
						<Typography variant="caption" color={'text.secondary'}>
							Perfil del centro
						</Typography>
					</Stack>
					<IconButton title="Cerrar" onClick={onClose}>
						<CloseOutlined />
					</IconButton>
					{!enabled && (
						<Box
							sx={{
								width: '100%',
								position: 'absolute',
								bottom: 0,
								left: 0,
							}}>
							<LinearProgress />
						</Box>
					)}
				</PanelHeader>
				<Stack flexGrow={1} component={'form'} autoComplete="off">
					<Stack padding={'16px'} gap={'8px'}>
						<Typography
							fontWeight={'bold'}
							variant="subtitle1">{`Perfil ${
							data.nanoid ? data.nanoid : ''
						}`}</Typography>
						<Stack gap={'8px'} flexWrap={'wrap'}>
							<TextField
								fullWidth
								autoComplete="off"
								disabled={!enabled}
								variant="filled"
								label="Nombre del centro"
								name="name"
								value={data.name}
								onChange={handleChange}
							/>
							<TextField
								fullWidth
								disabled={!enabled}
								variant="filled"
								label="Descripción"
								name="description"
								value={data.description}
								onChange={handleChange}
							/>
						</Stack>
					</Stack>
					<Stack padding={'16px'} gap={'8px'}>
						<Typography fontWeight={'bold'} variant="subtitle1">
							Contactos del administrador o encargado
						</Typography>
						<Stack direction={'row'} gap={'8px'} flexWrap={'wrap'}>
							<TextField
								sx={{
									flex: '1 0 200px',
								}}
								disabled={!enabled}
								variant="filled"
								label="Nombre"
								name="manager_name"
								value={data.managers[0].name}
								onChange={handleManagerChange('name')}
							/>
							<TextField
								sx={{
									flex: '1 0 200px',
								}}
								disabled={!enabled}
								variant="filled"
								label="Apellido"
								name="manager_last_name"
								value={data.managers[0].last_name}
								onChange={handleManagerChange('last_name')}
							/>
						</Stack>
						{loadContactsForm()}
						<Stack direction={'row'}>
							<Button
								onClick={handleContactsAppend}
								disabled={data.managers[0].contacts.some(
									(item) => item.value === ''
								)}>
								Agregar nuevo
							</Button>
						</Stack>
					</Stack>
				</Stack>
				<PanelAction>
					<Button
						onClick={handleUpdate}
						variant="contained"
						disabled={!enabled}
						sx={{
							padding: '8px 16px',
							lineHeight: 'normal',
						}}
						startIcon={<SaveOutlined />}>
						{data.id ? 'Guardar' : 'Guardar nuevo'}
					</Button>
				</PanelAction>
			</PanelContent>
			<BasicModal
				open={open}
				title={modal.title}
				message={modal.message}
				onClose={handleModalClose}
			/>
		</PanelCard>
	);
}
