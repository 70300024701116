import { SvgIcon } from '@mui/material';

/**
 * Gets the official Kaady Sport Logo including their variants.
 * The format is svg, using svg icon from material
 *
 * By Frankil A.
 */
function Logo({
	type = 'horizontal',
	height = '36px',
	maxHeight = 'none',
	color = '',
}) {
	/**
	 * @type {import('@mui/material').SvgIconProps}
	 */
	const props = {
		className: 'ks_logo',
		sx: (t) => ({
			height: height,
			maxHeight: maxHeight,
			width: 'auto',
			fill: color ? color : t.palette.background.logo,
		}),
	};

	if (type === 'horizontal') {
		return (
			<SvgIcon {...props}>
				<svg
					height="36px"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 152.55 25.5">
					<path d="M33.89,18.72a1.06,1.06,0,0,1-.92-.37,1.37,1.37,0,0,1-.18-1.08l1.45-9.19a1.73,1.73,0,0,1,.48-1,1.31,1.31,0,0,1,1-.3,1,1,0,0,1,.9.37,1.43,1.43,0,0,1,.18,1.1l-.6,3.75h0l5.07-4.67a3,3,0,0,1,.58-.45,1.66,1.66,0,0,1,.7-.15,1,1,0,0,1,.8.3.87.87,0,0,1,.27.7,1,1,0,0,1-.37.75l-5,4.52L38.14,12l4,5.05a1.15,1.15,0,0,1,.25.77,1,1,0,0,1-.35.65,1.1,1.1,0,0,1-.8.25,1.62,1.62,0,0,1-.75-.17,2.88,2.88,0,0,1-.65-.7l-3.8-4.67h0l-.67,4.32a1.33,1.33,0,0,1-.45.92A1.5,1.5,0,0,1,33.89,18.72Z" />
					<path d="M44,18.72a1.32,1.32,0,0,1-.75-.2.74.74,0,0,1-.35-.55,1.18,1.18,0,0,1,.2-.75L49,7.65a2,2,0,0,1,.62-.7,2,2,0,0,1,.83-.2,1.51,1.51,0,0,1,.85.25,1.49,1.49,0,0,1,.5.78l2.75,9.32a1.52,1.52,0,0,1,0,.87.7.7,0,0,1-.38.5,1.28,1.28,0,0,1-.7.18,1.37,1.37,0,0,1-.85-.25,2.05,2.05,0,0,1-.47-.85l-.5-2.05.75.52h-6.7l.93-.47L45.31,18a1.45,1.45,0,0,1-.52.55A1.42,1.42,0,0,1,44,18.72Zm6.15-9L47.31,14.5,47,14.05h5l-.48.53-1.3-4.9Z" />
					<path d="M56.46,18.72a1.28,1.28,0,0,1-.75-.2.71.71,0,0,1-.35-.55,1.12,1.12,0,0,1,.2-.75l5.89-9.57a2.09,2.09,0,0,1,.63-.7,1.64,1.64,0,0,1,.85-.2,1.51,1.51,0,0,1,.85.25,1.49,1.49,0,0,1,.5.78l2.79,9.32a1.44,1.44,0,0,1,.05.87.69.69,0,0,1-.37.5,1.28,1.28,0,0,1-.7.18,1.37,1.37,0,0,1-.85-.25,2.21,2.21,0,0,1-.47-.83L64.2,15.5,65,16H58.23l.92-.47L57.78,18a1.52,1.52,0,0,1-.52.55A1.39,1.39,0,0,1,56.46,18.72Zm6.14-9L59.75,14.5l-.35-.45h5l-.45.53-1.3-4.9Z" />
					<path d="M69.92,18.57a1.3,1.3,0,0,1-1-.37,1.26,1.26,0,0,1-.23-1.08l1.43-9a1.61,1.61,0,0,1,.45-.92,1.51,1.51,0,0,1,1-.33h2.67a6.62,6.62,0,0,1,4.4,1.33A4.64,4.64,0,0,1,80.17,12a7.84,7.84,0,0,1-.43,2.63,5.38,5.38,0,0,1-1.3,2.09,6.09,6.09,0,0,1-2.1,1.38,7.81,7.81,0,0,1-2.92.5Zm1.45-2.1h2.18a4.28,4.28,0,0,0,1.77-.32,3.36,3.36,0,0,0,1.25-.93,4.23,4.23,0,0,0,.72-1.42,5.94,5.94,0,0,0,.25-1.8,2.92,2.92,0,0,0-.82-2.22A3.48,3.48,0,0,0,74.27,9h-1.7Z" />
					<path d="M84.61,18.72a1,1,0,0,1-.9-.37,1.69,1.69,0,0,1-.17-1.13l.75-4.59.27,1.47-3-5.52a1.54,1.54,0,0,1-.2-.88,1,1,0,0,1,.37-.7,1.45,1.45,0,0,1,.83-.25,1.18,1.18,0,0,1,.75.2,2,2,0,0,1,.57.78l1.92,3.67h-.52l3.25-3.77a2.82,2.82,0,0,1,.7-.63A1.54,1.54,0,0,1,90,6.83a1,1,0,0,1,.8.27.92.92,0,0,1,.3.7,1.22,1.22,0,0,1-.35.83L86,14.15l.78-1.47L86,17.5A1.32,1.32,0,0,1,84.61,18.72Z" />
					<path d="M100.06,18.75a8.76,8.76,0,0,1-1.35-.1,6.1,6.1,0,0,1-1.33-.3,8,8,0,0,1-1.22-.5,1.05,1.05,0,0,1-.53-.53,1.11,1.11,0,0,1-.07-.7,1.07,1.07,0,0,1,.25-.6,1,1,0,0,1,.55-.32,1,1,0,0,1,.72.17,6.06,6.06,0,0,0,1.6.55,12.13,12.13,0,0,0,1.58.18,3.12,3.12,0,0,0,1.64-.38,1.07,1.07,0,0,0,.28-1.72A2.71,2.71,0,0,0,101,14l-1.85-.45a3.77,3.77,0,0,1-2-1.05,2.81,2.81,0,0,1-.7-2A3.43,3.43,0,0,1,96.88,9,4.19,4.19,0,0,1,99.46,7a6.75,6.75,0,0,1,1.84-.25,9.19,9.19,0,0,1,1.8.2,5.36,5.36,0,0,1,1.8.7.89.89,0,0,1,.45.5,1.27,1.27,0,0,1,.1.63.9.9,0,0,1-.25.55,1.16,1.16,0,0,1-.57.3,1.46,1.46,0,0,1-.85-.18A4.07,4.07,0,0,0,102.55,9a5.51,5.51,0,0,0-1.27-.15,3.81,3.81,0,0,0-1.17.17,1.59,1.59,0,0,0-.78.48,1.11,1.11,0,0,0-.27.72,1,1,0,0,0,.32.8,2.25,2.25,0,0,0,1,.45l1.84.43a4.4,4.4,0,0,1,2.13,1.17,2.65,2.65,0,0,1,.75,1.9,3.2,3.2,0,0,1-.43,1.65,3.39,3.39,0,0,1-1.12,1.17,5.1,5.1,0,0,1-1.6.7A6.35,6.35,0,0,1,100.06,18.75Z" />
					<path d="M107.5,18.72a1.09,1.09,0,0,1-.9-.37,1.47,1.47,0,0,1-.17-1.13l1.42-9a1.79,1.79,0,0,1,.48-1,1.43,1.43,0,0,1,1-.3h3.44a4.6,4.6,0,0,1,3,.87,3,3,0,0,1,1,2.45,3.91,3.91,0,0,1-1.23,3.08,5.13,5.13,0,0,1-3.5,1.1h-2.69l-.5,3a1.49,1.49,0,0,1-.45,1A1.57,1.57,0,0,1,107.5,18.72Zm2.25-6.32h2.5a2.32,2.32,0,0,0,1.6-.5,2,2,0,0,0,.55-1.45,1.47,1.47,0,0,0-.48-1.15,2.49,2.49,0,0,0-1.47-.37H110.3Z" />
					<path d="M123,18.75a6.17,6.17,0,0,1-2.8-.6,4.32,4.32,0,0,1-1.84-1.75,5.68,5.68,0,0,1-.7-2.8,8.48,8.48,0,0,1,.45-2.75,6.5,6.5,0,0,1,1.24-2.15,5.57,5.57,0,0,1,2-1.37,6.85,6.85,0,0,1,5.4.1,4.46,4.46,0,0,1,1.85,1.75,5.68,5.68,0,0,1,.7,2.75,8.61,8.61,0,0,1-.45,2.75,6.2,6.2,0,0,1-1.27,2.14,6,6,0,0,1-2,1.43A6.89,6.89,0,0,1,123,18.75Zm0-2.18A2.88,2.88,0,0,0,125,16a3.53,3.53,0,0,0,1.18-1.64,6.8,6.8,0,0,0,.4-2.4,3.16,3.16,0,0,0-.7-2.28,2.7,2.7,0,0,0-2-.75,3.14,3.14,0,0,0-1.87.58,3.83,3.83,0,0,0-1.2,1.62,6.73,6.73,0,0,0-.43,2.43,3.15,3.15,0,0,0,.7,2.27,2.6,2.6,0,0,0,2,.77Z" />
					<path d="M131.56,18.72a1.09,1.09,0,0,1-.9-.37,1.47,1.47,0,0,1-.17-1.13l1.42-9a1.63,1.63,0,0,1,.5-1,1.56,1.56,0,0,1,1-.3h3.44a4.48,4.48,0,0,1,3,.88,2.93,2.93,0,0,1,1,2.4,3.93,3.93,0,0,1-.58,2.17,3.4,3.4,0,0,1-1.6,1.25,5.28,5.28,0,0,1-2.32.38v-.25h.52a2,2,0,0,1,1.33.45,3.11,3.11,0,0,1,.9,1.22l.62,1.62a1.49,1.49,0,0,1,.13.88.82.82,0,0,1-.38.55,1.47,1.47,0,0,1-.85.2,1.28,1.28,0,0,1-.82-.25,2.35,2.35,0,0,1-.55-.85L136.36,15a1.25,1.25,0,0,0-.5-.73,1.82,1.82,0,0,0-.9-.22h-1.35l-.52,3.34a1.38,1.38,0,0,1-1.48,1.28S131.59,18.72,131.56,18.72Zm2.3-6.52h2.53a2.38,2.38,0,0,0,1.54-.45,1.66,1.66,0,0,0,.55-1.37A1.38,1.38,0,0,0,138,9.25a2.64,2.64,0,0,0-1.5-.37h-2.12Z" />
					<path d="M145.58,18.72a1.09,1.09,0,0,1-.9-.37,1.42,1.42,0,0,1-.17-1.1l1.29-8.2h-2.72a1.32,1.32,0,0,1-.82-.22.77.77,0,0,1-.3-.63,1.14,1.14,0,0,1,.35-.92,1.23,1.23,0,0,1,.92-.33h8.2a1.21,1.21,0,0,1,.82.23.81.81,0,0,1,.3.65,1.15,1.15,0,0,1-1.05,1.25h-3.15L147,17.5a1.61,1.61,0,0,1-.45.92A1.8,1.8,0,0,1,145.58,18.72Z" />
					<path d="M18.75,25.5h-12A6.76,6.76,0,0,1,0,18.75v-12A6.76,6.76,0,0,1,6.75,0h12A6.76,6.76,0,0,1,25.5,6.75v12A6.76,6.76,0,0,1,18.75,25.5Zm-12-24A5.26,5.26,0,0,0,1.5,6.75v12A5.26,5.26,0,0,0,6.75,24h12A5.26,5.26,0,0,0,24,18.75v-12A5.26,5.26,0,0,0,18.75,1.5Z" />
					<path d="M21.25,12.12a.64.64,0,0,1-.11.36l-5.47,8h0a.66.66,0,0,1-.53.27.63.63,0,0,1-.63-.63.6.6,0,0,1,.05-.24h0l2-5h0a.6.6,0,0,0,.05-.24A.63.63,0,0,0,16,14H11.36a.64.64,0,0,1-.64-.63.73.73,0,0,1,.11-.36l5.48-8h0a.63.63,0,0,1,1.15.36.6.6,0,0,1-.05.24h0l-2,5h0a.61.61,0,0,0,0,.24.63.63,0,0,0,.63.63h4.63A.63.63,0,0,1,21.25,12.12Z" />
					<rect
						x="0.15"
						y="11.49"
						width="16.52"
						height="2.53"
						rx="1.26"
						transform="translate(-6.14 17.39) rotate(-74.29)"
					/>
				</svg>
			</SvgIcon>
		);
	}

	if (type === 'square') {
		return (
			<SvgIcon {...props}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 76">
					<path d="M5,71a.93.93,0,0,1-.77-.31,1.15,1.15,0,0,1-.14-.9l1.21-7.66a1.38,1.38,0,0,1,.39-.79,1.14,1.14,0,0,1,.81-.25.83.83,0,0,1,.75.31,1.17,1.17,0,0,1,.15.91l-.5,3.13H7l4.23-3.9a2.46,2.46,0,0,1,.48-.37,1.41,1.41,0,0,1,.58-.13.82.82,0,0,1,.66.25.75.75,0,0,1,.23.59.77.77,0,0,1-.31.62L8.68,66.25l-.11-.92L12,69.54a1,1,0,0,1,.21.65.84.84,0,0,1-.3.54,1,1,0,0,1-.66.21,1.39,1.39,0,0,1-.63-.15,2.48,2.48,0,0,1-.54-.58l-3.16-3.9h0L6.3,69.92a1.09,1.09,0,0,1-.37.77A1.3,1.3,0,0,1,5,71Z" />
					<path d="M13.47,71a1.07,1.07,0,0,1-.63-.17.62.62,0,0,1-.29-.46.92.92,0,0,1,.17-.62l4.91-8a1.61,1.61,0,0,1,.52-.58,1.67,1.67,0,0,1,.69-.17,1.28,1.28,0,0,1,.71.21,1.19,1.19,0,0,1,.41.65l2.29,7.76a1.22,1.22,0,0,1,.05.73.6.6,0,0,1-.32.42,1.11,1.11,0,0,1-.58.14,1.07,1.07,0,0,1-.71-.2A1.67,1.67,0,0,1,20.3,70l-.42-1.71.62.44H14.92l.77-.4-1.14,2a1.09,1.09,0,0,1-.44.46A1.2,1.2,0,0,1,13.47,71Zm5.12-7.54-2.37,4-.3-.38h4.17l-.4.44-1.08-4.08Z" />
					<path d="M23.84,71a1.07,1.07,0,0,1-.63-.17.6.6,0,0,1-.29-.46.92.92,0,0,1,.17-.62l4.91-8a1.79,1.79,0,0,1,.52-.58,1.43,1.43,0,0,1,.71-.17,1.28,1.28,0,0,1,.71.21,1.25,1.25,0,0,1,.41.65l2.34,7.76a1.3,1.3,0,0,1,0,.73.58.58,0,0,1-.32.42,1.11,1.11,0,0,1-.58.14,1.07,1.07,0,0,1-.71-.2,1.82,1.82,0,0,1-.39-.69l-.44-1.73.63.44H25.31l.77-.4-1.14,2a1.27,1.27,0,0,1-1.1.65ZM29,63.44l-2.38,4-.29-.38h4.17l-.38.44L29,63.44Z" />
					<path d="M35.06,70.85a1.08,1.08,0,0,1-.85-.31,1,1,0,0,1-.19-.9l1.18-7.47a1.41,1.41,0,0,1,.38-.77,1.27,1.27,0,0,1,.83-.27h2.23a5.52,5.52,0,0,1,3.66,1.1,3.87,3.87,0,0,1,1.3,3.1,6.53,6.53,0,0,1-.36,2.19,4.48,4.48,0,0,1-1.08,1.75,5.07,5.07,0,0,1-1.75,1.15,6.49,6.49,0,0,1-2.44.41Zm1.21-1.75h1.81a3.57,3.57,0,0,0,1.48-.27,2.62,2.62,0,0,0,1-.77,3.47,3.47,0,0,0,.6-1.18,5,5,0,0,0,.21-1.5,2.45,2.45,0,0,0-.69-1.86,3,3,0,0,0-2-.62H37.27Z" />
					<path d="M47.3,71a.89.89,0,0,1-.75-.31,1.34,1.34,0,0,1-.14-.94L47,65.9l.23,1.23-2.5-4.61a1.23,1.23,0,0,1-.16-.72.82.82,0,0,1,.31-.59,1.19,1.19,0,0,1,.68-.21,1,1,0,0,1,.63.17,1.72,1.72,0,0,1,.48.65l1.6,3.06h-.44l2.71-3.15a2.4,2.4,0,0,1,.58-.52,1.33,1.33,0,0,1,.63-.14.84.84,0,0,1,.66.23.73.73,0,0,1,.25.58,1,1,0,0,1-.29.69l-3.93,4.6.64-1.23-.64,4A1.11,1.11,0,0,1,47.3,71Z" />
					<path d="M60.17,71a6,6,0,0,1-1.12-.09,5.23,5.23,0,0,1-1.11-.24,6.44,6.44,0,0,1-1-.42.9.9,0,0,1-.44-.44,1,1,0,0,1-.06-.58.83.83,0,0,1,.21-.5.75.75,0,0,1,.46-.27.92.92,0,0,1,.6.14,5,5,0,0,0,1.33.46,8.47,8.47,0,0,0,1.32.15,2.71,2.71,0,0,0,1.37-.31.94.94,0,0,0,.5-.82.88.88,0,0,0-.27-.62A2.14,2.14,0,0,0,61,67l-1.54-.37a3.19,3.19,0,0,1-1.62-.88,2.37,2.37,0,0,1-.59-1.64,2.79,2.79,0,0,1,.32-1.31,3.23,3.23,0,0,1,.87-1,3.69,3.69,0,0,1,1.27-.63A5.72,5.72,0,0,1,61.21,61a7.66,7.66,0,0,1,1.5.17,4.37,4.37,0,0,1,1.5.58.73.73,0,0,1,.37.42,1.06,1.06,0,0,1,.09.52.81.81,0,0,1-.21.46,1,1,0,0,1-.48.25,1.16,1.16,0,0,1-.71-.15,3.52,3.52,0,0,0-1-.35,4.8,4.8,0,0,0-1.06-.13,3.24,3.24,0,0,0-1,.15,1.23,1.23,0,0,0-.64.4.86.86,0,0,0-.23.6.82.82,0,0,0,.27.67,2,2,0,0,0,.81.37l1.54.35a3.66,3.66,0,0,1,1.77,1,2.2,2.2,0,0,1,.62,1.59A2.67,2.67,0,0,1,64,69.25a2.84,2.84,0,0,1-.94,1,4.08,4.08,0,0,1-1.33.58A5.3,5.3,0,0,1,60.17,71Z" />
					<path d="M66.37,71a1,1,0,0,1-.75-.31,1.28,1.28,0,0,1-.14-.94l1.19-7.52a1.47,1.47,0,0,1,.39-.81,1.22,1.22,0,0,1,.83-.25h2.88a3.9,3.9,0,0,1,2.54.73,2.51,2.51,0,0,1,.85,2,3.23,3.23,0,0,1-1,2.56,4.23,4.23,0,0,1-2.91.92H68l-.42,2.54a1.27,1.27,0,0,1-.37.79A1.35,1.35,0,0,1,66.37,71Zm1.88-5.27h2.08a1.93,1.93,0,0,0,1.33-.42,1.59,1.59,0,0,0,.46-1.2,1.21,1.21,0,0,0-.39-1,2,2,0,0,0-1.23-.31H68.71Z" />
					<path d="M79.33,71A5.09,5.09,0,0,1,77,70.5,3.61,3.61,0,0,1,75.45,69a4.85,4.85,0,0,1-.58-2.33,7,7,0,0,1,.37-2.29,5.29,5.29,0,0,1,1.05-1.79,4.56,4.56,0,0,1,1.62-1.15,5.72,5.72,0,0,1,4.5.09A3.78,3.78,0,0,1,84,63a4.75,4.75,0,0,1,.58,2.29,7.09,7.09,0,0,1-.37,2.29,5.16,5.16,0,0,1-1.07,1.8,5,5,0,0,1-1.64,1.18A5.72,5.72,0,0,1,79.33,71Zm0-1.81a2.48,2.48,0,0,0,1.6-.5,3,3,0,0,0,1-1.38,5.42,5.42,0,0,0,.33-2,2.58,2.58,0,0,0-.58-1.89A2.21,2.21,0,0,0,80,62.79a2.64,2.64,0,0,0-1.56.48,3.26,3.26,0,0,0-1,1.36,5.54,5.54,0,0,0-.35,2,2.58,2.58,0,0,0,.58,1.89,2.18,2.18,0,0,0,1.65.65Z" />
					<path d="M86.43,71a.93.93,0,0,1-.75-.31,1.23,1.23,0,0,1-.15-.94l1.19-7.5a1.32,1.32,0,0,1,.41-.81,1.25,1.25,0,0,1,.86-.25h2.87a3.74,3.74,0,0,1,2.5.73,2.45,2.45,0,0,1,.85,2,3.2,3.2,0,0,1-.48,1.81,2.84,2.84,0,0,1-1.33,1,4.43,4.43,0,0,1-1.94.31v-.21h.44a1.68,1.68,0,0,1,1.11.38,2.63,2.63,0,0,1,.75,1l.52,1.35a1.31,1.31,0,0,1,.1.73.65.65,0,0,1-.31.46,1.25,1.25,0,0,1-.71.17,1.08,1.08,0,0,1-.69-.21,2.07,2.07,0,0,1-.46-.71l-.79-2.14a1.08,1.08,0,0,0-.41-.61,1.61,1.61,0,0,0-.75-.19H88.13l-.43,2.79A1.16,1.16,0,0,1,86.47,71S86.45,71,86.43,71Zm1.91-5.44h2.1a2,2,0,0,0,1.29-.37A1.38,1.38,0,0,0,92.19,64a1.14,1.14,0,0,0-.39-.93,2.21,2.21,0,0,0-1.25-.32H88.78Z" />
					<path d="M98.11,71a.93.93,0,0,1-.75-.31,1.2,1.2,0,0,1-.15-.92l1.08-6.83H96a1.1,1.1,0,0,1-.68-.19.61.61,0,0,1-.25-.52,1,1,0,0,1,.29-.77,1,1,0,0,1,.77-.27H103a1,1,0,0,1,.69.19.68.68,0,0,1,.25.54,1,1,0,0,1-.88,1h-2.62l-1.11,7a1.33,1.33,0,0,1-.37.77A1.55,1.55,0,0,1,98.11,71Z" />
					<path d="M66,54.5H42A13.52,13.52,0,0,1,28.5,41V17A13.52,13.52,0,0,1,42,3.5H66A13.52,13.52,0,0,1,79.5,17V41A13.52,13.52,0,0,1,66,54.5ZM42,6.5A10.51,10.51,0,0,0,31.5,17V41A10.51,10.51,0,0,0,42,51.5H66A10.51,10.51,0,0,0,76.5,41V17A10.51,10.51,0,0,0,66,6.5Z" />
					<path d="M71,27.74a1.22,1.22,0,0,1-.22.71l-11,16h0a1.26,1.26,0,0,1-2.3-.71,1.28,1.28,0,0,1,.09-.48h0l4-10v0a1.2,1.2,0,0,0,.1-.47,1.26,1.26,0,0,0-1.27-1.26H51.21A1.26,1.26,0,0,1,50,30.26a1.22,1.22,0,0,1,.22-.71l10.94-16h0a1.29,1.29,0,0,1,1-.55,1.25,1.25,0,0,1,1.26,1.26,1.28,1.28,0,0,1-.09.48h0l-4,10v0a1.19,1.19,0,0,0-.09.47,1.26,1.26,0,0,0,1.26,1.26h9.27A1.26,1.26,0,0,1,71,27.74Z" />
					<rect
						x="28.79"
						y="26.47"
						width="33.04"
						height="5.05"
						rx="2.53"
						transform="translate(5.13 64.77) rotate(-74.29)"
					/>
				</svg>
			</SvgIcon>
		);
	}
	// type === 'icon'
	else {
		return (
			<SvgIcon {...props}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
					<path d="M39,6a9,9,0,0,1,9,9V39a9,9,0,0,1-9,9H15a9,9,0,0,1-9-9V15a9,9,0,0,1,9-9H39m0-3H15A12,12,0,0,0,3,15V39A12,12,0,0,0,15,51H39A12,12,0,0,0,51,39V15A12,12,0,0,0,39,3Z" />
					<path d="M24.53,14.21,17,41.16A2.48,2.48,0,0,1,14.53,43,2.53,2.53,0,0,1,12,40.47a2.24,2.24,0,0,1,.1-.68l7.58-26.95A2.5,2.5,0,0,1,22.11,11a2.52,2.52,0,0,1,2.52,2.53A2.24,2.24,0,0,1,24.53,14.21Z" />
					<path d="M44,25.74a1.22,1.22,0,0,1-.22.71l-11,16a1.26,1.26,0,0,1-2.3-.71,1.19,1.19,0,0,1,.09-.47l4-10a1.2,1.2,0,0,0,.1-.47,1.26,1.26,0,0,0-1.27-1.26H24.21A1.26,1.26,0,0,1,23,28.26a1.22,1.22,0,0,1,.22-.71l10.94-16a1.29,1.29,0,0,1,1-.55,1.25,1.25,0,0,1,1.26,1.26,1.19,1.19,0,0,1-.09.47l-4,10a1.19,1.19,0,0,0-.09.47,1.25,1.25,0,0,0,1.26,1.26h9.27A1.26,1.26,0,0,1,44,25.74Z" />
				</svg>
			</SvgIcon>
		);
	}
}

export default Logo;
