import { useEffect, useState } from 'react';
import { useExtraScreen } from '@utils/Media';
import CentersMain from './sections/CentersMain';
import CenterProfile from './sections/CenterProfile';
import CenterLocation from './sections/CenterLocation';
import CenterImages from './sections/CenterImages';
import CenterSocial from './sections/CenterSocial';
import { loadZonesToStorage } from '@utils/Zones';

export default function CentersPanel() {
	const isExtra = useExtraScreen();
	const [openSecondary, setOpenSecondary] = useState(false);
	const [item, setItem] = useState({});
	const [index, setIndex] = useState(0);
	const [load, setLoad] = useState(true);

	useEffect(() => {
		const loadFirst = async () => {
			await loadZonesToStorage();
		};
		loadFirst();
	}, []);

	const handleOpen = (i, item) => {
		setIndex(i);
		setItem(item);
		setOpenSecondary(true);
	};
	const handleClose = async () => {
		setOpenSecondary(false);
	};
	const handleReload = () => {
		setLoad(true);
	};
	const handleLoaded = () => {
		setLoad(false);
	};
	const secondaries = [
		<CenterProfile item={item} onClose={handleClose} onSaved={handleReload} />,
		<CenterLocation item={item} onClose={handleClose} onSaved={handleReload} />,
		<CenterImages item={item} onClose={handleClose} onSaved={handleReload} />,
		<CenterSocial item={item} onClose={handleClose} />,
	];

	return (
		<>
			<CentersMain
				onOpen={handleOpen}
				load={load}
				onLoaded={handleLoaded}
				visible={isExtra || !openSecondary}
			/>
			{openSecondary && secondaries[index]}
		</>
	);
}
